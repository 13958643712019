import { useState, useEffect } from 'react';

import { useScreenDimensions } from './useStyleForWidth';

function useElementDimensions(ref, extraInput = null) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const screenDimensions = useScreenDimensions();
  const screenWidth = screenDimensions.width;

  useEffect(() => {
    let f = (w, h) => {
      setWidth(w);
      setHeight(h);
    };
    if (ref.current) {
      ref.current.measure((x, y, w, h) => f(w, h));
    }
    return () => (f = () => {});
  }, [ref, screenWidth, extraInput]);

  return {
    width,
    height,
  };
}

export { useElementDimensions };
