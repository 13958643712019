import React, { useEffect } from 'react';
import './App.css';
import { Routes } from './components/routes';
import smoothscroll from 'smoothscroll-polyfill';
import {
  useUserInfo,
  USER_FIELD_AUTH_STATUS,
  AUTH_STATUS_UNKNOWN,
  AUTH_STATUS_LOADING,
  AUTH_STATUS_AUTHENTICATED,
  AUTH_STATUS_ANONYMOUS,
  AUTH_STATUS_ERROR,
} from './shared/services/apiService';
import { ScreenLoadingIndicator } from './shared/components/screenLoadingIndicator';
import { AppError } from './shared/components/appError';
import { AppErrorBoundary } from './shared/components/appErrorBoundary';
import { useLocale } from './shared/hooks/useLocale';
import { Languages } from './shared/util/enums';
import { useStaticTranslation } from './shared/services/staticTranslations';

//polyfill full support for scroll() method (https://developer.mozilla.org/en-US/docs/Web/API/Element/scroll)
//this ensures that the topic carousel animates on Safari and Firefox
smoothscroll.polyfill();

function App() {
  const authStatus = useUserInfo(USER_FIELD_AUTH_STATUS);
  const errorView = (
    <AppError
      action={() => (document.location = '/')}
      message={useStaticTranslation(
        'Please try again by refreshing your browser or return to the dashboard.'
      )}
    />
  );
  const loadingView = (
    <div style={{ marginTop: 100 }}>
      <ScreenLoadingIndicator center={true} />
    </div>
  );

  let viewForStatus = (s) => {
    switch (s) {
      case AUTH_STATUS_LOADING:
        return loadingView;
      case AUTH_STATUS_ANONYMOUS:
      case AUTH_STATUS_AUTHENTICATED:
        return <Routes loadingView={loadingView} errorView={errorView} />;
      case AUTH_STATUS_ERROR:
        return errorView;
      case AUTH_STATUS_UNKNOWN:
      default:
        return null;
    }
  };

  const locale = useLocale();
  useEffect(() => {
    if (document.querySelector) {
      const link = document.querySelector('link[rel=manifest]');
      if (link) {
        let href = link.href;
        if (locale === Languages.french) {
          href = href.replace('manifest.json', 'manifest.fr.json');
        } else {
          href = href.replace('manifest.fr.json', 'manifest.json');
        }
        link.href = href;
      }
    }
  }, [locale]);

  return (
    <div className="App">
      <AppErrorBoundary errorView={errorView}>
        {viewForStatus(authStatus)}
      </AppErrorBoundary>
    </div>
  );
}

export default App;
