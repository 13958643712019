import React from 'react';

import { OnboardingImage } from './OnboardingImage';

const OnboardingResourcesImage = function ({ style }) {
  const twoX = {
    es: require('./es/onboarding-resources@2x.jpg'),
    fr: require('./fr/onboarding-resources@2x.png'),
    en: require('./onboarding-resources@2x.png'),
  };
  const threeX = {
    es: require('./es/onboarding-resources@3x.jpg'),
    fr: require('./fr/onboarding-resources@3x.png'),
    en: require('./onboarding-resources@3x.png'),
  };
  return <OnboardingImage twoX={twoX} threeX={threeX} style={style} />;
};

export { OnboardingResourcesImage };
