import { useState, useEffect, useMemo } from 'react';
import { Dimensions, Platform } from 'react-native';

import { PubSub } from '../services/pubsub';
import { Breakpoints } from '../util/enums';

const ScreenSizes = {
  SM: 1,
  MD: 2,
  LG: 3,
  XL: 4,
};

const StoredDimensions = new (function () {
  const window = Dimensions.get('window');
  this.width = window.width;
  this.height = window.height;
  this.pubsub = new PubSub();
  this.transformToLandscape = false;

  const transformDimensions = window => {
    //we have to do this because on Android tablets where the screen orientation is locked to landscape (app.js),
    // the height and width here may still return portrait numbers, which will screw up the interface
    if (this.transformToLandscape) {
      return {
        width: Math.max(window.width, window.height),
        height: Math.min(window.width, window.height),
      };
    } else {
      return window;
    }
  };

  this.landscapeLock = () => {
    this.transformToLandscape = true;
    const window = transformDimensions(Dimensions.get('window'));
    this.width = window.width;
    this.height = window.height;
    this.pubsub.fire('change', window);
  };

  const updateDimensions = ({ window }) => {
    const w = transformDimensions(window);
    if (Platform.OS === 'android' && !this.transformToLandscape && w.width > w.height) {
      //this is an unexpected screen rotation event on a portrait mode android device
      //if width is > height then the device has been rotated to landscape while playing a video and we should
      //ignore this event to avoid an unnecessary re-render
      return;
    } else if (Platform.OS === 'ios' && w.width === this.height && w.height === this.width) {
      //this is an unexpected screen rotation event on an iOS device
      //this seems to happen on iPhone when you full screen and then rotate a vimeo video
      //in that case, we look for that scenario and ignore it to avoid unnecessary re-renders
      return;
    }
    this.width = w.width;
    this.height = w.height;
    this.pubsub.fire('change', w);
  };
  Dimensions.addEventListener('change', updateDimensions);
})();

function useScreenDimensions() {
  const [width, setWidth] = useState(StoredDimensions.width);
  const [height, setHeight] = useState(StoredDimensions.height);

  useEffect(() => {
    const ref = StoredDimensions.pubsub.on('change', ({ width, height }) => {
      setWidth(width);
      setHeight(height);
    });
    return () => StoredDimensions.pubsub.off(ref);
  }, []);

  return { width, height };
}

function useScreenSize() {
  //most phones are less than 420 wide, some giant phone might approach 600 wide
  //most tablets are min 768 wide, up to maybe 1024, though some tiny ones are 600 wide
  //most computers start at 1280
  const { width } = useScreenDimensions();
  //TODO: look at changing LG to start at 1024 for ipads
  return useMemo(
    () =>
      width <= Breakpoints.SM
        ? ScreenSizes.SM
        : width <= Breakpoints.MD
          ? ScreenSizes.MD
          : width <= Breakpoints.LG
            ? ScreenSizes.LG
            : ScreenSizes.XL,
    [width]
  );
}

function useStylesForWidth(styles, name, ...extras) {
  const size = useScreenSize();

  return useMemo(() => {
    const values = [styles[name] || {}];
    switch (size) {
      case ScreenSizes.SM:
        values.push(styles[name + 'SM'] || {});
        values.push(styles[name + 'SMMD'] || {});
        values.push(styles[name + 'SMMDLG'] || {});
        break;
      case ScreenSizes.MD:
        values.push(styles[name + 'MD'] || {});
        values.push(styles[name + 'SMMD'] || {});
        values.push(styles[name + 'SMMDLG'] || {});
        values.push(styles[name + 'MDLG'] || {});
        values.push(styles[name + 'MDLGXL'] || {});
        break;
      case ScreenSizes.LG:
        values.push(styles[name + 'LG'] || {});
        values.push(styles[name + 'SMMDLG'] || {});
        values.push(styles[name + 'MDLG'] || {});
        values.push(styles[name + 'MDLGXL'] || {});
        values.push(styles[name + 'LGXL'] || {});
        break;
      case ScreenSizes.XL:
        values.push(styles[name + 'XL'] || {});
        values.push(styles[name + 'MDLGXL'] || {});
        values.push(styles[name + 'LGXL'] || {});
        break;
      default:
        break;
    }

    extras.forEach(ex => values.push(ex));
    return values;
  }, [styles, name, size, extras]);
}

export { useScreenSize, useScreenDimensions, useStylesForWidth, StoredDimensions, ScreenSizes };
