import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { ContentPopup } from './contentPopup';
import { useActivityCompletion } from '../../hooks/useActivityCompletion';
import {
  TranslatableResourceTypes,
  useDynamicTranslation,
} from '../../hooks/useDynamicTranslation';
import { useLocale } from '../../hooks/useLocale';
import { ApiService, useApiRequest } from '../../services/apiService';
import { staticTranslation } from '../../services/staticTranslations';
import { AppColors } from '../../services/styles';
import { AnalyticsContentTypes, Languages } from '../../util/enums';
import { analyticsSelectContent } from '../../util/firebaseHelper';
import {
  getCategoryTitle,
  imageClassForResourceOrActivity,
  isItemComplete,
} from '../../util/utils';
import { AudioPlayer } from '../audioPlayer';
import { Paragraph } from '../paragraph';
import { RichTextDisplay } from '../richTextDisplay';
import { ScreenLoadingIndicator } from '../screenLoadingIndicator';
import { WarningText } from '../warningText';

const ToggleButton = function (props) {
  return (
    <TouchableOpacity
      style={[styles.aapToggleButton, props.active ? styles.aapToggleButtonActive : {}]}
      onPress={() => props.action()}>
      <Paragraph
        bold
        style={[styles.aapToggleButtonText, props.active ? styles.aapToggleButtonTextActive : {}]}>
        {props.title}
      </Paragraph>
    </TouchableOpacity>
  );
};

const AudioActivityPopup = function (props) {
  const FEMALE = 1;
  const MALE = 2;
  const { close, onCompleted, trackProgress } = props;
  const activityInfo = props.activity;
  const id = activityInfo.id;
  const [voice, setVoice] = useState(FEMALE);
  const apiRequest = useApiRequest(
    cb => {
      return ApiService.getActivity(id, cb);
    },
    [id],
    true
  );
  const fetchedActivity = apiRequest.data ? apiRequest.data.activity || {} : {};
  const activityCompletion = useActivityCompletion(activityInfo.id, onCompleted);
  const preferredLanguage = useLocale();

  useEffect(() => analyticsSelectContent(AnalyticsContentTypes.audioActivity, id), [id]);
  const title = useDynamicTranslation(
    id,
    TranslatableResourceTypes.ACTIVITY,
    'title',
    activityInfo.title
  );
  const body = useDynamicTranslation(
    id,
    TranslatableResourceTypes.ACTIVITY,
    'body',
    fetchedActivity.body
  );
  const translatedMaleAudioFile = useDynamicTranslation(
    id,
    TranslatableResourceTypes.ACTIVITY,
    'male_audio_file',
    null
  );
  const translatedFemaleAudioFile = useDynamicTranslation(
    id,
    TranslatableResourceTypes.ACTIVITY,
    'female_audio_file',
    null
  );
  const hasOnlyOneTranslatedAudioFile =
    (translatedMaleAudioFile || translatedFemaleAudioFile) &&
    (!translatedMaleAudioFile || !translatedFemaleAudioFile);
  const maleAudioFile =
    translatedMaleAudioFile ||
    (fetchedActivity
      ? {
          id: fetchedActivity.male_audio_id,
          audio_duration: fetchedActivity.male_audio_duration,
        }
      : {});
  const femaleAudioFile =
    translatedFemaleAudioFile ||
    (fetchedActivity
      ? {
          id: fetchedActivity.female_audio_id,
          audio_duration: fetchedActivity.female_audio_duration,
        }
      : {});
  useEffect(() => {
    if (hasOnlyOneTranslatedAudioFile) {
      setVoice(translatedFemaleAudioFile ? FEMALE : MALE);
    }
  }, [hasOnlyOneTranslatedAudioFile, translatedFemaleAudioFile, FEMALE, MALE]);

  return (
    <ContentPopup
      title={title}
      subtitle={getCategoryTitle(activityInfo)}
      close={close}
      contentStyle={styles.aapContent}
      imageClass={imageClassForResourceOrActivity(activityInfo)}>
      {preferredLanguage !== Languages.english && hasOnlyOneTranslatedAudioFile ? null : ( //this is a very special case to support translations where only one of the male or female voice has been added to the CMS
        <View style={styles.aapToggleView}>
          <ToggleButton
            action={() => setVoice(FEMALE)}
            active={voice === FEMALE}
            title={staticTranslation('Female Voice')}
          />
          <ToggleButton
            action={() => setVoice(MALE)}
            active={voice === MALE}
            title={staticTranslation('Male Voice')}
          />
        </View>
      )}
      {apiRequest.isBusy ? (
        <ScreenLoadingIndicator center />
      ) : apiRequest.error ? (
        <WarningText>{apiRequest.error}</WarningText>
      ) : (
        <>
          <RichTextDisplay style={styles.aapBody} html={body} />
          <AudioPlayer
            style={styles.aapAudioPlayer}
            audioDuration={
              parseFloat(
                voice === FEMALE ? femaleAudioFile.audio_duration : maleAudioFile.audio_duration
              ) || 0
            }
            title={title}
            onTrackCompleted={() => {
              if (!isItemComplete(activityInfo) && trackProgress) {
                activityCompletion.markComplete();
              }
            }}
            audioId={voice === FEMALE ? femaleAudioFile.id : maleAudioFile.id}
          />
        </>
      )}
    </ContentPopup>
  );
};

const styles = StyleSheet.create({
  aapContent: {
    alignItems: 'center',
  },
  aapToggleView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  aapToggleButton: {
    width: 150,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderBottomColor: AppColors.lightBackground,
  },
  aapToggleButtonText: {
    color: AppColors.darkText,
  },
  aapToggleButtonTextActive: {
    color: AppColors.brandedBlue,
  },
  aapToggleButtonActive: {
    borderBottomColor: AppColors.brandedBlue,
  },
  aapBody: {
    textAlign: 'center',
  },
  aapAudioPlayer: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export { AudioActivityPopup };
