import React from 'react';

import { SubscribePopup } from './SubscribePopup';
import { AudioActivityPopup } from './audioActivityPopup';
import { WrittenActivityPopup } from './writtenActivityPopup';
import { USER_FIELD_IS_TRIAL, useUserInfo } from '../../services/apiService';
import { ActivityType } from '../../util/enums';

const ActivityPopup = function (props) {
  const { activity, close, trackProgress } = props;
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);
  const onCompleted = () => (activity.user_percent_complete = 100);

  if (isTrialUser && !activity.is_trial_content) {
    return <SubscribePopup close={close} />;
  }

  if (activity.activity_type === ActivityType.MEDITATION) {
    return (
      <AudioActivityPopup
        close={close}
        trackProgress={trackProgress}
        onCompleted={onCompleted}
        activity={activity}
      />
    );
  } else {
    return (
      <WrittenActivityPopup
        close={close}
        trackProgress={trackProgress}
        onCompleted={() => {
          onCompleted();
          close();
        }}
        activity={activity}
      />
    );
  }
};

export { ActivityPopup };
