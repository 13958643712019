import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Paragraph, ParagraphSizes } from './paragraph';
import { useStaticTranslation } from '../services/staticTranslations';

const Copyright = function (props) {
  const style = props.style || {};
  const color = props.color ? { color: props.color } : {};
  return (
    <View style={[styles.copyrightView, style]}>
      <Paragraph size={ParagraphSizes.SMALL} style={color}>
        &copy; 2009-2020{' '}
        {useStaticTranslation('Canadian Institute Of Natural And Integrative Medicine')}
      </Paragraph>
    </View>
  );
};

const styles = StyleSheet.create({
  copyrightView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export { Copyright };
