import React from 'react';
import { StyleSheet } from 'react-native';

import { useLocale } from '../../hooks/useLocale';
import { Languages } from '../../util/enums';

const LogoSmallDashboardImage = function ({ style }) {
  const useFrench = useLocale() === Languages.french;
  const oneX = useFrench
    ? require('./logo-small-dashboard-fr.png')
    : require('./logo-small-dashboard.png');
  const twoX = useFrench
    ? require('./logo-small-dashboard-fr@2x.png')
    : require('./logo-small-dashboard@2x.png');
  const threeX = useFrench
    ? require('./logo-small-dashboard-fr@3x.png')
    : require('./logo-small-dashboard@3x.png');

  return (
    <div
      style={{
        overflow: 'hidden',
        alignSelf: 'center',
        width: useFrench ? 78 : 102,
        height: 15,
        ...StyleSheet.flatten(style),
      }}>
      <img
        src={threeX}
        alt="Breathing Room"
        style={{
          width: useFrench ? 78 : 167,
          height: useFrench ? 27 : 80,
          position: 'relative',
          top: useFrench ? -7 : -33,
          left: useFrench ? 0 : -33,
        }}
        srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
      />
    </div>
  );
};

export { LogoSmallDashboardImage };
