import React from 'react';
import { Image, Platform, StyleSheet } from 'react-native';

import { useLocale } from '../../hooks/useLocale';
import { Breakpoints, Languages } from '../../util/enums';

const OnboardingImage = function ({ style, oneX, twoX, threeX }) {
  const locale = useLocale();
  const one = oneX ? oneX[locale] || oneX[Languages.english] : null;
  if (Platform.OS === 'web') {
    const two = twoX[locale] || twoX[Languages.english];
    const three = threeX[locale] || threeX[Languages.english];
    return (
      <img
        src={three}
        alt=""
        style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
        sizes={`(max-width:${Breakpoints.SM}px) 100vw, 500px`}
        srcSet={(one ? one + ' 375w, ' : '') + `${two} 750w, ${three} 1125w`}
      />
    );
  } else {
    return <Image source={one} resizeMode="cover" resizeMethod="scale" style={style} />;
  }
};

export { OnboardingImage };
