import React, { useState, useRef } from 'react';
import { View, TouchableOpacity, TouchableHighlight, ActivityIndicator } from 'react-native';

import { AP_JUMP_INTERVAL, AP_STYLES, AudioTimeline } from './audioPlayerUtils';
import { Icon } from './icon';
import { urlForMedia } from '../services/apiService';
import { useStaticTranslation } from '../services/staticTranslations';
import { AppColors, IconNames, IconSizes } from '../services/styles';
import { isItemComplete } from '../util/utils';

const AudioPlayer = function (props) {
  const { onTrackCompleted, audioId } = props;
  const propStyle = props.style || {};

  const [playing, setPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioElement = useRef(null);
  const noticeSent = useRef(false);

  function playPause() {
    if (!playing) {
      // noinspection JSUnresolvedFunction
      audioElement.current.play();
    } else {
      // noinspection JSUnresolvedFunction
      audioElement.current.pause();
    }
  }

  const sendCompletedEvent = () => {
    if (!noticeSent.current && onTrackCompleted) {
      onTrackCompleted();
      noticeSent.current = true;
    }
  };

  const audioEvents = {
    onTimeUpdate: info => {
      setTime(info.currentTarget.currentTime);
      const percent = info.currentTarget.duration
        ? info.currentTarget.currentTime / info.currentTarget.duration
        : 0;
      if (
        isItemComplete({
          user_percent_complete: Math.round(100 * percent),
        })
      ) {
        sendCompletedEvent();
      }
    },
    onDurationChange: info => {
      setDuration(info.currentTarget.duration);
      setCanPlay(info.currentTarget.duration > 0);
    },
    onPause: () => setPlaying(false),
    onPlay: () => setPlaying(true),
    onCanPlay: () => setCanPlay(true),
    onCanPlayThrough: () => setCanPlay(true),
    onWaiting: () => setCanPlay(false),
    onEmptied: info => {
      setCanPlay(false);
      setPlaying(false);
    },
    onStalled: () => setCanPlay(false),
    onEnded: () => {
      setPlaying(false);
      sendCompletedEvent();
    },
  };

  function skip(seconds) {
    audioElement.current.currentTime = Math.min(Math.max(time + seconds, 0), duration);
  }

  //https://developer.mozilla.org/en-US/docs/Web/HTML/Element/audio
  return (
    <View style={[AP_STYLES.audioView, propStyle]}>
      <AudioTimeline position={time} duration={duration} />
      <View style={AP_STYLES.audioControls}>
        <TouchableOpacity
          onPress={() => skip(AP_JUMP_INTERVAL * -1)}
          style={AP_STYLES.audioSkipButton}
          disabled={!canPlay}>
          <Icon color={AppColors.darkText} size={IconSizes.large} name={IconNames.skipBack30} />
        </TouchableOpacity>
        <TouchableHighlight
          onPress={() => playPause()}
          style={AP_STYLES.audioPlayButton}
          disabled={!canPlay}>
          {canPlay ? (
            <Icon
              color={AppColors.white}
              size={IconSizes.large}
              name={playing ? IconNames.pause : IconNames.play}
            />
          ) : (
            <ActivityIndicator size="large" color={AppColors.white} />
          )}
        </TouchableHighlight>
        <TouchableOpacity
          onPress={() => skip(AP_JUMP_INTERVAL)}
          style={AP_STYLES.audioSkipButton}
          disabled={!canPlay}>
          <Icon color={AppColors.darkText} size={IconSizes.large} name={IconNames.skipForward30} />
        </TouchableOpacity>
      </View>
      <audio
        {...audioEvents}
        src={urlForMedia(audioId)}
        crossOrigin="use-credentials"
        preload="auto"
        ref={audioElement}>
        {useStaticTranslation('Your browser does not support audio elements.')}
      </audio>
    </View>
  );
};

export { AudioPlayer };
