import React from 'react';
import { StyleSheet } from 'react-native';

const QAAnswer1Image = function ({ style }) {
  const oneX = require('./NotAtAll.png');
  const twoX = require('./NotAtAll@2x.png');
  const threeX = require('./NotAtAll@3x.png');
  return (
    <img
      src={threeX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'contain' }}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
    />
  );
};

export { QAAnswer1Image };
