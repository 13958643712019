import React from 'react';
import { StyleSheet } from 'react-native';

import { ScreenSizes, useScreenSize, useStylesForWidth } from '../hooks/useStyleForWidth';
import { DashboardBannerImage } from '../img/dashboard-banner/DashboardBannerImage';
import { DashboardBannerSMImage } from '../img/dashboard-banner-sm/DashboardBannerSMImage';
import { AppStyles } from '../services/styles';

const ScreenBannerImage = function () {
  const ImageClass =
    useScreenSize() === ScreenSizes.SM ? DashboardBannerSMImage : DashboardBannerImage;
  return <ImageClass style={useStylesForWidth(styles, 'sbiImage')} />;
};

const styles = StyleSheet.create({
  sbiImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
  },
  sbiImageMDLGXL: {
    height: 400,
    borderTopLeftRadius: AppStyles.largeBorderRadius,
  },
  sbiImageSM: {
    height: 300,
    borderBottomRightRadius: AppStyles.largeBorderRadius,
  },
});

export { ScreenBannerImage };
