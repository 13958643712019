import { useEffect, useState } from 'react';
import { Keyboard, LayoutAnimation, Platform } from 'react-native';

function useSpaceForKeyboard(alwaysZero = false) {
  const [spaceForKeyboard, setSpaceForKeyboard] = useState(0);

  useEffect(() => {
    const listeners = [];
    if (!alwaysZero) {
      listeners.push(
        Platform.OS === 'ios'
          ? Keyboard.addListener('keyboardWillHide', event => {
              const { duration, easing } = event;
              const d = Math.max(10, duration || 100);
              LayoutAnimation.configureNext({
                duration: d,
                update: {
                  duration: d,
                  type: LayoutAnimation.Types[easing] || 'keyboard',
                },
              });
              setSpaceForKeyboard(0);
            })
          : Keyboard.addListener('keyboardDidHide', () => setSpaceForKeyboard(0))
      );
      listeners.push(
        Keyboard.addListener('keyboardDidShow', event =>
          setSpaceForKeyboard(event.endCoordinates.height)
        )
      );
    }
    return () => listeners.forEach(subscription => subscription.remove());
  }, [alwaysZero]);

  return spaceForKeyboard;
}

export { useSpaceForKeyboard };
