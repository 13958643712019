import { Platform, Linking } from 'react-native';

import { ActivityType, ResourceCategory } from './enums';
import { ActivityBannerImage } from '../img/activity-banner/ActivityBannerImage';
import { DashboardButtonImage } from '../img/dashboard-button/DashboardButtonImage';
import { OnboardingButtonImage } from '../img/onboarding-button/OnboardingButtonImage';
import { ResourceAudioImage } from '../img/resource-audio/ResourceAudioImage';
import { ResourceAudioButtonImage } from '../img/resource-audio-button/ResourceAudioButtonImage';
import { ResourceBookImage } from '../img/resource-book/ResourceBookImage';
import { ResourceBookButtonImage } from '../img/resource-book-button/ResourceBookButtonImage';
import { ResourceDownloadImage } from '../img/resource-download/ResourceDownloadImage';
import { ResourceDownloadButtonImage } from '../img/resource-download-button/ResourceDownloadButtonImage';
import { ResourceLightPurpleImage } from '../img/resource-light-purple/ResourceLightPurpleImage';
import { ResourceLightPurpleButtonImage } from '../img/resource-light-purple-button/ResourceLightPurpleButtonImage';
import { ResourceMovieImage } from '../img/resource-movie/ResourceMovieImage';
import { ResourceMovieButtonImage } from '../img/resource-movie-button/ResourceMovieButtonImage';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors, IconNames } from '../services/styles';

function getShadowStyles(options = {}) {
  const hOffset = options.hOffset || 0;
  const vOffset = options.vOffset || 0;
  const blur = options.blur || 12;
  const spread = options.spread || 1;
  const zIndex = typeof options.zIndex === 'undefined' ? {} : { zIndex: options.zIndex };
  const color = options.color || AppColors.withOpacity('#000000', 0.1);
  if (Platform.OS === 'web') {
    return {
      boxShadow: `${hOffset}px ${vOffset}px ${blur}px ${spread}px ${color}`,
      ...zIndex,
    };
  } else if (Platform.OS === 'ios') {
    return {
      shadowColor: color,
      shadowOffset: {
        width: hOffset,
        height: vOffset,
      },
      shadowRadius: blur,
      shadowOpacity: 1,
      ...zIndex,
    };
  } else {
    return {
      elevation: typeof options.zIndex === 'undefined' ? 5 : options.zIndex,
      ...zIndex,
    };
  }
}

function getAssociativeArray(object) {
  const results = [];
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      results.push({ key, value: object[key] });
    }
  }
  return results;
}

function openUrlAction(openUrl) {
  return () => {
    if (openUrl) {
      if (Platform.OS === 'web') {
        window.open(openUrl, openUrl.indexOf('mailto:') === 0 ? '_self' : '_blank');
      } else {
        Linking.canOpenURL(openUrl).then(supported => {
          if (supported) {
            Linking.openURL(openUrl);
          }
        });
      }
    }
  };
}

function isItemComplete(item) {
  return item.user_percent_complete >= 90;
}

function iconForActivity(activity) {
  switch (activity.activity_type) {
    case ActivityType.MEDITATION:
      return IconNames.audio;
    case ActivityType.ACTIVITY:
      return IconNames.writtenActivity;
    case ActivityType.JOURNALING:
      return IconNames.pencil;
    default:
      return null;
  }
}

function getCategoryTitle(resourceOrActivity, singular = true) {
  return staticTranslation(getCategoryTitleKey(resourceOrActivity, singular));
}

function getCategoryTitleKey(resourceOrActivity, singular = true) {
  if (resourceOrActivity.activity_type) {
    switch (parseInt(resourceOrActivity.activity_type, 10)) {
      case ActivityType.MEDITATION:
        return `Meditation${singular ? '' : 's'}`;
      case ActivityType.JOURNALING:
        return 'Journaling';
      default:
        return singular ? 'Activity' : 'Activities';
    }
  } else {
    switch (parseInt(resourceOrActivity.category, 10)) {
      case ResourceCategory.FileDownload:
        return `Download Noun${singular ? '' : 's'}`;
      case ResourceCategory.AudioFile:
        return 'Audio';
      case ResourceCategory.BookRecommendation:
        return `Book${singular ? '' : 's'}`;
      case ResourceCategory.MovieRecommendation:
        return `Movie${singular ? '' : 's'}`;
      case ResourceCategory.SongRecommendation:
        return `Song${singular ? '' : 's'}`;
      case ResourceCategory.Tutorial:
        return 'Introduction';
      default:
        return `Resource${singular ? '' : 's'}`;
    }
  }
}

function percentCompletionForTopic(topic, deepCheck = false) {
  if (!topic || !topic.id) {
    return 0;
  }
  let total = 1,
    done = topic.user_assessment_completed ? 1 : 0;
  if (deepCheck) {
    topic.segments.forEach(segment => {
      total += segment.total_steps;
      done += segment.steps.filter(step => isItemComplete(step)).length;
    });
    total += topic.activities.length;
    done += topic.activities.filter(activity => isItemComplete(activity)).length;
  } else if (typeof topic.done_steps === 'undefined') {
    topic.segments.forEach(segment => {
      total += segment.total_exercises + segment.total_steps;
      done += segment.done_steps + segment.done_exercises;
    });
  } else {
    done += topic.done_steps + topic.done_exercises;
    total += topic.total_steps + topic.total_exercises;
  }
  const percent = total > 0 ? done / total : 0;
  return Math.round(percent * 100);
}

function isTouchScreen(ifUnknown) {
  if (Platform.OS !== 'web') {
    return true;
  }
  if (!window.matchMedia) {
    //there is no real way to tell
    return ifUnknown;
  }
  const coarsePointer = !!window.matchMedia('(pointer: coarse)').matches;
  if (coarsePointer) {
    return coarsePointer;
  }
  //maybe pointer: isn't supported by this browser, so check the other possibilities
  const finePointer = !!window.matchMedia('(pointer: fine)').matches;
  const noPointer = !!window.matchMedia('(pointer: none)').matches;
  if (finePointer || noPointer) {
    return coarsePointer; //pointer: is supported, it is just false
  }
  //there is no real way to tell
  return ifUnknown;
}

//option 1: use onClick only if platform is web AND
//On web browsers, the onPress action often conflicts with parent scroll views, resulting in unwanted clicks when scrolling.
//The solution is to use the onClick event, which relies on the browser to handle events instead of react native web.
//The problem with this solution is that keypress events are lost, which breaks usage of the space bar and enter key to fire actions.
//SO we only want this hack for devices that are on the web AND have no physical keyboard.

//upgrading RNW to 15 has rendered this problem moot, so we can use onPress instead of onClick (which doesn't even work)
//TODO: remove altogether
function pressPropsForTouchable(action, disabled = false) {
  if (!action) return {};
  return {
    onPress: e => {
      e.preventDefault(); //prevent default because onPress is triggered onKeyDown of enter key, and the enter key's onkeypress can fire elsewhere
      action();
    },
  };
}

function imageClassForResourceOrActivity(resourceOrActivity, button = false) {
  if (resourceOrActivity.activity_type) {
    switch (parseInt(resourceOrActivity.activity_type, 10)) {
      case ActivityType.ACTIVITY:
        return button ? ResourceAudioButtonImage : ResourceAudioImage; //audio category is out of use for now, so use the images for activities
      case ActivityType.JOURNALING:
      case ActivityType.MEDITATION:
      default:
        return button ? DashboardButtonImage : ActivityBannerImage;
    }
  } else {
    switch (parseInt(resourceOrActivity.category, 10)) {
      case ResourceCategory.FileDownload:
        return button ? ResourceMovieButtonImage : ResourceMovieImage;
      case ResourceCategory.BookRecommendation:
        return button ? ResourceBookButtonImage : ResourceBookImage;
      case ResourceCategory.MovieRecommendation:
        return button ? ResourceDownloadButtonImage : ResourceDownloadImage;
      case ResourceCategory.SongRecommendation:
        return button ? ResourceLightPurpleButtonImage : ResourceLightPurpleImage;
      case ResourceCategory.Tutorial:
        return button ? OnboardingButtonImage : ActivityBannerImage;
      default:
        return button ? DashboardButtonImage : ActivityBannerImage;
    }
  }
}

//For scroll views where the width of paged items is not the same as the width of the scrollview
function pagingAttributesForScrollView(itemWidth) {
  //RNW pages by width of child elements only
  //RN pages by width of the scrollview (not what we want) if pagingEnabled is used
  return Platform.OS === 'web'
    ? { pagingEnabled: true }
    : { snapToInterval: itemWidth, decelerationRate: 'fast' };
}

const TIME_TO_COMPLETE_ASSESSMENT = 5;

const noop = () => {};

export {
  noop,
  isItemComplete,
  getCategoryTitle,
  percentCompletionForTopic,
  iconForActivity,
  openUrlAction,
  getAssociativeArray,
  pressPropsForTouchable,
  isTouchScreen,
  getShadowStyles,
  imageClassForResourceOrActivity,
  pagingAttributesForScrollView,
  TIME_TO_COMPLETE_ASSESSMENT,
};
