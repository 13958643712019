import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Paragraph } from '../shared/components/paragraph';
import About from '../shared/util/about';
import { ApiService, useApiRequest } from '../shared/services/apiService';

let HealthCheckScreen = function () {
  const request = useApiRequest(
    (cb) => {
      return ApiService.healthCheck(cb);
    },
    [],
    true
  );
  const data = request.data;

  const apiInfo = useMemo(() => {
    let results = [];
    for (let key in data || {}) {
      if (data.hasOwnProperty(key)) {
        let value = data[key];
        switch (typeof value) {
          case 'object':
            value = value.healthy
              ? 'Healthy'
              : `Not Healthy (${value.error})`;
            break;
          case 'boolean':
            value = value ? 'Yes' : 'No';
            break;
          default:
        }
        results.push(
          <Paragraph key={key}>
            API {key}: {value}
          </Paragraph>
        );
      }
    }
    return results;
  }, [data]);

  return (
    <View style={{ margin: 50 }}>
      <Paragraph>BreathingRoom Status</Paragraph>
      <Paragraph>App Version: {About.webVersion}</Paragraph>
      <Paragraph>App Build: {About.webBuild}</Paragraph>
      <Paragraph>
        {request.isBusy ? 'loading...' : request.error ? request.error : ' '}
      </Paragraph>
      {!request.succeeded ? null : <View>{apiInfo}</View>}
      <p>
        <a href="/">Go to home</a>
      </p>
    </View>
  );
};

export { HealthCheckScreen };
