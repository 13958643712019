import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Icon } from '../components/icon';
import { AppColors, AppStyles, IconNames, IconSizes } from '../services/styles';

const CompletedBadgeSizes = {
  NORMAL: 'NORMAL',
  SMALL: 'SMALL',
};

const CompletedBadge = function (props) {
  const size = props.size || CompletedBadgeSizes.NORMAL;

  return (
    <View style={[styles.cbParagraph, styles['cbParagraph' + size]]}>
      <Icon name={IconNames.checkmark} size={IconSizes.small} color={AppColors.white} />
    </View>
  );
};

const styles = StyleSheet.create({
  cbParagraph: {
    backgroundColor: AppColors.brandedOrange,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cbParagraphNORMAL: {
    height: 30,
    width: 30,
    borderRadius: AppStyles.smallBorderRadius,
  },
  cbParagraphSMALL: {
    width: 20,
    height: 20,
    borderRadius: AppStyles.tinyBorderRadius,
  },
});

export { CompletedBadge, CompletedBadgeSizes };
