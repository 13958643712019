import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Paragraph, ParagraphSizes } from './paragraph';
import { RadioButton } from './radioButton';
import { Languages } from '../util/enums';

const LanguageToggleField = function (props) {
  const { style, selectedLanguage, onToggle } = props;

  return (
    <View style={[styles.langToggleView, style || {}]}>
      <RadioButton
        selected={selectedLanguage === Languages.english}
        style={{ marginRight: 10 }}
        action={() => onToggle(Languages.english)}>
        <Paragraph size={ParagraphSizes.SMALL}>
          {Languages.displayLanguage(Languages.english)}
        </Paragraph>
      </RadioButton>
      <RadioButton
        selected={selectedLanguage === Languages.french}
        style={{ marginRight: 10 }}
        action={() => onToggle(Languages.french)}>
        <Paragraph size={ParagraphSizes.SMALL}>
          {Languages.displayLanguage(Languages.french)}
        </Paragraph>
      </RadioButton>
      <RadioButton
        selected={selectedLanguage === Languages.spanish}
        action={() => onToggle(Languages.spanish)}>
        <Paragraph size={ParagraphSizes.SMALL}>
          {Languages.displayLanguage(Languages.spanish)}
        </Paragraph>
      </RadioButton>
    </View>
  );
};

const styles = StyleSheet.create({
  langToggleView: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginBottom: 20,
  },
});

export { LanguageToggleField };
