import Player from '@vimeo/player';
import React, { useEffect, useRef } from 'react';

import { useVimeoVideoPropValues } from '../hooks/useVimeoVideoPropValues';
import { AppStyles } from '../services/styles';

const VimeoVideo = function (props) {
  const propValues = useVimeoVideoPropValues(props);
  const videoId = propValues.videoId;
  const onError = propValues.onError;
  const onViewingProgress = propValues.onViewingProgress;
  const allowPlayback = propValues.allowPlayback;
  const iframeContainer = useRef(null);
  const iframe = useRef(null);
  const player = useRef(null);

  //https://developer.vimeo.com/player/sdk/reference#events-for-playback-controls
  useEffect(() => {
    if (player.current && player.current.br_video_id !== videoId) {
      player.current.destroy();
      player.current = null;
    }

    player.current = new Player(iframe.current);
    player.current.br_video_id = videoId;

    const timeupdateHandler = o => onViewingProgress(o);
    const errorHandler = e => onError(e);
    const playHandler = () => {
      if (!allowPlayback) {
        player.current.pause();
      }
    };
    player.current.on('timeupdate', timeupdateHandler);
    player.current.on('ended', timeupdateHandler);
    player.current.on('error', errorHandler);
    player.current.on('play', playHandler);

    // noinspection JSUnresolvedFunction
    Promise.all([player.current.getVideoWidth(), player.current.getVideoHeight()])
      .then(function (dimensions) {
        const width = dimensions[0];
        const height = dimensions[1];
        iframeContainer.current.style.paddingTop = (height / width) * 100 + '%';
      })
      .catch(errorHandler);

    const playerInstance = player.current;
    return () => {
      if (playerInstance) {
        playerInstance.off('error', errorHandler);
        playerInstance.off('timeupdate', timeupdateHandler);
        playerInstance.off('ended', timeupdateHandler);
        playerInstance.off('play', playHandler);
      }
    };
  }, [videoId, onError, onViewingProgress, allowPlayback]);

  useEffect(() => {
    return () => {
      player.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (!allowPlayback) {
      player.current.pause();
    }
  }, [allowPlayback]);

  const borderRadiusStyle = propValues.squareCorners
    ? {}
    : { borderRadius: AppStyles.normalBorderRadius };

  //https://developer.vimeo.com/player/sdk/basics
  // noinspection CheckTagEmptyBody
  return (
    <div
      className="VimeoContainer"
      style={{ overflow: 'hidden', ...borderRadiusStyle }}
      ref={iframeContainer}
      id="vimeoContainer">
      <iframe
        style={borderRadiusStyle}
        key={videoId}
        ref={iframe}
        title={`Video number ${videoId} from Vimeo`}
        className="VimeoIframe"
        src={propValues.src}
        allow="autoplay; fullscreen"
        allowFullScreen
        frameBorder="0"
      />
      {props.children}
    </div>
  );
};

export { VimeoVideo };
