import { useMemo } from 'react';

import { noop } from '../util/utils';

function useVimeoVideoPropValues(props) {
  const link = props.link;
  const videoId = link ? link.split('vimeo.com/')[1] : null;
  const querystring = props.autoplay ? '?autoplay=1' : '';
  const startTime = props.startTime || null;
  const startTimeHashTag = useMemo(() => {
    if (startTime) {
      return '#t=' + Math.floor(startTime / 60) + 'm' + (startTime % 60) + 's';
    }
    return '';
    // lint doesn't like this hack-ish use of a stale value
    // eslint-disable-next-line
  }, [videoId]); //use the stale value of startTime only from when video ID has changed

  const src = videoId
    ? 'https://player.vimeo.com/video/' + videoId + querystring + startTimeHashTag
    : null;
  return {
    link,
    src,
    onError: props.onError || noop,
    onViewingProgress: props.onViewingProgress || noop,
    allowPlayback: !!props.allowPlayback,
    squareCorners: !!props.squareCorners,
    videoId,
  };
}

export { useVimeoVideoPropValues };
