import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import { ContentPopup } from './contentPopup';
import { useActivityCompletion } from '../../hooks/useActivityCompletion';
import {
  TranslatableResourceTypes,
  useDynamicTranslation,
} from '../../hooks/useDynamicTranslation';
import { staticTranslation } from '../../services/staticTranslations';
import { ActivityType, AnalyticsContentTypes } from '../../util/enums';
import { analyticsSelectContent } from '../../util/firebaseHelper';
import {
  getCategoryTitle,
  imageClassForResourceOrActivity,
  isItemComplete,
} from '../../util/utils';
import { ActionButton } from '../actionButton';
import { Paragraph } from '../paragraph';
import { RichTextDisplay } from '../richTextDisplay';

const WrittenActivityPopup = function (props) {
  const { activity, close, onCompleted, trackProgress } = props;

  const activityId = activity.id;
  const activityCompletion = useActivityCompletion(activityId, onCompleted);

  const type = parseInt(activity.activity_type, 10);
  useEffect(() => {
    const contentType =
      type === ActivityType.JOURNALING
        ? AnalyticsContentTypes.journalActivity
        : AnalyticsContentTypes.activityActivity;
    analyticsSelectContent(contentType, activityId);
  }, [type, activityId]);

  const completeButton = () => {
    return (
      <View>
        <View style={styles.wapButtonView}>
          <ActionButton
            showActivityIndicator={activityCompletion.isBusy}
            style={styles.wapButton}
            disabled={activityCompletion.isBusy}
            action={() => activityCompletion.markComplete()}>
            {staticTranslation('Mark As Complete')}
          </ActionButton>
        </View>
        {activityCompletion.error ? <Paragraph>{activityCompletion.error}</Paragraph> : null}
      </View>
    );
  };

  const title = useDynamicTranslation(
    activityId,
    TranslatableResourceTypes.ACTIVITY,
    'title',
    activity.title
  );
  const body = useDynamicTranslation(
    activityId,
    TranslatableResourceTypes.ACTIVITY,
    'body',
    activity.body
  );
  return (
    <ContentPopup
      title={title}
      subtitle={getCategoryTitle(activity)}
      close={close}
      imageClass={imageClassForResourceOrActivity(activity)}>
      <RichTextDisplay html={body} />
      {isItemComplete(activity) || !trackProgress ? null : completeButton()}
    </ContentPopup>
  );
};

const styles = StyleSheet.create({
  wapButtonView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  wapButton: {
    minWidth: '50%',
  },
});
export { WrittenActivityPopup };
