import { StyleSheet } from 'react-native';

import { useStylesForWidth } from './useStyleForWidth';
import { AppStyles } from '../services/styles';

function useAuthContentStyles() {
  return useStylesForWidth(styles, 'authContent');
}

const styles = StyleSheet.create({
  authContentSM: {
    padding: AppStyles.mainAuthScreenPaddingSM,
    paddingBottom: 80, //compensates for navigation menu on small screens
  },
  authContentMDLG: {
    padding: AppStyles.mainAuthScreenPaddingMDLG,
  },
  authContentXL: {
    padding: AppStyles.mainAuthScreenPaddingXL,
  },
});

export { useAuthContentStyles };
