import React, { useState } from 'react';
import { View, Platform, Text } from 'react-native';

import { AuthenticatedScreen } from './authenticatedScreen';
import { ActionButton, ActionButtonType } from '../components/actionButton';
import { ActionImage } from '../components/actionImage';
import { ActionLink } from '../components/actionLink';
import { AnonymousLanguageToggle } from '../components/anonymousLanguageToggle';
import { AudioPlayer } from '../components/audioPlayer';
import { Checkbox } from '../components/checkbox';
import { CompletedBadge, CompletedBadgeSizes } from '../components/completedBadge';
import { ContentBox } from '../components/contentBox';
import { ContentBoxRow } from '../components/contentBoxRow';
import { Copyright } from '../components/copyright';
import { CornerButton } from '../components/cornerButton';
import { DownloadButton } from '../components/downloadButton';
import { DraftBadge } from '../components/draftBadge';
import { Heading, HeadingType } from '../components/heading';
import { Icon } from '../components/icon';
import { InAppPurchase } from '../components/inAppPurchase';
import { InstitutionCodeHelp } from '../components/institutionCodeHelp';
import { LanguageToggleField } from '../components/languageToggleField';
import { MajorError } from '../components/majorError';
import { OrLine } from '../components/orLine';
import { Paragraph, ParagraphSizes } from '../components/paragraph';
import { ProgressBar, ProgressBarType } from '../components/progressBar';
import { RadioButton } from '../components/radioButton';
import { ResourcesList } from '../components/resourcesList';
import { RichTextDisplay } from '../components/richTextDisplay';
import { ScreenLoadingIndicator } from '../components/screenLoadingIndicator';
import { TextField } from '../components/textField';
import { TopicCarousel } from '../components/topicCarousel';
import { VimeoVideo } from '../components/vimeoVideo';
import { WarningText } from '../components/warningText';
import { useAuthContentStyles } from '../hooks/useAuthContentStyles';
import { AppColors, IconNames, IconSizes } from '../services/styles';
import { ActivityType, InAppPurchases, Languages, ResourceCategory } from '../util/enums';

const InterfaceLibraryScreen = function (props) {
  const [checked, setChecked] = useState(false);
  const [radio, setRadio] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [videoProgress2, setVideoProgress2] = useState(0);
  const topics = [
    {
      id: 1,
      title: 'Unlock The Real You',
      subtitle: 'Self-acceptance',
      publish_status: 1,
      is_trial_content: true,
      image_key: 'unlock',
      user_assessment_completed: false,
      total_steps: 13,
      done_steps: 0,
      total_exercises: 2,
      done_exercises: 0,
    },
    {
      id: 2,
      title: 'Connect With Life',
      subtitle: 'fill in',
      publish_status: 1,
      is_trial_content: false,
      image_key: 'connect',
      user_assessment_completed: false,
      total_steps: 1,
      done_steps: 1,
      total_exercises: 0,
      done_exercises: 0,
    },
    {
      id: 3,
      title: 'Discover Your Purpose',
      subtitle: 'fill in',
      publish_status: 1,
      is_trial_content: false,
      image_key: 'purpose',
      user_assessment_completed: false,
      total_steps: 1,
      done_steps: 0,
      total_exercises: 0,
      done_exercises: 0,
    },
    {
      id: 4,
      title: 'Find The Positive',
      subtitle: 'fill in',
      publish_status: 1,
      is_trial_content: false,
      image_key: 'positive',
      user_assessment_completed: false,
      total_steps: 1,
      done_steps: 0,
      total_exercises: 0,
      done_exercises: 0,
    },
    {
      id: 5,
      title: 'Reach Out',
      subtitle: 'fill in',
      publish_status: 1,
      is_trial_content: false,
      image_key: 'reach',
      user_assessment_completed: false,
      total_steps: 1,
      done_steps: 0,
      total_exercises: 0,
      done_exercises: 0,
    },
    {
      id: 6,
      title: 'Respond To Disappointments',
      subtitle: 'fill in',
      publish_status: 1,
      is_trial_content: false,
      image_key: 'respond',
      user_assessment_completed: false,
      total_steps: 1,
      done_steps: 0,
      total_exercises: 0,
      done_exercises: 0,
    },
    {
      id: 7,
      title: 'Deal With The Past',
      subtitle: 'fill in',
      publish_status: 1,
      is_trial_content: false,
      image_key: 'past',
      user_assessment_completed: false,
      total_steps: 1,
      done_steps: 0,
      total_exercises: 0,
      done_exercises: 0,
    },
    {
      id: 8,
      title: 'Move Forward',
      subtitle: 'fill in',
      publish_status: 1,
      is_trial_content: false,
      image_key: 'forward',
      user_assessment_completed: false,
      total_steps: 1,
      done_steps: 0,
      total_exercises: 0,
      done_exercises: 0,
    },
  ];
  const resources = [
    {
      id: 20,
      category: ResourceCategory.BookRecommendation,
      title: 'Sample Book 1',
    },
    {
      id: 21,
      category: ResourceCategory.BookRecommendation,
      title: 'Sample Book 2',
    },
    {
      id: 23,
      category: ResourceCategory.Tutorial,
      title: 'Tutorial',
    },
    {
      id: 24,
      activity_type: ActivityType.JOURNALING,
      title: 'Journaling Activity',
    },
    {
      id: 25,
      activity_type: ActivityType.ACTIVITY,
      title: 'Act Activity',
    },
    {
      id: 26,
      activity_type: ActivityType.MEDITATION,
      title: 'Meditation Activity',
    },
    {
      id: 27,
      category: ResourceCategory.BookRecommendation,
      title: 'Sample Book 7',
    },
    {
      id: 1,
      title: 'Test download',
      category: ResourceCategory.FileDownload,
      is_trial_content: false,
    },
    {
      id: 2,
      category: ResourceCategory.SongRecommendation,
      title: 'Sample Song',
    },
    {
      id: 3,
      category: ResourceCategory.MovieRecommendation,
      title: 'Sample Movie',
    },
    {
      id: 400,
      category: ResourceCategory.AudioFile,
      title: 'Sample Audio',
    },
  ];
  return (
    <AuthenticatedScreen {...props}>
      <View style={useAuthContentStyles()}>
        <Heading>Interface Library</Heading>
        <Heading type={HeadingType.TWO}>Heading Two</Heading>
        <Heading type={HeadingType.THREE}>Heading Three</Heading>
        <Heading type={HeadingType.FOUR}>Heading Four</Heading>
        <Heading type={HeadingType.FIVE}>Heading Five</Heading>
        <Paragraph size={ParagraphSizes.NORMAL}>A paragraph, this is a normal paragraph.</Paragraph>
        <Paragraph size={ParagraphSizes.SMALL}>A paragraph, this is a small paragraph.</Paragraph>
        <Paragraph size={ParagraphSizes.TINY}>A paragraph, this is a tiny paragraph.</Paragraph>
        <View style={{ width: 280 }}>
          <ActionButton>Primary</ActionButton>
          <ActionButton leftIcon={IconNames.checkmark} disabled>
            Primary Disabled
          </ActionButton>
          <ActionButton rightIcon={IconNames.tutorial} type={ActionButtonType.SECONDARY}>
            Secondary
          </ActionButton>
          <ActionButton
            leftIcon={IconNames.chevronLeft}
            rightIcon={IconNames.chevronRight}
            type={ActionButtonType.SECONDARY}
            disabled>
            Secondary Disabled
          </ActionButton>
          <ActionButton showActivityIndicator type={ActionButtonType.ORANGE}>
            Orange
          </ActionButton>
          <ActionButton type={ActionButtonType.ORANGE} disabled>
            Orange Disabled
          </ActionButton>
          <OrLine />
          <ActionImage
            disabled={false}
            style={{ width: 250, height: 50 }}
            imageSource={require('../img/resource-audio-button/resource-audio-button.png')}
          />
          <ActionLink>Normal Link</ActionLink>
          <ActionLink size={ParagraphSizes.SMALL} bold>
            Small Bold Link
          </ActionLink>
          <ActionLink leftIcon={IconNames.chevronLeft} disabled>
            Disabled Left Icon Link
          </ActionLink>
          <ActionLink
            size={ParagraphSizes.TINY}
            iconSize={IconSizes.small}
            rightIcon={IconNames.chevronRight}>
            Tiny Right Icon Link
          </ActionLink>
          <ActionLink
            leftIcon={IconNames.chevronLeft}
            iconSize={IconSizes.large}
            rightIcon={IconNames.chevronRight}>
            Double Large Icon Link
          </ActionLink>
          <DownloadButton fileId={1} fileName="test.gif" />

          <AudioPlayer audioId="unknown" />
          <Checkbox
            title="A white background checkbox"
            checked={checked}
            onChange={() => setChecked(c => !c)}
            whiteBackground
          />
          <Checkbox
            title="A normal checkbox"
            checked={checked}
            onChange={() => setChecked(c => !c)}
            whiteBackground={false}
          />
          <CompletedBadge size={CompletedBadgeSizes.NORMAL} />
          <CompletedBadge size={CompletedBadgeSizes.SMALL} />
          <DraftBadge />
          <CornerButton action={() => {}} />
          <ContentBox title="Content Box" actionButton={{ title: 'CB Action' }}>
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>Small Icon</Paragraph>}
              right={<Icon name={IconNames.chevronLeft} size={IconSizes.small} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>Blue Normal Icon</Paragraph>}
              right={
                <Icon
                  name={IconNames.chevronRight}
                  color={AppColors.brandedBlue}
                  size={IconSizes.normal}
                />
              }
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>Large Icon</Paragraph>}
              right={<Icon name={IconNames.chevronDown} size={IconSizes.large} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>XLarge Icon</Paragraph>}
              right={<Icon name={IconNames.chevronUp} size={IconSizes.xlarge} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>assessment Icon</Paragraph>}
              right={<Icon name={IconNames.assessment} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>checkmark Icon</Paragraph>}
              right={<Icon name={IconNames.checkmark} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>trackProgress Icon</Paragraph>}
              right={<Icon name={IconNames.trackProgress} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>lock Icon</Paragraph>}
              right={<Icon name={IconNames.lock} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>skipBack30 Icon</Paragraph>}
              right={<Icon name={IconNames.skipBack30} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>skipForward30 Icon</Paragraph>}
              right={<Icon name={IconNames.skipForward30} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>play Icon</Paragraph>}
              right={<Icon name={IconNames.play} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>pause Icon</Paragraph>}
              right={<Icon name={IconNames.pause} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>dashboard Icon</Paragraph>}
              right={<Icon name={IconNames.dashboard} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>library Icon</Paragraph>}
              right={<Icon name={IconNames.library} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>resources Icon</Paragraph>}
              right={<Icon name={IconNames.resources} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>progress Icon</Paragraph>}
              right={<Icon name={IconNames.progress} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>account Icon</Paragraph>}
              right={<Icon name={IconNames.account} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>audio Icon</Paragraph>}
              right={<Icon name={IconNames.audio} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>book Icon</Paragraph>}
              right={<Icon name={IconNames.book} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>movie Icon</Paragraph>}
              right={<Icon name={IconNames.movie} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>download Icon</Paragraph>}
              right={<Icon name={IconNames.download} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>close Icon</Paragraph>}
              right={<Icon name={IconNames.close} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>writtenActivity Icon</Paragraph>}
              right={<Icon name={IconNames.writtenActivity} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>clock Icon</Paragraph>}
              right={<Icon name={IconNames.clock} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>pencil Icon</Paragraph>}
              right={<Icon name={IconNames.pencil} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>logout Icon</Paragraph>}
              right={<Icon name={IconNames.logout} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>tutorial Icon</Paragraph>}
              right={<Icon name={IconNames.tutorial} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>openInNew Icon</Paragraph>}
              right={<Icon name={IconNames.openInNew} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>giftCard Icon</Paragraph>}
              right={<Icon name={IconNames.giftCard} size={IconSizes.normal} />}
            />
            <ContentBoxRow
              action={() => {}}
              left={<Paragraph>music Icon</Paragraph>}
              right={<Icon name={IconNames.music} size={IconSizes.normal} />}
            />
          </ContentBox>
          <Copyright />
          <InstitutionCodeHelp />
          <InAppPurchase disabled type={InAppPurchases.UNLOCK} onPurchasing={() => {}} />
          <InAppPurchase disabled={false} type={InAppPurchases.UNLOCK} onPurchasing={() => {}} />
          <MajorError
            title="Major Error"
            message="This is what a major error looks like"
            action={() => {}}
          />
          <View style={{ backgroundColor: AppColors.white, padding: 10 }}>
            <ProgressBar percent={30} type={ProgressBarType.colored} />
          </View>
          <View style={{ backgroundColor: AppColors.brandedBlue, padding: 10 }}>
            <ProgressBar percent={50} type={ProgressBarType.white} />
          </View>
          <View style={{ backgroundColor: AppColors.white, padding: 10 }}>
            <ProgressBar percent={70} type={ProgressBarType.squared} />
          </View>
          <View style={{ backgroundColor: AppColors.white, padding: 10 }}>
            <RadioButton selected={radio} action={() => setRadio(o => !o)}>
              <Paragraph
                style={{
                  color: AppColors.darkText,
                  ...(Platform.OS === 'web' ? { lineHeight: 'normal' } : {}),
                }}>
                A radio button with a very long title what will happen
              </Paragraph>
            </RadioButton>
            <RadioButton selected={radio} action={() => setRadio(o => !o)}>
              <Paragraph
                style={{
                  color: AppColors.darkText,
                  ...(Platform.OS === 'web' ? { lineHeight: 'normal' } : {}),
                }}>
                Another
              </Paragraph>
            </RadioButton>
          </View>
          <AnonymousLanguageToggle />
          <LanguageToggleField selectedLanguage={Languages.english} onToggle={() => {}} />
        </View>
        <Text style={{ borderBottomWidth: 2, borderBottomColor: '#ff0000' }}>a paragraph</Text>
        <ContentBox>
          <ResourcesList
            title="Resource List"
            resources={resources}
            columns={2}
            displayCategories
            onShowResource={() => {}}
            underlineTitle
          />
        </ContentBox>
        <ResourcesList
          title="Resource List"
          resources={resources}
          columns={1}
          displayCategories={false}
          onShowResource={() => {}}
          underlineTitle={false}
        />
        <RichTextDisplay html="<h1>Heading One</h1><h2>Heading two</h2><p>a paragraph with <b>Bold</b>, <i>Italic</i>, and <u>Underline</u></p><ol><li>ordered item</li><li>ordered item</li></ol><ul><li>unordered item</li><li>unordered item</li></ul>" />
        <ScreenLoadingIndicator center={false} />
        <ScreenLoadingIndicator center color={AppColors.brandedOrange} />
        <TextField
          inputProps={{
            disabled: false,
            maxLength: 100,
            blurOnSubmit: false,
            placeholder: 'Full Name',
            autoComplete: 'name',
            autoCompleteType: 'name',
            returnKeyType: 'next',
          }}
          autoFocusHardwareKeyboards={false}
        />
        <TextField
          inputProps={{
            disabled: false,
            maxLength: 100,
            blurOnSubmit: false,
            keyboardType: 'email-address',
            placeholder: 'Email',
            autoComplete: 'email',
            autoCompleteType: 'email',
            returnKeyType: 'go',
          }}
          autoFocusHardwareKeyboards={false}
        />
        <TextField
          inputProps={{
            maxLength: 100,
            blurOnSubmit: false,
            returnKeyType: 'go',
            disabled: false,
            autoComplete: 'current-password',
            autoCompleteType: 'password',
            placeholder: 'Password',
            secureTextEntry: true,
          }}
          icon={IconNames.lock}
          autoFocusHardwareKeyboards={false}
        />
        <TextField
          inputProps={{
            autoComplete: 'off',
            autoCompleteType: 'off',
            placeholder: 'Multi-line field',
            disabled: false,
            maxLength: 500,
            multiline: true,
            textAlignVertical: 'top',
          }}
          autoFocusHardwareKeyboards={false}
          style={{ height: 200 }}
        />
        <TextField
          inputProps={{
            disabled: true,
            placeholder: 'Disabled field',
          }}
          autoFocusHardwareKeyboards={false}
        />
        <TopicCarousel topics={topics} onSelect={() => {}} />
        <VimeoVideo
          autoplay={false}
          squareCorners={false}
          link="https://vimeo.com/404454842"
          startTime={0}
          onViewingProgress={({ percent }) => {
            setVideoProgress(Math.round(100 * percent));
          }}
          allowPlayback
          onError={info => console.error(info)}
        />
        <View style={{ backgroundColor: AppColors.white, padding: 10 }}>
          <ProgressBar percent={videoProgress} type={ProgressBarType.squared} />
        </View>
        <VimeoVideo
          autoplay={false}
          squareCorners
          link="https://vimeo.com/404454842"
          startTime={20}
          onViewingProgress={({ percent }) => {
            setVideoProgress2(Math.round(100 * percent));
          }}
          allowPlayback={false}
          onError={info => console.error(info)}
        />
        <View style={{ backgroundColor: AppColors.white, padding: 10 }}>
          <ProgressBar percent={videoProgress2} type={ProgressBarType.squared} />
        </View>
        <WarningText />
        <WarningText>This is a warning</WarningText>
      </View>
    </AuthenticatedScreen>
  );
};

export { InterfaceLibraryScreen };
