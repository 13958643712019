import React, { useEffect } from 'react';
import { View, Modal, StyleSheet, Platform } from 'react-native';

import {
  ScreenSizes,
  useScreenDimensions,
  useScreenSize,
  useStylesForWidth,
} from '../../hooks/useStyleForWidth';
import { AppColors, AppStyles } from '../../services/styles';
import { CornerButton } from '../cornerButton';

const ModalPopup = function (props) {
  const propStyle = props.style || {};
  const { close, isBelowSafeArea } = props;
  const closeColor = props.closeColor || AppColors.white;
  const screenHeight = useScreenDimensions().height;
  const screenSize = useScreenSize();
  const isSM = screenSize === ScreenSizes.SM;
  const maxContainerHeightStyle = !isSM ? { maxHeight: 0.8 * screenHeight } : {};
  const width = props.width || 500;
  const containerWidthStyle = isSM ? {} : { width, marginLeft: width * -0.5 };

  //TODO: use https://github.com/th3rdwave/react-native-safe-area-context or https://github.com/react-navigation/react-native-safe-area-view
  //iOS needs extra padding because the Modal element doesn't play nice with SafeAreaView in app.js
  const iPhoneTop = screenHeight > 736 ? 50 : 30; //50 looks better on devices with the notch, ugly hack until a proper safe area plugin is used
  const closeButtonTop = Platform.OS === 'ios' && isSM && !isBelowSafeArea ? iPhoneTop : 20;
  const closeButton = (
    <CornerButton
      iconColor={closeColor}
      action={close}
      style={[styles.mpClose, { top: closeButtonTop }]}
    />
  );

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.body.className = 'noScroll';
    }
    return () => {
      if (Platform.OS === 'web') {
        document.body.className = '';
      }
    };
  }, []);

  return (
    <Modal
      animationType={isSM ? 'slide' : 'fade'}
      onRequestClose={close}
      transparent
      style={styles.mpModalRoot}
      presentationStyle="overFullScreen"
      visible>
      <View style={styles.mpView}>
        <View style={styles.mpBGView} />
        <View
          style={useStylesForWidth(
            styles,
            'mpContainerView',
            maxContainerHeightStyle,
            containerWidthStyle,
            propStyle
          )}>
          {props.children}
          {close ? closeButton : null}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  mpModalRoot: {
    zIndex: 1000,
  },
  mpView: {
    ...(Platform.OS === 'web'
      ? {
          position: 'fixed',
          right: 0,
          bottom: 0,
        }
      : {
          position: 'absolute',
          width: '100%',
          height: '100%',
        }),
    top: 0,
    left: 0,
    zIndex: 1000,
    elevation: 1000,
  },
  mpBGView: {
    height: '100%',
    width: '100%',
    opacity: 0.5,
    backgroundColor: '#000000',
  },
  mpContainerView: {
    position: 'absolute',
    overflow: 'hidden',
    backgroundColor: AppColors.white,
  },
  mpContainerViewSM: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
  },
  mpContainerViewMDLGXL: {
    top: '10%',
    left: '50%',
    borderRadius: AppStyles.largeBorderRadius,
    minHeight: 400,
  },
  mpClose: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 1000,
    elevation: 1000,
  },
});

export { ModalPopup };
