import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import { ActionButton, ActionButtonType } from '../components/actionButton';
import { AnonymousLanguageToggle } from '../components/anonymousLanguageToggle';
import { Heading } from '../components/heading';
import { OrLine } from '../components/orLine';
import { Paragraph } from '../components/paragraph';
import { TextField } from '../components/textField';
import { WarningText } from '../components/warningText';
import { useLocale } from '../hooks/useLocale';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { ApiService, useApiRequest } from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AnalyticsScreenNames } from '../util/enums';

const ForgotPasswordScreen = function (props) {
  const locale = useLocale();
  useScreenTitle(staticTranslation(locale, 'Password Help'));
  useScreenView(AnalyticsScreenNames.forgotPassword, 'ForgotPasswordScreen');
  const [email, setEmail] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const sendReset = useApiRequest(
    cb => {
      return ApiService.sendResetLinkEmail(email, locale, cb);
    },
    [email, locale]
  );

  useEffect(() => {
    setErrMessage('');
  }, [locale]);

  const attemptToSendLinkEmail = () => {
    if (!email) {
      setErrMessage(staticTranslation(locale, 'Oops, you need to enter an email!'));
    } else {
      setErrMessage('');
      sendReset.send();
    }
  };

  const form = () => {
    return (
      <View>
        <Paragraph style={styles.fpSubtitle}>
          {staticTranslation(
            locale,
            "If you can't remember your password, we'll send you an email with instructions on how to reset it."
          )}
        </Paragraph>
        <TextField
          autoFocusHardwareKeyboards
          inputProps={{
            disabled: sendReset.isBusy,
            maxLength: 100,
            blurOnSubmit: true,
            onSubmitEditing: () => attemptToSendLinkEmail(),
            keyboardType: 'email-address',
            placeholder: staticTranslation(locale, 'Your Account Email'),
            autoCapitalize: 'none',
            enablesReturnKeyAutomatically: true,
            value: email,
            onChangeText: setEmail,
            autoComplete: 'email',
            autoCompleteType: 'email',
            textContentType: 'emailAddress',
            returnKeyType: 'go',
          }}
        />
        <WarningText>
          {errMessage ||
            (sendReset.error
              ? `${staticTranslation(locale, 'There was a problem sending this request')} (${
                  sendReset.error
                })`
              : null)}
        </WarningText>
        <ActionButton
          title={staticTranslation(locale, 'Submit')}
          showActivityIndicator={sendReset.isBusy}
          action={() => attemptToSendLinkEmail()}
          disabled={sendReset.isBusy}
        />
        <OrLine />
      </View>
    );
  };

  const result = () => {
    return (
      <Paragraph style={styles.fpResultText}>
        {staticTranslation(
          locale,
          'We have sent an email to the address you provided with instructions for resetting your password.'
        )}
      </Paragraph>
    );
  };

  useEffect(() => {
    props.setHideLogo(false);
    props.setWelcomeMessageForSM(null);
    props.setAvoidKeyboard(true);
  }, [props]);

  return (
    <>
      <Heading>{staticTranslation(locale, 'Password Help')}</Heading>
      {sendReset.succeeded ? result() : form()}
      <ActionButton
        title={staticTranslation(locale, 'Return to Sign In')}
        disabled={sendReset.isBusy}
        type={ActionButtonType.SECONDARY}
        action={() => props.navigateTo('/login')}
      />
      <AnonymousLanguageToggle />
    </>
  );
};

const styles = StyleSheet.create({
  fpSubtitle: {
    marginBottom: 30,
  },
  fpResultText: {
    marginBottom: 30,
  },
});

export { ForgotPasswordScreen };
