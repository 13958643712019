import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Paragraph, ParagraphSizes } from './paragraph';
import { useStaticTranslation } from '../services/staticTranslations';
import { AppColors } from '../services/styles';
import { INSTITUTION_CODE_HELP_URL } from '../util/links';
import { openUrlAction, pressPropsForTouchable } from '../util/utils';

const InstitutionCodeHelp = function () {
  return (
    <TouchableOpacity
      {...pressPropsForTouchable(openUrlAction(INSTITUTION_CODE_HELP_URL))}
      style={styles.ichHelpLink}>
      <Paragraph size={ParagraphSizes.SMALL} bold style={styles.ichHelpLinkText}>
        {useStaticTranslation('What is this?')}
      </Paragraph>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  ichHelpLink: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 20,
    alignSelf: 'flex-end',
  },
  ichHelpLinkText: {
    color: AppColors.brandedBlue,
  },
});

export { InstitutionCodeHelp };
