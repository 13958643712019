import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Paragraph, ParagraphSizes } from './paragraph';
import { useStaticTranslation } from '../services/staticTranslations';
import { AppColors } from '../services/styles';

const OrLine = function () {
  return (
    <View style={styles.orLineView}>
      <View style={styles.orLine} />
      <Paragraph size={ParagraphSizes.SMALL} style={styles.orText}>
        {useStaticTranslation('or')}
      </Paragraph>
      <View style={styles.orLine} />
    </View>
  );
};

const styles = StyleSheet.create({
  orLineView: {
    marginTop: 20,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  orLine: {
    width: '40%',
    borderBottomColor: AppColors.greyText,
    borderBottomWidth: 1,
  },
  orText: {
    width: '20%',
    textAlign: 'center',
    color: AppColors.darkText,
  },
});

export { OrLine };
