import React, { useEffect, useState, useRef } from 'react';
import { Alert, Platform, StyleSheet, View } from 'react-native';

import { ContentPopup } from './contentPopup';
import { useSpaceForKeyboard } from '../../hooks/useSpaceForKeyboard';
import { ScreenSizes, useScreenSize } from '../../hooks/useStyleForWidth';
import { SubscribeBannerImage } from '../../img/subscribe-banner/SubscribeBannerImage';
import { ApiService, useApiRequest } from '../../services/apiService';
import { GlobalPubSub, PURCHASE_LISTENER_GC_EVENT } from '../../services/pubsub';
import { staticTranslation } from '../../services/staticTranslations';
import { AppColors } from '../../services/styles';
import { AnalyticsContentTypes, GiftCardStatus, InAppPurchases } from '../../util/enums';
import { analyticsSelectContent } from '../../util/firebaseHelper';
import { ActionButton } from '../actionButton';
import { ContentBox } from '../contentBox';
import { ContentBoxRow } from '../contentBoxRow';
import { Heading, HeadingType } from '../heading';
import { InAppPurchase } from '../inAppPurchase';
import { Paragraph, ParagraphSizes } from '../paragraph';
import { ScreenLoadingIndicator } from '../screenLoadingIndicator';
import { TextField } from '../textField';
import { WarningText } from '../warningText';

const SendGiftView = function (props) {
  const { giftCardId, onSent } = props;
  const [recipientEmail, setRecipientEmail] = useState('');
  const [from, setFrom] = useState('');
  const [message, setMessage] = useState('');
  const [warning, setWarning] = useState('');
  const emailRef = useRef(null);
  const fromRef = useRef(null);
  const messageRef = useRef(null);
  const sendCardRequest = useApiRequest(
    cb => {
      return ApiService.sendGiftCard(giftCardId, recipientEmail, from, message, cb);
    },
    [giftCardId, recipientEmail, from, message]
  );
  const sent = sendCardRequest.succeeded;
  const sendGift = () => {
    if (recipientEmail) {
      setWarning('');
      sendCardRequest.send();
    } else {
      setWarning(staticTranslation('Oops, you need to enter the recipient email!'));
    }
  };
  useEffect(() => {
    if (sent) {
      onSent();
    }
  }, [sent, onSent]);

  return (
    <View>
      <Heading type={HeadingType.FOUR}>
        {staticTranslation(
          'Your gift card is almost ready to send! Fill in the details below, then tap on the blue button to finalize.'
        )}
      </Heading>
      <TextField
        autoFocusHardwareKeyboards={false}
        ref={emailRef}
        inputProps={{
          disabled: sendCardRequest.isBusy,
          maxLength: 100,
          blurOnSubmit: false,
          onSubmitEditing: () => fromRef.current.focus(),
          keyboardType: 'email-address',
          placeholder: staticTranslation('Recipient Email'),
          autoCapitalize: 'none',
          enablesReturnKeyAutomatically: true,
          value: recipientEmail,
          onChangeText: v => {
            setWarning('');
            setRecipientEmail(v);
          },
          autoComplete: 'email',
          autoCompleteType: 'email',
          textContentType: 'emailAddress',
          returnKeyType: 'next',
        }}
      />
      <TextField
        autoFocusHardwareKeyboards={false}
        ref={fromRef}
        inputProps={{
          disabled: sendCardRequest.isBusy,
          maxLength: 50,
          blurOnSubmit: false,
          onSubmitEditing: () => messageRef.current.focus(),
          returnKeyType: 'next',
          placeholder: staticTranslation('From (optional)'),
          value: from,
          onChangeText: setFrom,
          autoComplete: 'name',
          autoCompleteType: 'name',
          textContentType: 'name',
          enablesReturnKeyAutomatically: true,
        }}
      />
      <TextField
        ref={messageRef}
        style={styles.giftPopupMessageField}
        autoFocusHardwareKeyboards={false}
        inputProps={{
          autoComplete: 'off',
          blurOnSubmit: false,
          placeholder: staticTranslation('Message (optional)'),
          autoCompleteType: 'off',
          textContentType: 'none',
          disabled: sendCardRequest.isBusy,
          maxLength: 140,
          multiline: true,
          textAlignVertical: 'top',
          onChangeText: setMessage,
          value: message,
        }}
      />
      <WarningText>
        {warning ||
          (sendCardRequest.error
            ? `${staticTranslation('There was a problem sending this gift:')} ${
                sendCardRequest.error
              }`
            : null)}
      </WarningText>
      <ActionButton
        title={staticTranslation('Send Gift')}
        action={() => sendGift()}
        showActivityIndicator={sendCardRequest.isBusy}
        disabled={sendCardRequest.isBusy}
      />
    </View>
  );
};

const ListGiftView = function (props) {
  const { giftCards, onCancel } = props;
  if (!(giftCards || []).length) {
    return null;
  }
  const items = giftCards.filter(gc => gc.status !== GiftCardStatus.UNSENT);
  return (
    <View style={styles.giftPopupPreviousPurchases}>
      <ContentBox title={staticTranslation('Gift Card History')} style={styles.giftPopupContentBox}>
        {items.length ? (
          items.map((gc, index) => {
            const description =
              gc.status === GiftCardStatus.SENT
                ? staticTranslation('Sent to :email', {
                    email: gc.recipient_email,
                  })
                : staticTranslation('Redeemed by :email', {
                    email: gc.recipient_email,
                  });
            return (
              <ContentBoxRow
                left={
                  <Paragraph
                    style={{
                      maxWidth: gc.status === GiftCardStatus.SENT ? '70%' : '100%',
                    }}>
                    {description}
                  </Paragraph>
                }
                action={gc.status !== GiftCardStatus.SENT ? null : () => onCancel(gc.id)}
                key={index}
                right={
                  <Paragraph size={ParagraphSizes.SMALL} style={{ color: AppColors.brandedBlue }}>
                    {gc.status === GiftCardStatus.SENT ? staticTranslation('Rescind...') : ''}
                  </Paragraph>
                }
              />
            );
          })
        ) : (
          <ContentBoxRow
            left={<Paragraph>{staticTranslation('No purchases found')}</Paragraph>}
            key={-1}
          />
        )}
      </ContentBox>
    </View>
  );
};

const GiftPopup = function (props) {
  const isSM = useScreenSize() === ScreenSizes.SM;
  const [busyPurchasing, setBusyPurchasing] = useState(false);
  const [giftCardToCancel, setGiftCardToCancel] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const getGiftCardsRequest = useApiRequest(
    cb => {
      //immediately load the user's gift purchases
      return ApiService.getGiftCards(cb);
    },
    [],
    true
  );
  const cancelGiftCardRequest = useApiRequest(
    cb => {
      return ApiService.unsendGiftCard(giftCardToCancel, cb);
    },
    [giftCardToCancel]
  );
  const cancelledGiftCard = cancelGiftCardRequest.succeeded;
  const close = busyPurchasing ? () => {} : props.close;
  const [newlyPurchasedGiftCard, setNewlyPurchasedGiftCard] = useState(null);
  const giftCards = getGiftCardsRequest.succeeded
    ? getGiftCardsRequest.data.purchased_gift_cards
    : null;
  const unsentGiftCards = !giftCards
    ? null
    : giftCards.filter(gc => gc.status === GiftCardStatus.UNSENT);
  const firstUnsentGiftCard = unsentGiftCards && unsentGiftCards.length ? unsentGiftCards[0] : null;
  const spaceForKeyboard = useSpaceForKeyboard(
    Platform.OS === 'web' || (Platform.OS === 'android' && isSM)
  );

  const onGiftSent = () => {
    setShowSpinner(true);
    getGiftCardsRequest.send();
    setNewlyPurchasedGiftCard(null);
    const alertMessage = staticTranslation(
      'Your gift was sent! You can buy another gift and see your purchase history at any time by returning to this screen.'
    );
    if (Platform.OS === 'web') {
      window.alert(alertMessage);
    } else {
      Alert.alert(staticTranslation('Sent'), alertMessage);
    }
  };

  const onCancelGift = giftCardId => {
    if (!busyPurchasing) {
      setGiftCardToCancel(giftCardId);
      const gc = giftCards.filter(gc => gc.id === giftCardId);
      const alertTitle = staticTranslation('Confirm');
      const alertMessage = staticTranslation(
        'Are you sure you want to rescind the gift card you sent to :email? If you proceed with this action, your friend will not be able to use the gift card, and you can send it to someone else.',
        { email: gc[0].recipient_email }
      );
      if (Platform.OS === 'web') {
        if (window.confirm(alertMessage)) {
          cancelGiftCardRequest.send();
        }
      } else {
        Alert.alert(alertTitle, alertMessage, [
          {
            text: staticTranslation('Yes'),
            onPress: () => {
              cancelGiftCardRequest.send();
              setShowSpinner(true);
            },
          },
          {
            text: staticTranslation('No'),
            onPress: () => setGiftCardToCancel(null),
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (cancelledGiftCard) {
      setGiftCardToCancel(null);
      cancelGiftCardRequest.reset();
      getGiftCardsRequest.send();
    }
  }, [cancelledGiftCard, cancelGiftCardRequest, getGiftCardsRequest]);

  useEffect(() => {
    if (!getGiftCardsRequest.isBusy || cancelGiftCardRequest.error) {
      setShowSpinner(false);
    }
  }, [getGiftCardsRequest.isBusy, cancelGiftCardRequest.error]);

  useEffect(() => {
    //after a gift card purchase, show the SendGiftView
    const listener = GlobalPubSub.on(PURCHASE_LISTENER_GC_EVENT, giftCard => {
      if (giftCard.status === GiftCardStatus.UNSENT) {
        setNewlyPurchasedGiftCard(giftCard);
      }
    });
    return () => GlobalPubSub.off(listener);
  }, []);

  useEffect(() => analyticsSelectContent(AnalyticsContentTypes.gift, 'gift'), []);

  return (
    <ContentPopup
      close={close}
      headerHeightOverride={spaceForKeyboard > 0 && !isSM ? 100 : null}
      imageClass={SubscribeBannerImage}
      subtitle={staticTranslation('Gift Cards')}
      title={staticTranslation('Unlock full access for a friend today!')}>
      <View style={{ marginBottom: spaceForKeyboard }}>
        {showSpinner ? (
          <ScreenLoadingIndicator center />
        ) : newlyPurchasedGiftCard || firstUnsentGiftCard ? (
          <SendGiftView
            onSent={onGiftSent}
            giftCardId={(newlyPurchasedGiftCard || firstUnsentGiftCard).id}
          />
        ) : (
          <>
            <Paragraph style={styles.giftPopupMessage}>
              {staticTranslation(
                'Purchase a digital gift card and email it to a friend so they can access all our videos, activities, resources, and self-assessments.'
              )}
            </Paragraph>
            <InAppPurchase
              disabled={false}
              type={InAppPurchases.GIFT}
              onPurchasing={purchasing => setBusyPurchasing(purchasing)}
            />
            <WarningText>{getGiftCardsRequest.error || cancelGiftCardRequest.error}</WarningText>
            <ListGiftView onCancel={onCancelGift} giftCards={giftCards} />
          </>
        )}
      </View>
    </ContentPopup>
  );
};

const styles = StyleSheet.create({
  giftPopupMessage: {
    marginBottom: 30,
  },
  giftPopupMessageField: {
    height: 120,
  },
  giftPopupPreviousPurchases: {
    marginTop: 20,
  },
  giftPopupContentBox: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export { GiftPopup };
