import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { Icon } from './icon';
import { Paragraph } from './paragraph';
import { AppColors, IconNames, IconSizes } from '../services/styles';
import { pressPropsForTouchable } from '../util/utils';

const Checkbox = function (props) {
  const { title, style, children, checked, onChange, whiteBackground } = props;

  return (
    <TouchableOpacity style={[style, styles.checkView]} {...pressPropsForTouchable(onChange)}>
      <>
        <View
          style={[
            styles.checkBox,
            whiteBackground ? styles.checkBoxWhite : {},
            checked ? styles.checkBoxChecked : {},
          ]}>
          {checked ? (
            <Icon name={IconNames.checkmark} size={IconSizes.small} color={AppColors.white} />
          ) : null}
        </View>
        <Paragraph style={styles.checkText}>{title || children}</Paragraph>
      </>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  checkView: {
    flexDirection: 'row',
  },
  checkBox: {
    height: 30,
    width: 30,
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: 15,
    justifyContent: 'center',
    backgroundColor: AppColors.mainBackground,
    alignItems: 'center',
    marginRight: 10,
  },
  checkBoxWhite: {
    backgroundColor: AppColors.white,
  },
  checkBoxChecked: {
    backgroundColor: AppColors.brandedBlue,
  },
  checkText: {
    flexGrow: 1,
    flexShrink: 1,
  },
});

export { Checkbox };
