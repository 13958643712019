import React from 'react';
import { StyleSheet } from 'react-native';

import { Paragraph, ParagraphSizes } from './paragraph';
import { AppColors } from '../services/styles';

const WarningText = function (props) {
  return props.children ? (
    <Paragraph size={ParagraphSizes.SMALL} style={[styles.warningText, props.style || {}]}>
      {props.children}
    </Paragraph>
  ) : null;
};

const styles = StyleSheet.create({
  warningText: {
    color: AppColors.errorText,
    paddingTop: 20,
    paddingBottom: 20,
  },
});

export { WarningText };
