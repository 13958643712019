import React from 'react';
import { StyleSheet } from 'react-native';

import { AlertPopup } from './alertPopup';
import {
  ApiService,
  useApiRequest,
  USER_FIELD_EMAIL,
  useUserInfo,
} from '../../services/apiService';
import { staticTranslation } from '../../services/staticTranslations';
import { ActionButton } from '../actionButton';
import { Paragraph } from '../paragraph';
import { WarningText } from '../warningText';

const VerifyEmailPopup = function (props) {
  const { close } = props;
  const userEmail = useUserInfo(USER_FIELD_EMAIL);
  const sendRequest = useApiRequest(cb => {
    return ApiService.resendVerificationEmail(cb);
  });

  return (
    <AlertPopup close={close} title={staticTranslation('Email Verification')} okButton={false}>
      {sendRequest.succeeded ? (
        <Paragraph style={styles.verifyParagraph}>
          {staticTranslation(
            'We have sent another email to :email with instructions for verifying your email.',
            { email: userEmail }
          )}
        </Paragraph>
      ) : (
        <Paragraph style={styles.verifyParagraph}>
          {staticTranslation(
            'An email was sent to :email with instructions for verification. Please verify your email to ensure that we can contact you when necessary.',
            { email: userEmail }
          )}
        </Paragraph>
      )}
      <Paragraph style={styles.verifyParagraph}>
        {staticTranslation("If you didn't receive an email, click here to resend:")}
      </Paragraph>
      <WarningText>
        {sendRequest.error
          ? `${staticTranslation('There was a problem sending this request')} (${
              sendRequest.error
            })`
          : null}
      </WarningText>
      <ActionButton
        disabled={sendRequest.isBusy}
        showActivityIndicator={sendRequest.isBusy}
        title={staticTranslation('Resend Verification Email')}
        action={() => sendRequest.send()}
      />
    </AlertPopup>
  );
};

const styles = StyleSheet.create({
  verifyParagraph: {
    marginBottom: 20,
  },
});

export { VerifyEmailPopup };
