import React, { useEffect, useRef } from 'react';
import { View, Modal, Platform, StyleSheet } from 'react-native';

import { useLocale } from '../../hooks/useLocale';
import { staticTranslation } from '../../services/staticTranslations';
import { AppColors, AppStyles } from '../../services/styles';
import { ActionButton } from '../actionButton';
import { CornerButton } from '../cornerButton';
import { Heading, HeadingType } from '../heading';
import { Paragraph } from '../paragraph';

const AlertPopup = function (props) {
  const { close, title, body } = props;
  const locale = useLocale();
  const okButton = typeof props.okButton === 'undefined' ? true : !!props.okButton;
  const closeButtonRef = useRef(null);

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.body.className = 'noScroll';
      if (closeButtonRef.current) {
        // noinspection JSUnresolvedFunction
        closeButtonRef.current.focus();
      }
    }
    return () => {
      if (Platform.OS === 'web') {
        document.body.className = '';
      }
    };
  }, []);

  return (
    <Modal
      animationType="fade"
      onRequestClose={close}
      transparent
      style={styles.apModalRoot}
      presentationStyle="overFullScreen"
      visible>
      <View style={styles.apView}>
        <View style={styles.apBGView} />
        <View style={styles.apContainerView}>
          <View style={[styles.apHeadingView, styles.apContent]}>
            <Heading type={HeadingType.THREE} style={styles.apHeading}>
              {title}
            </Heading>
            <CornerButton ref={closeButtonRef} action={close} />
          </View>
          <View style={[styles.apBody, styles.apContent]}>
            {body ? <Paragraph>{body}</Paragraph> : null}
            {props.children}
          </View>
          {okButton ? (
            <View style={styles.apContent}>
              <ActionButton action={close} title={staticTranslation(locale, 'Ok')} />
            </View>
          ) : null}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  apModalRoot: {
    zIndex: 1000,
  },
  apView: {
    ...(Platform.OS === 'web'
      ? {
          position: 'fixed',
        }
      : {
          position: 'absolute',
        }),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1000,
    elevation: 1000,
  },
  apBGView: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    opacity: 0.5,
    backgroundColor: '#000000',
  },
  apContainerView: {
    backgroundColor: AppColors.white,
    borderRadius: AppStyles.largeBorderRadius,
    top: '10%',
    maxWidth: 400,
    width: '90%',
    maxHeight: '80%',
  },
  apHeadingView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 3,
    borderBottomColor: AppColors.lightBackground,
  },
  apHeading: {
    marginBottom: 0,
  },
  apBody: {
    maxHeight: 360,
    overflow: 'hidden',
  },
  apContent: {
    padding: 20,
  },
});

export { AlertPopup };
