import React from 'react';
import { Image, StyleSheet, View, ScrollView, Platform } from 'react-native';

import { ModalPopup } from './modalPopup';
import {
  ScreenSizes,
  useScreenDimensions,
  useScreenSize,
  useStylesForWidth,
} from '../../hooks/useStyleForWidth';
import { AppColors, AppStyles } from '../../services/styles';
import { GradientMask } from '../gradientMask';
import { Heading, HeadingType } from '../heading';

const ContentPopup = function (props) {
  const { title, subtitle, close, headerHeightOverride } = props;
  const ImageClass = props.imageClass || Image;
  const propStyle = props.style || {};
  const propContentStyle = props.contentStyle || {};
  const screenDimensions = useScreenDimensions();
  const isSM = useScreenSize() === ScreenSizes.SM;
  const screenWidth = screenDimensions.width;
  const headingType = screenWidth < 350 ? HeadingType.TWO : HeadingType.ONE;
  const screenHeight = screenDimensions.height;
  const headingStyle = useStylesForWidth(
    styles,
    'apHeading',
    screenHeight > 610 || !isSM ? {} : { paddingTop: Platform.OS === 'ios' ? 50 : 40 }
  ); //reduce top padding on tiny phones, also make it a titch bigger on iPhone because of safe area issue

  return (
    <ModalPopup close={close}>
      <View
        style={useStylesForWidth(
          styles,
          'apHeader',
          headerHeightOverride ? { height: headerHeightOverride } : {}
        )}>
        <View style={styles.apImageContainer}>
          <ImageClass style={styles.apImage} />
          <GradientMask zIndex={1} />
        </View>
        <View style={headingStyle}>
          <Heading type={HeadingType.FOUR} style={styles.apSubtitle}>
            {subtitle}
          </Heading>
          <Heading type={headingType} style={styles.apTitle}>
            {title}
          </Heading>
        </View>
      </View>
      <ScrollView
        contentContainerStyle={useStylesForWidth(styles, 'apScrollerContent', propContentStyle)}
        style={useStylesForWidth(styles, 'apScroller', propStyle)}>
        {props.children}
      </ScrollView>
    </ModalPopup>
  );
};

const styles = StyleSheet.create({
  apHeader: {
    width: '100%',
    height: 260,
    flexShrink: 0,
    flexGrow: 0,
    flexDirection: 'column-reverse',
  },
  apHeaderSM: {
    height: 'auto',
    minHeight: '35%',
  },
  apImageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  apImage: {
    width: '100%',
    height: '100%',
  },
  apHeading: {
    paddingLeft: AppStyles.modalPaddingMDLGXL,
    paddingRight: AppStyles.modalPaddingMDLGXL,
    paddingTop: 30,
    zIndex: 2,
    paddingBottom: 30,
  },
  apHeadingSM: {
    paddingTop: 70,
    paddingBottom: 70,
    paddingLeft: AppStyles.modalPaddingSM,
    paddingRight: AppStyles.modalPaddingSM,
  },
  apTitle: {
    color: AppColors.white,
    marginBottom: 0,
  },
  apSubtitle: {
    marginBottom: 0,
    color: AppColors.white,
  },
  apScroller: {
    flexGrow: 1,
    zIndex: 2,
    flexShrink: 1,
  },
  apScrollerSM: {
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderTopRightRadius: AppStyles.largeBorderRadius,
    position: 'relative',
    marginTop: -60,
    backgroundColor: AppColors.white,
  },
  apScrollerContentMDLGXL: {
    padding: AppStyles.modalPaddingMDLGXL,
  },
  apScrollerContentSM: {
    padding: AppStyles.modalPaddingSM,
  },
});

export { ContentPopup };
