import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { Platform, Dimensions } from 'react-native';

import { mockService } from './mock-service';
import { PubSub } from './pubsub';
import { staticTranslation } from './staticTranslations';
import { setLocale } from '../hooks/useLocale';
import About from '../util/about';
import { AgeSurveyStatus, GiftCardStatus } from '../util/enums';
import { env } from '../util/env';
import { analyticsLogin, analyticsSignUp } from '../util/firebaseHelper';
import { TokenStorage } from '../util/tokenStorage';

axios.defaults.timeout = 30000;
axios.defaults.baseURL = env.apiBaseUrl + '/api';
axios.defaults.withCredentials = true; //combines with Access-Control-Allow-Credentials header on server to allow auth cookie to work
const initialWindow = Dimensions.get('window');
axios.defaults.headers.common['X-BR-Client-Info'] = JSON.stringify({
  os: Platform.OS,
  version: Platform.Version || 'na',
  appVersion: About[`${Platform.OS}Version`],
  appBuild: About[`${Platform.OS}Build`],
  initialWidth: initialWindow.width,
  initialHeight: initialWindow.height,
  initialScale: initialWindow.scale,
  initialFontScale: initialWindow.fontScale,
});

// Uncomment this to use a mock service and not hit the API
// mockService(axios);

function setAuthHeader(bearerToken) {
  if (Platform.OS !== 'web') {
    //on the web, we rely on the HTTPS-only secure cookie and don't store anything locally
    if (bearerToken) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + bearerToken;
    } else if (axios.defaults.headers.common.Authorization) {
      delete axios.defaults.headers.common.Authorization;
    }
  }
}

const apiError = function (error) {
  return {
    error: staticTranslation(error),
    success: false,
    data: {},
  };
};

const doRequest = (axiosConfig, cb, on = {}, useMemoryCache = false) => {
  console.log(axiosConfig.url, axiosConfig.method);
  const h = Object.assign(
    {
      success: () => {},
      error: () => {},
    },
    on
  );

  if (useMemoryCache) {
    const cachedData = ApiServiceCache.getItem(axiosConfig.url);
    if (cachedData !== undefined) {
      cb(cachedData);
      return () => {};
    }
  }

  const source = axios.CancelToken.source();

  axiosConfig.cancelToken = source.token;
  axiosConfig.validateStatus = status => {
    return (status >= 200 && status < 300) || status === 401;
  };

  axios(axiosConfig)
    .then(function (response) {
      const result = response.data || apiError('No response data');
      if (response.status === 401) {
        if (UserState[USER_FIELD_AUTH_STATUS] === AUTH_STATUS_AUTHENTICATED) {
          //TODO: trigger a popup message using pubsub to tell the user they were logged out
        }
        UserState.setUser(null, null);
        cb(apiError('You do not have access to this resource.'));
      } else if (result.success) {
        if (useMemoryCache) {
          ApiServiceCache.setItem(axiosConfig.url, result);
        }
        h.success(result.data);
        cb(result);
      } else {
        throw new Error(result.error || response.status);
      }
    })
    .catch(function (error) {
      console.log(
        'err',
        error.response ? error.response.config.url : '',
        error.config && error.config.data && JSON.parse(error.config.data)
      );
      const err = error.message || error.toString();
      h.error(err);
      cb(apiError(err));
    });
  return function () {
    source.cancel('Operation canceled');
  };
};

let ApiServiceCache = {
  cache: {},
  setItem(key, value) {
    this.cache[key] = value;
  },
  getItem(key) {
    return this.cache[key];
  },
};
/*
All API methods should callback with an object that looks like this:
{
    data: {} or null,
    success: boolean,
    error: string or null
}

All API methods return a function that cancels the request
 */
const ApiService = {
  /*
   * Login with a username and password
   *
   * Callback data:
   * {
   * user: {},
   * token: "token"
   * }
   */
  login(email, password, anonymous_locale, cb) {
    console.log('Logging in user with server');
    return doRequest(
      {
        method: 'post',
        url: '/auth/login',
        data: { email, password, anonymous_locale },
      },
      cb,
      {
        success: data => {
          analyticsLogin();
          UserState.setUser(data.user, data.token);
        },
      }
    );
  },
  /*
   * Logout the current user
   *
   * Callback data: null
   */
  logout(cb) {
    console.log('Logging out user with server');
    return doRequest(
      {
        url: '/auth/logout',
      },
      cb,
      {
        success: () => UserState.setUser(null, null),
      }
    );
  },
  /*
   * Get details for the current user
   *
   * Callback data:
   * {
   * user: {} //user object
   * }
   */
  getCurrentUser(backgroundUpdate, cb = () => {}) {
    console.log('Getting current user from server');
    if (!backgroundUpdate) {
      UserState.setField(USER_FIELD_AUTH_STATUS, AUTH_STATUS_LOADING);
    }
    return doRequest(
      {
        url: '/users/me',
      },
      cb,
      {
        success: data => UserState.setUser(data.user),
        error: e => {
          if (!backgroundUpdate) {
            console.error(`Error getting current user from ${env.apiBaseUrl}:`, e);
            UserState.setField(USER_FIELD_AUTH_STATUS, AUTH_STATUS_ERROR);
          }
        },
      }
    );
  },
  /*
   * Send a password reset email link request
   *
   * Callback data: null
   */
  sendResetLinkEmail(email, anonymous_locale, cb) {
    console.log('Sending password reset request');
    return doRequest(
      {
        method: 'post',
        url: '/password/email',
        data: { email, anonymous_locale },
      },
      cb
    );
  },
  /*
   * Register a new user
   *
   * Callback data:
   * {
   * user: {},
   * token: "token",
   * }
   */
  register(
    institution_code,
    name,
    email,
    password,
    password_confirmation,
    agreed_to_conditions,
    marketing_opt_in,
    preferred_language,
    cb
  ) {
    console.log('Sending registration request');
    return doRequest(
      {
        method: 'post',
        url: '/users/register',
        data: {
          institution_code,
          name,
          email,
          password,
          password_confirmation,
          agreed_to_conditions,
          marketing_opt_in,
          preferred_language,
        },
      },
      cb,
      {
        success: data => {
          analyticsSignUp();
          UserState.setField(USER_FIELD_AUTO_TUTORIAL, true);
          UserState.setUser(data.user, data.token);
        },
      }
    );
  },
  /*
   * Send a request to resend verification email
   *
   * Callback data:
   * {
   * already_verified: true or false,
   * }
   */
  resendVerificationEmail(cb) {
    console.log('Sending request to resend verification email');
    return doRequest(
      {
        url: '/email/resend',
      },
      cb,
      {
        success: data => {
          if (data.already_verified) {
            UserState.setField(USER_FIELD_EMAIL_IS_VERIFIED, true);
          }
        },
      }
    );
  },
  /*
   * Send a request to verify the user's email
   *
   * Callback data:
   * {
   * user: {}, //logged in user
   * token: '', //login token, if the user was just logged in
   * }
   */
  verifyEmail(url, cb) {
    console.log('Sending request to mark as verified');
    if (!url) {
      cb(apiError('Verification URL is invalid'));
      return () => {};
    }
    return doRequest(
      {
        url,
      },
      cb,
      {
        success: data => {
          if (data.token) {
            UserState.setUser(data.user, data.token);
          } else {
            UserState.setField(USER_FIELD_EMAIL_IS_VERIFIED, true);
          }
        },
      }
    );
  },
  /*
   * Do a password reset
   *
   * Callback data:
   * {
   * user: {}, //logged in user
   * token: '', //login token
   * }
   */
  resetPassword(email, password, password_confirmation, token, anonymous_locale, cb) {
    console.log('Sending password reset request');
    return doRequest(
      {
        method: 'post',
        url: '/password/reset',
        data: {
          email,
          password,
          password_confirmation,
          token,
          anonymous_locale,
        },
      },
      cb,
      {
        success: data => UserState.setUser(data.user, data.token),
      }
    );
  },
  /*
   * Update user info
   *
   * Callback data:
   * {
   * user: {},
   * }
   */
  updateCurrentUser(email, name, marketing_opt_in, agreed_to_conditions, preferred_language, cb) {
    console.log('Sending request to update user');

    const data = {
      name,
      email,
      marketing_opt_in,
    };

    if (agreed_to_conditions !== null) {
      data.agreed_to_conditions = agreed_to_conditions;
    }

    if (preferred_language !== null) {
      data.preferred_language = preferred_language;
    }

    return doRequest(
      {
        method: 'put',
        url: '/users/' + UserState[USER_FIELD_ID],
        data,
      },
      cb,
      {
        success: data => {
          if (agreed_to_conditions !== null) {
            //if agreed_to_conditions is specified, the user is from WTC and just finished their registration/signup
            UserState.setField(USER_FIELD_AUTO_TUTORIAL, true);
          }
          UserState.setUser(data.user);
        },
      }
    );
  },
  /*
   * Request account deletion
   *
   * Callback data:
   * null
   */
  deleteCurrentUser(cb) {
    console.log(`Sending request to delete user`);
    return doRequest(
      {
        method: 'delete',
        url: '/users/' + UserState[USER_FIELD_ID],
      },
      cb,
      {
        success: () => UserState.setUser(null, null),
      }
    );
  },
  /*
   * Add institution code
   *
   * Callback data:
   * {
   * user: {},
   * }
   */
  setInstitutionCode(institution_code, cb) {
    console.log('Sending request to set institution code');

    return doRequest(
      {
        method: 'put',
        url: '/users/' + UserState[USER_FIELD_ID],
        data: {
          name: UserState[USER_FIELD_NAME],
          email: UserState[USER_FIELD_EMAIL],
          marketing_opt_in: UserState[USER_FIELD_MARKETING],
          institution_code,
        },
      },
      cb,
      {
        success: data => UserState.setUser(data.user),
      }
    );
  },
  /*
   * Get the main list of topics
   *
   * Callback data:
   * {
   * modules: [] //array of modules
   * }
   */
  getModules(cb = () => {}) {
    console.log('Getting modules from server');
    return doRequest(
      {
        url: '/modules',
      },
      cb
    );
  },
  /*
   * Get information for a topic
   *
   * Callback data:
   * {
   * module: {} //module data
   * }
   */
  getModule(id, fullDetail = false, cb = () => {}) {
    console.log('Getting module ' + id + ' from server');
    return doRequest(
      {
        url: '/modules/' + id + '?fullDetail=' + (fullDetail ? '1' : '0'),
      },
      cb
    );
  },
  /*
   * Get information for a resource
   *
   * Callback data:
   * {
   * resource: {} //resource data
   * }
   */
  getResource(id, cb = () => {}) {
    console.log('Getting resource ' + id + ' from server');
    return doRequest(
      {
        url: '/extra_contents/' + id,
      },
      cb,
      null,
      true
    );
  },
  /*
   * Get resources listed by category
   *
   * Callback data:
   * {
   * resources: {}
   * }
   */
  getResourcesByCategory(cb = () => {}) {
    console.log('Getting resources from server');
    return doRequest(
      {
        url: '/extra_contents?groupby=category',
      },
      cb,
      null,
      true
    );
  },
  /*
   * Get featured resources
   *
   * Callback data:
   * {
   * resources: {}
   * }
   */
  getFeaturedResources(cb = () => {}) {
    console.log('Getting featured resources from server');
    return doRequest(
      {
        url: '/extra_contents?groupby=featured',
      },
      cb,
      null,
      true
    );
  },
  /*
   * Update step progress
   *
   * Callback data:
   * {
   * progress: {},
   * }
   */
  updateStepProgress(stepId, progress, time, cb) {
    console.log(`Sending request to update step progress to ${progress}`);
    return doRequest(
      {
        method: 'put',
        url: '/steps/' + stepId + '/progress',
        data: {
          progress,
          time,
        },
      },
      cb
    );
  },
  /*
   * Update activity progress
   *
   * Callback data:
   * {
   * progress: {},
   * }
   */
  updateActivityProgress(activityId, progress, cb) {
    console.log(`Sending request to update activity progress to ${progress}`);
    return doRequest(
      {
        method: 'put',
        url: '/exercises/' + activityId + '/progress',
        data: {
          progress,
        },
      },
      cb
    );
  },
  /*
   * Reset progress (for admins)
   *
   * Callback data:
   * null
   */
  resetProgress(cb) {
    console.log(`Sending request to reset progress`);
    return doRequest(
      {
        url: '/users/' + UserState[USER_FIELD_ID] + '/reset_progress',
      },
      cb
    );
  },
  /*
   * Submit an assessment
   *
   * Callback data:
   * {
   * assessment: {}
   * }
   */
  submitAssessment(
    module_id,
    quantitative_answer,
    qualitative_answer_one,
    qualitative_answer_two,
    cb
  ) {
    console.log('Sending assessment request');
    return doRequest(
      {
        method: 'post',
        url: '/user_assessments',
        data: {
          quantitative_answer,
          qualitative_answer_one,
          qualitative_answer_two,
          user_id: UserState[USER_FIELD_ID],
          module_id,
        },
      },
      cb
    );
  },
  /*
   * Verify an In App Purchase
   *
   * Callback data:
   * {
   * user: {}
   * }
   */
  verifyIAP(data, cb) {
    console.log('Sending IAP verification request');
    return doRequest(
      {
        method: 'post',
        url: '/in_app_purchases',
        data,
      },
      cb,
      {
        success: data => UserState.setUser(data.user),
      }
    );
  },
  /*
      API Health Check
       */
  healthCheck(cb) {
    console.log('Sending health check');
    return doRequest(
      {
        url: '/health',
      },
      cb
    );
  },
  /*
      Get an activity
       */
  getActivity(id, cb) {
    console.log(`Getting activity ${id} from server`);
    return doRequest(
      {
        url: '/exercises/' + id,
      },
      cb,
      {},
      true
    );
  },
  /*
      Get all activities
       */
  getActivities(featured, cb) {
    console.log(`Getting ${featured ? 'featured' : 'all'} activities from server`);
    return doRequest(
      {
        url: '/exercises' + (featured ? '?featured=true' : ''),
      },
      cb,
      {},
      true
    ); //TODO: note that we are caching user progress with this, but it is not used for anything
  },
  /*
   * Get translations for a language
   *
   * Callback data:
   * {
   * translations: [] //array of modules
   * }
   */
  getTranslationsForLanguage(language, cb = () => {}) {
    console.log(`Getting ${language} translations from server`);
    return doRequest(
      {
        url: '/translations?language=' + language,
      },
      cb
    );
  },
  /*
   * Get gift cards for a user (ones they have purchased, and ones
   *
   * Callback data:
   * {
   * purchased_gift_cards: [], //array of gift cards {id,status,recipient_email,display_from, message}
   * redeemable_gift_card: {id,status,recipient_email,display_from, message}
   * }
   */
  getGiftCards(cb) {
    console.log('Fetching purchased gift cards');
    return doRequest(
      {
        url: '/users/' + UserState[USER_FIELD_ID] + '/gift_cards',
      },
      cb
    );
  },
  /*
   * Update a gift card to send to a user or cancel
   *
   * Callback data:
   * {
   * gift_card: {id,status,recipient_email,display_from, message},
   * user: {}
   * }
   */
  sendGiftCard(id, recipient_email, display_from, message, cb) {
    return this.updateGiftCard(
      id,
      {
        status: GiftCardStatus.SENT,
        recipient_email,
        display_from,
        message,
      },
      cb
    );
  },
  unsendGiftCard(id, cb) {
    return this.updateGiftCard(
      id,
      {
        status: GiftCardStatus.UNSENT,
      },
      cb
    );
  },
  redeemGiftCard(id, cb) {
    return this.updateGiftCard(
      id,
      {
        status: GiftCardStatus.REDEEMED,
      },
      cb
    );
  },
  updateGiftCard(id, data, cb) {
    console.log('Updating gift card ' + id);
    return doRequest(
      {
        method: 'put',
        data,
        url: '/users/' + UserState[USER_FIELD_ID] + '/gift_cards/' + id,
      },
      cb,
      {
        success: data => UserState.setUser(data.user),
      }
    );
  },
  /*
   * Submit an age survey response
   *
   * Callback data:
   * {
   * age_survey: {},
   * }
   */
  submitAgeSurveyResponse(response, institution_code_id, cb) {
    console.log('posting age survey response');
    return doRequest(
      {
        method: 'post',
        url: '/age_surveys',
        data: {
          response,
          institution_code_id,
        },
      },
      cb
    );
  },
  /*
   * Update age survey status for a user
   *
   * Callback data:
   * {
   * user: {},
   * }
   */
  markAgeSurveyOffered(cb) {
    console.log('Updating the age survey status offered date');
    const now = new Date();
    const dd = ('0' + now.getDate()).substr(-2);
    const mm = ('0' + (now.getMonth() + 1)).substr(-2);
    const yyyy = '' + now.getFullYear();
    const age_survey_status = AgeSurveyStatus.OfferedMMDDYYY.replace('MM', mm)
      .replace('DD', dd)
      .replace('YYYY', yyyy);

    return doRequest(
      {
        method: 'put',
        url: '/users/' + UserState[USER_FIELD_ID],
        data: {
          name: UserState[USER_FIELD_NAME],
          email: UserState[USER_FIELD_EMAIL],
          marketing_opt_in: UserState[USER_FIELD_MARKETING],
          age_survey_status,
        },
      },
      cb,
      {
        success: data => UserState.setUser(data.user),
      }
    );
  },
};

const AUTH_STATUS_UNKNOWN = 'AUTH_STATUS_UNKNOWN';
const AUTH_STATUS_LOADING = 'AUTH_STATUS_LOADING';
const AUTH_STATUS_AUTHENTICATED = 'AUTH_STATUS_AUTHENTICATED';
const AUTH_STATUS_ANONYMOUS = 'AUTH_STATUS_ANONYMOUS';
const AUTH_STATUS_ERROR = 'AUTH_STATUS_ERROR';

const USER_FIELD_ID = 'userId';
const USER_FIELD_EMAIL = 'userEmail';
const USER_FIELD_EMAIL_IS_VERIFIED = 'userEmailIsVerified';
const USER_FIELD_IS_DISABLED = 'userIsDisabled';
const USER_FIELD_IS_ADMIN = 'userIsAdmin';
const USER_FIELD_NAME = 'userName';
const USER_FIELD_INSTITUTION_NAME = 'userInstitutionName';
const USER_FIELD_INSTITUTION_ID = 'userInstitutionId';
const USER_FIELD_IS_TRIAL = 'userIsTrial';
const USER_FIELD_AUTH_STATUS = 'userAuthStatus';
const USER_FIELD_AUTO_TUTORIAL = 'userAutoTutorial';
const USER_FIELD_MARKETING = 'userMarketingOptIn';
const USER_FIELD_TOC_AGREED = 'userAgreedToConditions';
const USER_FIELD_PREFERRED_LANGUAGE = 'userPreferredLanguage';
const USER_FIELD_AGE_SURVEY_STATUS = 'userAgeSurveyStatus';

let UserState = {
  userId: null,
  userEmail: null,
  userEmailIsVerified: false,
  userIsDisabled: false,
  userIsAdmin: false,
  userName: null,
  userInstitutionName: null,
  userInstitutionId: null,
  userIsTrial: false,
  userAuthStatus: AUTH_STATUS_UNKNOWN,
  userAutoTutorial: false,
  userMarketingOptIn: false,
  userAgreedToConditions: null,
  userPreferredLanguage: null,
  pubsub: new PubSub(),
  setField(field, value, returnFire) {
    const changed = this[field] !== value;
    this[field] = value;
    const fire = changed
      ? () => {
          this.pubsub.fire(field + 'Changed', value);
        }
      : () => {};
    if (returnFire) {
      return fire;
    } else {
      fire();
    }
  },
  setUser(user, token) {
    console.log('setUser', user, token);
    if (typeof token !== 'undefined') {
      TokenStorage.setToken(token);
      setAuthHeader(token);
    }
    const fireEvents = [];
    fireEvents.push(
      this.setField(
        USER_FIELD_AUTH_STATUS,
        user ? AUTH_STATUS_AUTHENTICATED : AUTH_STATUS_ANONYMOUS,
        true
      )
    );
    fireEvents.push(
      this.setField(USER_FIELD_EMAIL_IS_VERIFIED, user ? user.is_email_verified : null, true)
    );
    fireEvents.push(this.setField(USER_FIELD_IS_DISABLED, user ? user.is_disabled : null, true));
    fireEvents.push(this.setField(USER_FIELD_ID, user ? user.id : null, true));
    fireEvents.push(this.setField(USER_FIELD_EMAIL, user ? user.email : null, true));
    fireEvents.push(this.setField(USER_FIELD_IS_ADMIN, user ? user.is_admin : null, true));
    fireEvents.push(this.setField(USER_FIELD_NAME, user ? user.name : null, true));
    fireEvents.push(
      this.setField(USER_FIELD_INSTITUTION_NAME, user ? user.institution_name : null, true)
    );
    fireEvents.push(
      this.setField(USER_FIELD_INSTITUTION_ID, user ? user.institution_id : null, true)
    );
    fireEvents.push(this.setField(USER_FIELD_IS_TRIAL, user ? user.is_trial_mode : null, true));
    fireEvents.push(this.setField(USER_FIELD_MARKETING, user ? user.marketing_opt_in : null, true));
    fireEvents.push(
      this.setField(USER_FIELD_PREFERRED_LANGUAGE, user ? user.preferred_language : null, true)
    );
    fireEvents.push(
      this.setField(USER_FIELD_AGE_SURVEY_STATUS, user ? user.age_survey_status : null, true)
    );
    fireEvents.push(
      this.setField(USER_FIELD_TOC_AGREED, user ? user.agreed_to_conditions : null, true)
    );
    fireEvents.forEach(f => f());
    if (user && user.preferred_language) {
      setLocale(user.preferred_language, true);
    }
  },
};

function useUserInfo(field) {
  const [value, setValue] = useState(UserState[field]);
  useEffect(() => {
    const listener = UserState.pubsub.on(field + 'Changed', newValue => setValue(newValue));
    if (UserState[USER_FIELD_AUTH_STATUS] === AUTH_STATUS_UNKNOWN) {
      //first attempt to check the user's auth status

      //1. set status to loading to prevent duplicate attempts to load user info
      UserState.setField(USER_FIELD_AUTH_STATUS, AUTH_STATUS_LOADING);

      //2. Load token from storage (does nothing on web)
      TokenStorage.getToken().then(token => {
        //3. Set the token auth header for axios (does nothing on web)
        setAuthHeader(token);

        //4. Now get the user from the server to validate the user's current token
        ApiService.getCurrentUser(false);
      });
    }
    return () => {
      UserState.pubsub.off(listener);
    };
  }, [field]);

  return value;
}

function useApiRequest(apiCall, inputs = [], fireImmediately = false) {
  // eslint-disable-next-line
  const apiCallCB = useCallback(apiCall, inputs);
  const [isBusy, setIsBusy] = useState(fireImmediately);
  const [succeeded, setSucceeded] = useState(null);
  const [error, setError] = useState('');
  const [data, setData] = useState(null);

  useEffect(() => {
    let cancelRequest = function () {};
    let handler = function () {};

    if (isBusy) {
      handler = results => {
        setIsBusy(false);
        setData(results.data);
        setSucceeded(results.success);
        setError(results.error);
      };
      cancelRequest = apiCallCB(r => handler(r));
    }

    return () => {
      cancelRequest();
      // setIsBusy(false);
      handler = function () {};
    };
  }, [isBusy, apiCallCB]);

  return {
    send: () => {
      setSucceeded(null);
      setError('');
      setData(null);
      setIsBusy(true);
    },
    reset: () => {
      setSucceeded(null);
      setError('');
      setData(null);
      setIsBusy(false);
    },
    isBusy,
    succeeded,
    error,
    data,
  };
}

function urlForMedia(mediaId) {
  return axios.defaults.baseURL + '/content_media/' + mediaId;
}

function clearAutoTutorial() {
  UserState.setField(USER_FIELD_AUTO_TUTORIAL, false);
}

function markAgeSurveyComplete() {
  UserState.setField(USER_FIELD_AGE_SURVEY_STATUS, AgeSurveyStatus.Completed);
}

export {
  ApiService,
  useUserInfo,
  useApiRequest,
  urlForMedia,
  clearAutoTutorial,
  markAgeSurveyComplete,
  AUTH_STATUS_UNKNOWN,
  AUTH_STATUS_LOADING,
  AUTH_STATUS_ANONYMOUS,
  AUTH_STATUS_AUTHENTICATED,
  AUTH_STATUS_ERROR,
  USER_FIELD_ID,
  USER_FIELD_EMAIL,
  USER_FIELD_EMAIL_IS_VERIFIED,
  USER_FIELD_IS_DISABLED,
  USER_FIELD_IS_ADMIN,
  USER_FIELD_NAME,
  USER_FIELD_INSTITUTION_NAME,
  USER_FIELD_INSTITUTION_ID,
  USER_FIELD_IS_TRIAL,
  USER_FIELD_AUTH_STATUS,
  USER_FIELD_AUTO_TUTORIAL,
  USER_FIELD_MARKETING,
  USER_FIELD_PREFERRED_LANGUAGE,
  USER_FIELD_AGE_SURVEY_STATUS,
  USER_FIELD_TOC_AGREED,
};
