import React from 'react';

import { ActionButton } from './actionButton';
import { urlForMedia } from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';

const DownloadButton = function (props) {
  const propStyle = props.style || {};
  const fileId = props.fileId || null;
  const title = props.children || props.title || staticTranslation('Download Action');
  const url = urlForMedia(fileId);

  return (
    <ActionButton style={propStyle} action={() => window.open(url, '_blank')}>
      {title}
    </ActionButton>
  );
};

export { DownloadButton };
