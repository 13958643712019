import React from 'react';

import { ScreenLoadingIndicator } from './screenLoadingIndicator';
import { WarningText } from './warningText';

const ApiView = function (props) {
  const { succeeded, isBusy, hasError, errorMessage, centerLoading } = props;

  if (succeeded) {
    return props.children;
  }
  if (isBusy) {
    return <ScreenLoadingIndicator center={!!centerLoading} />;
  }
  if (hasError) {
    return <WarningText>{errorMessage}</WarningText>;
  }
  // noinspection JSConstructorReturnsPrimitive
  return null;
};

export { ApiView };
