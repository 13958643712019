import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { AppColors } from '../services/styles';
import { pressPropsForTouchable } from '../util/utils';

const RadioButton = function (props) {
  const { selected, children, action, style } = props;
  return (
    <TouchableOpacity
      {...pressPropsForTouchable(action)}
      style={[styles.radioTouchable, style || {}]}>
      <View style={[styles.radioCircle, selected ? styles.radioCircleSelected : {}]}>
        <View style={styles.radioInnerCircle} />
      </View>
      <View style={styles.radioContent}>{children}</View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  radioTouchable: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 40,
  },
  radioCircle: {
    borderRadius: 20,
    height: 20,
    width: 20,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: AppColors.greyText,
    justifyContent: 'center',
    alignItems: 'center',
  },
  radioInnerCircle: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: AppColors.white,
    borderRadius: 16,
    height: 16,
    width: 16,
  },
  radioCircleSelected: {
    borderColor: AppColors.brandedBlue,
    backgroundColor: AppColors.brandedBlue,
  },
  radioContent: {
    marginLeft: 10,
  },
});

export { RadioButton };
