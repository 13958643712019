//on the web, we rely on the HTTPS-only secure cookie and don't store anything locally
//if we wanted to store locally, we would use local storage as below
const TokenStorage = {
  setToken(value) {
    // window.localStorage.setItem('token', value);
  },
  getToken(key) {
    // return Promise.resolve(window.localStorage.getItem(key));
    return Promise.resolve('');
  },
};

export { TokenStorage };
