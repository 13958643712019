import React from 'react';
import { View, StyleSheet } from 'react-native';

import { ActionButton } from './actionButton';
import { HeadingType, WrappedHeading } from './heading';
import { AppColors, AppStyles } from '../services/styles';

const ContentBox = function (props) {
  const style = props.style || {};
  const title = props.title;
  const actionButton = props.actionButton;

  return (
    <View style={[styles.cbView, style]}>
      {title ? (
        <WrappedHeading type={HeadingType.THREE} style={styles.cbTitle}>
          {title}
        </WrappedHeading>
      ) : null}
      {props.children}
      {actionButton ? <ActionButton style={styles.cbButton} {...actionButton} /> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  cbView: {
    borderRadius: AppStyles.normalBorderRadius,
    backgroundColor: AppColors.white,
    padding: 20,
  },
  cbTitle: {
    marginBottom: 0,
    paddingBottom: 10,
    borderBottomWidth: 2,
    borderBottomColor: AppColors.lightBackground,
  },
  cbButton: {
    marginTop: 20,
  },
});

export { ContentBox };
