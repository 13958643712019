import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';

const GradientMask = function (props) {
  const zIndex = props.zIndex || 1;
  const propStyle = props.style || {};

  return <View style={[styles.gradient, { zIndex }, propStyle]} />;
};

const styles = StyleSheet.create({
  gradient: {
    position: 'absolute',
    height: '100%',
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.65,
    ...(Platform.OS === 'web'
      ? {
          backgroundImage: 'linear-gradient(180deg, #ffffff, #000000)',
          mixBlendMode: 'multiply',
        }
      : {}),
  },
});

export { GradientMask };
