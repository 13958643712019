import React from 'react';
import { StyleSheet } from 'react-native';

const QAAnswer2Image = function ({ style }) {
  const oneX = require('./AlittleBit.png');
  const twoX = require('./AlittleBit@2x.png');
  const threeX = require('./AlittleBit@3x.png');
  return (
    <img
      src={threeX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'contain' }}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
    />
  );
};

export { QAAnswer2Image };
