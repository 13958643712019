import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';

import {
  AuthenticatedScreen,
  MAX_AUTH_SCREEN_CONTENT_WIDTH,
  MIN_AUTH_SCREEN_CONTENT_WIDTH,
} from './authenticatedScreen';
import { ActionButton, ActionButtonType } from '../components/actionButton';
import { ApiView } from '../components/apiView';
import { ContentBox } from '../components/contentBox';
import { ContentBoxRow } from '../components/contentBoxRow';
import { Heading, HeadingType } from '../components/heading';
import { Paragraph } from '../components/paragraph';
import { SubscribePopup } from '../components/popups/SubscribePopup';
import { TopicHero } from '../components/topicHero';
import { useAuthContentStyles } from '../hooks/useAuthContentStyles';
import { TranslatableResourceTypes, useDynamicTranslation } from '../hooks/useDynamicTranslation';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { useStylesForWidth } from '../hooks/useStyleForWidth';
import {
  ApiService,
  useApiRequest,
  USER_FIELD_IS_TRIAL,
  useUserInfo,
} from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors, IconNames } from '../services/styles';
import { AnalyticsScreenNames, MainSections, PublishStatus } from '../util/enums';
import { percentCompletionForTopic } from '../util/utils';

const FractionalCompletion = function (props) {
  if (props.numerator === props.denominator) {
    return (
      <Paragraph bold style={styles.progCompletedText}>
        {staticTranslation('Done')}
      </Paragraph>
    );
  }
  return (
    <Paragraph bold>
      {props.numerator}/{props.denominator}
    </Paragraph>
  );
};

const Topic = function (props) {
  const { topic, locked } = props;
  const percent = percentCompletionForTopic(topic);
  const topicTitle = useDynamicTranslation(
    topic.id,
    TranslatableResourceTypes.TOPIC,
    'title',
    topic.title
  );
  return (
    <ContentBox style={useStylesForWidth(styles, 'progContentBox')}>
      <View style={useStylesForWidth(styles, 'progTopic')}>
        <View style={useStylesForWidth(styles, 'progHeroContainer')}>
          <TopicHero
            locked={locked}
            style={styles.progHero}
            title={topicTitle}
            percentComplete={percent}
            isDraft={topic.publish_status === PublishStatus.DRAFT}
            imageKey={topic.image_key}
          />
        </View>
        <View style={useStylesForWidth(styles, 'progInfoView')}>
          <ContentBoxRow
            left={
              <Heading style={styles.progHeadingFour} type={HeadingType.FOUR}>
                {staticTranslation('Videos')}
              </Heading>
            }
            right={
              <FractionalCompletion numerator={topic.done_steps} denominator={topic.total_steps} />
            }
          />

          <ContentBoxRow
            left={
              <Heading style={styles.progHeadingFour} type={HeadingType.FOUR}>
                {staticTranslation('Activities')}
              </Heading>
            }
            right={
              <FractionalCompletion
                numerator={topic.done_exercises}
                denominator={topic.total_exercises}
              />
            }
          />

          <ContentBoxRow
            left={
              <Heading style={styles.progHeadingFour} type={HeadingType.FOUR}>
                {staticTranslation('Assessment')}
              </Heading>
            }
            right={
              <FractionalCompletion
                numerator={topic.user_assessment_completed ? 1 : 0}
                denominator={1}
              />
            }
          />

          <ActionButton
            title={staticTranslation(
              percent === 100 ? 'Done' : percent === 0 ? 'Start Topic' : 'Continue Topic'
            )}
            type={percent === 100 ? ActionButtonType.ORANGE : ActionButtonType.SECONDARY}
            style={styles.progButton}
            rightIcon={percent === 100 ? IconNames.checkmark : IconNames.chevronRight}
            action={() => {
              if (locked) {
                props.navigateTo('/progress?subscribe=1');
              } else {
                props.navigateTo('/topic_overview/' + topic.id);
              }
            }}
          />
        </View>
      </View>
    </ContentBox>
  );
};

const ProgressScreen = function (props) {
  useScreenTitle(staticTranslation('Progress'));
  useScreenView(AnalyticsScreenNames.progress, 'ProgressScreen');
  const request = useApiRequest(
    cb => {
      return ApiService.getModules(cb);
    },
    [],
    true
  );
  const topics = (request.data || {}).modules || [];
  const listContainerStyle = useStylesForWidth(styles, 'progListContainer');
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);
  const showSubscriptionPopup = props.subscribe;
  const closePopup = () => props.navigateTo('/progress');

  return (
    <>
      <AuthenticatedScreen {...props} currentSection={MainSections.PROGRESS}>
        <View style={useAuthContentStyles()}>
          <Heading>{staticTranslation('Progress')}</Heading>
          <ApiView
            centerLoading
            succeeded={request.succeeded}
            hasError={request.error}
            errorMessage={`${staticTranslation(
              'There was a problem loading your progress information:'
            )} ${request.error}`}
            isBusy={request.isBusy}>
            <View style={listContainerStyle}>
              {topics.map(topic => (
                <Topic
                  {...props}
                  locked={isTrialUser && !topic.is_trial_content}
                  key={`topic${topic.id}`}
                  topic={topic}
                />
              ))}
            </View>
          </ApiView>
        </View>
      </AuthenticatedScreen>
      {showSubscriptionPopup ? <SubscribePopup close={closePopup} /> : null}
    </>
  );
};

const styles = StyleSheet.create({
  progListContainerMDLGXL: {
    maxWidth: MAX_AUTH_SCREEN_CONTENT_WIDTH,
    minWidth: MIN_AUTH_SCREEN_CONTENT_WIDTH,
  },
  progContentBox: {
    padding: 0,
    ...(Platform.OS === 'web'
      ? {
          transform: 'translateZ(0)', //fix bug in mobile safari caused by having an overflow:hidden element and/or border radius inside of overflow auto
        }
      : {}),
    overflow: 'hidden',
    marginBottom: 40,
  },
  progContentBoxMDLGXL: {
    height: 360,
  },
  progTopicMDLGXL: {
    height: '100%',
    flexDirection: 'row',
  },
  progHeroContainerSM: {
    width: '100%',
    position: 'relative',
    paddingTop: '66.66%',
  },
  progHeroContainerMDLGXL: {
    width: '50%',
    height: '100%',
    position: 'relative',
  },
  progHero: {
    borderRadius: 0,
  },
  progInfoViewSM: {
    padding: 20,
  },
  progInfoViewMDLGXL: {
    width: '50%',
    padding: 40,
  },
  progHeadingFour: {
    marginBottom: 0,
  },
  progCompletedText: {
    color: AppColors.brandedOrange,
    textTransform: 'uppercase',
  },
  progButton: {
    marginTop: 40,
  },
});

export { ProgressScreen };
