import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';

import { Heading } from './heading';
import { Icon } from './icon';
import { Paragraph, ParagraphSizes } from './paragraph';
import { ProgressBar, ProgressBarType } from './progressBar';
import { TranslatableResourceTypes, useDynamicTranslation } from '../hooks/useDynamicTranslation';
import { useStylesForWidth } from '../hooks/useStyleForWidth';
import { TopicDetailsBannerImage } from '../img/topic-details-bannner/TopicDetailsBannerImage';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors, AppStyles, IconNames, IconSizes } from '../services/styles';
import { percentCompletionForTopic, TIME_TO_COMPLETE_ASSESSMENT } from '../util/utils';

const TopicBanner = function (props) {
  const { topic } = props;
  const topicTitle = useDynamicTranslation(
    topic.id,
    TranslatableResourceTypes.TOPIC,
    'title',
    topic.title
  );
  return (
    <View style={[styles.bannerMenuHeaderView, styles.bannerRadius]}>
      <TopicDetailsBannerImage style={[styles.bannerMenuHeaderImage, styles.bannerRadius]} />
      <View style={useStylesForWidth(styles, 'bannerMenuHeaderContent')}>
        <View style={styles.bannerTotalTime}>
          <Icon color={AppColors.white} size={IconSizes.normal} name={IconNames.clock} />
          <Paragraph style={styles.bannerTotalTimeLabel} bold size={ParagraphSizes.SMALL}>
            {staticTranslation('Total Time')}
          </Paragraph>
          <Paragraph style={styles.bannerTotalTimeValue} bold size={ParagraphSizes.SMALL}>
            {staticTranslation(':number min', {
              number: topic.minutes_to_complete + TIME_TO_COMPLETE_ASSESSMENT,
            })}
          </Paragraph>
        </View>
        <Heading style={styles.bannerMenuHeaderHeading}>{topicTitle}</Heading>
        <ProgressBar
          type={ProgressBarType.white}
          style={styles.bannerProgressBar}
          percent={percentCompletionForTopic(topic, true)}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bannerMenuHeaderView: {
    height: 250,
  },
  bannerMenuHeaderContent: {
    justifyContent: 'flex-end',
    height: '100%',
    zIndex: 1,
  },
  bannerMenuHeaderContentMDLG: {
    padding: AppStyles.mainAuthScreenPaddingMDLG * 0.5,
  },
  bannerMenuHeaderContentXL: {
    padding: AppStyles.mainAuthScreenPaddingXL * 0.5,
  },
  bannerRadius: {
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
  },
  bannerMenuHeaderImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundColor: AppColors.brandedBlue,
    ...(Platform.OS === 'web'
      ? {
          transform: 'translateZ(0)', //fix bug in mobile safari caused by having an overflow:hidden element and/or border radius inside of overflow auto
        }
      : {}),
  },
  bannerMenuHeaderRoundedBorders: {
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
  },
  bannerTotalTime: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 3,
  },
  bannerTotalTimeLabel: {
    color: AppColors.white,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  bannerTotalTimeValue: {
    marginLeft: 10,
    color: AppColors.white,
    textTransform: 'uppercase',
  },
  bannerMenuHeaderHeading: {
    color: AppColors.white,
    zIndex: 3,
    marginTop: 10,
    marginBottom: 20,
  },
  bannerProgressBar: {
    zIndex: 3,
  },
});

export { TopicBanner };
