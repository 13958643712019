import React, { useRef, useEffect, useState } from 'react';
import {
  TouchableHighlight,
  TouchableNativeFeedback,
  View,
  Platform,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';

import { Icon } from './icon';
import { Paragraph } from './paragraph';
import { AppStyles, AppColors, IconSizes } from '../services/styles';
import { pressPropsForTouchable, openUrlAction } from '../util/utils';

const ActionButtonType = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  ORANGE: 'ORANGE',
  DANGER: 'DANGER',
};

const DISABLED_SECONDARY_TEXT_COLOR = '#d8d8d8';

const ActionButton = function (props) {
  const disabled = !!props.disabled;
  const showActivityIndicator = !!props.showActivityIndicator;
  const leftIcon = props.leftIcon;
  const rightIcon = props.rightIcon;
  const type = props.type || ActionButtonType.PRIMARY;
  const style = props.style || {};
  const openUrl = props.openUrl || null;
  const action = props.action || openUrlAction(openUrl);
  const text = props.children || props.title;
  const th = useRef(null);
  const [showingUnderlayOnMobile, setShowingUnderlayOnMobile] = useState(false);
  const textColor =
    type === ActionButtonType.PRIMARY
      ? AppColors.withOpacity(AppColors.white, disabled ? 0.5 : 1)
      : type === ActionButtonType.ORANGE
        ? AppColors.withOpacity(AppColors.brandedOrange, disabled ? 0.5 : 1)
        : type === ActionButtonType.DANGER
          ? AppColors.withOpacity(AppColors.white, disabled ? 0.8 : 1)
          : disabled
            ? DISABLED_SECONDARY_TEXT_COLOR
            : showingUnderlayOnMobile
              ? AppColors.white
              : AppColors.brandedBlue;

  useEffect(() => {
    if (Platform.OS === 'web') {
      // noinspection JSUnresolvedFunction
      th.current.setNativeProps({
        'data-component': 'ActionButton' + type,
      });
    }
  }, [type]);

  const renderBusy = () => {
    return <ActivityIndicator color={textColor} size="small" />;
  };

  const renderContent = () => {
    let leftIconComponent = null;
    if (leftIcon) {
      leftIconComponent = <Icon size={IconSizes.normal} name={leftIcon} color={textColor} />;
    }
    let rightIconComponent = null;
    if (rightIcon) {
      rightIconComponent = <Icon size={IconSizes.normal} name={rightIcon} color={textColor} />;
    }
    return (
      <>
        {leftIconComponent}
        <Paragraph bold style={[styles.abText, { color: textColor }]}>
          {text}
        </Paragraph>
        {rightIconComponent}
      </>
    );
  };

  // noinspection JSUnusedGlobalSymbols
  const underlayResponse =
    Platform.OS === 'ios'
      ? {
          onShowUnderlay: () => setShowingUnderlayOnMobile(true),
          onHideUnderlay: () => setShowingUnderlayOnMobile(false),
        }
      : {};

  const TouchableClass = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableHighlight;

  const nativeFeedbackProps =
    Platform.OS !== 'android'
      ? {}
      : {
          useForeground: false,
          background:
            Platform.Version < 21 //ripple is only available on 21 and above
              ? TouchableNativeFeedback.SelectableBackground()
              : TouchableNativeFeedback.Ripple(
                  type === ActionButtonType.SECONDARY ? AppColors.brandedBlue : AppColors.white,
                  false
                ),
        };

  //wrap the touchable in a view because TouchableNativeFeedback can't be styled
  return (
    <View style={[styles.abRoot, style]}>
      <TouchableClass
        {...underlayResponse}
        {...nativeFeedbackProps}
        ref={th}
        underlayColor={
          type === ActionButtonType.SECONDARY ? AppColors.brandedBlue : AppColors.white
        }
        disabled={disabled}
        style={styles.abRoot}
        {...pressPropsForTouchable(action, disabled)}>
        <View
          style={[
            styles.abView,
            styles['abView' + type],
            disabled ? styles['abView' + type + 'Disabled'] : {},
          ]}>
          {showActivityIndicator ? renderBusy() : renderContent()}
        </View>
      </TouchableClass>
    </View>
  );
};

const styles = StyleSheet.create({
  abRoot: {
    ...(Platform.OS === 'web' ? { outlineStyle: 'none' } : {}),
    borderRadius: AppStyles.normalBorderRadius,
  },
  abView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    minHeight: 50,
    borderRadius: AppStyles.normalBorderRadius,
    borderWidth: 2,
  },
  abViewPRIMARY: {
    backgroundColor: AppColors.brandedBlue,
    borderColor: AppColors.withOpacity(AppColors.white, 0),
  },
  abViewSECONDARY: {
    backgroundColor: AppColors.withOpacity(AppColors.white, 0),
    borderColor: AppColors.brandedBlue,
  },
  abViewSECONDARYDisabled: {
    borderColor: DISABLED_SECONDARY_TEXT_COLOR,
  },
  abViewORANGE: {
    borderColor: AppColors.withOpacity(AppColors.white, 0),
    backgroundColor: AppColors.withOpacity(AppColors.brandedOrange, 0.2),
  },
  abViewDANGER: {
    borderColor: AppColors.withOpacity(AppColors.dangerRed, 1),
    backgroundColor: AppColors.withOpacity(AppColors.dangerRed, 0.8),
  },
  abText: {
    textTransform: 'capitalize',
    textAlign: 'center',
    paddingRight: 10,
    paddingLeft: 10,
  },
});

export { ActionButton, ActionButtonType };
