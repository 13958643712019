import React, { useEffect } from 'react';
import { MajorError } from '../shared/components/majorError';
import { useScreenTitle } from '../shared/hooks/useScreenTitle';
import { useScreenView } from '../shared/hooks/useScreenView';
import { AnalyticsScreenNames } from '../shared/util/enums';
import { USER_FIELD_AUTH_STATUS } from '../shared/services/apiService';
import {
  AUTH_STATUS_AUTHENTICATED,
  useUserInfo,
} from '../shared/services/apiService';
import { AnonymousLanguageToggle } from '../shared/components/anonymousLanguageToggle';
import { useStaticTranslation } from '../shared/services/staticTranslations';

let NotFoundScreen = function (props) {
  useScreenTitle('404');
  useScreenView(AnalyticsScreenNames.notFound, 'NotFoundScreen');
  const isAnonymousUser =
    useUserInfo(USER_FIELD_AUTH_STATUS) !== AUTH_STATUS_AUTHENTICATED;
  useEffect(() => {
    props.setHideLogo(false);
    props.setWelcomeMessageForSM(null);
    props.setAvoidKeyboard(false);
  }, [props]);

  return (
    <>
      <MajorError
        message={useStaticTranslation(
          'Please check the URL and make sure it is valid, or return to the dashboard.'
        )}
        action={() => props.navigateTo('/')}
        title={useStaticTranslation('Uh oh! Page not found.')}
      />
      {isAnonymousUser ? <AnonymousLanguageToggle /> : null}
    </>
  );
};

export { NotFoundScreen };
