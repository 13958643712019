import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, ScrollView, Platform } from 'react-native';

import { ModalPopup } from './modalPopup';
import {
  TranslatableResourceTypes,
  useDynamicTranslation,
} from '../../hooks/useDynamicTranslation';
import { useSpaceForKeyboard } from '../../hooks/useSpaceForKeyboard';
import { ScreenSizes, useScreenSize, useStylesForWidth } from '../../hooks/useStyleForWidth';
import { QAAnswer1Image } from '../../img/qa-answer-icon/QAAnswer1Image';
import { QAAnswer2Image } from '../../img/qa-answer-icon/QAAnswer2Image';
import { QAAnswer3Image } from '../../img/qa-answer-icon/QAAnswer3Image';
import { QAAnswer4Image } from '../../img/qa-answer-icon/QAAnswer4Image';
import { QAAnswer5Image } from '../../img/qa-answer-icon/QAAnswer5Image';
import { ApiService, useApiRequest } from '../../services/apiService';
import { requestStoreReview } from '../../services/requestStoreReview';
import { staticTranslation } from '../../services/staticTranslations';
import { AppColors, AppStyles, IconNames, IconSizes } from '../../services/styles';
import { AnalyticsContentTypes, QuantitativeAssessmentAnswer } from '../../util/enums';
import { analyticsSelectContent } from '../../util/firebaseHelper';
import { noop } from '../../util/utils';
import { ActionButton, ActionButtonType } from '../actionButton';
import { Heading, HeadingType } from '../heading';
import { Icon } from '../icon';
import { Paragraph } from '../paragraph';
import { ProgressBar, ProgressBarType } from '../progressBar';
import { RadioButton } from '../radioButton';
import { TextField } from '../textField';
import { WarningText } from '../warningText';

const AssessmentPopup = function (props) {
  const close = props.close;
  const onCompleted = props.onCompleted || noop;
  const topic = props.topic;
  const topicId = topic.id;
  const [complete, setComplete] = useState(topic.user_assessment_completed);
  const [warning, setWarning] = useState('');
  const [progress, setProgress] = useState(complete ? 100 : 0);
  const [questionNumber, setQuestionNumber] = useState(complete ? 3 : 0);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [answerOne, setAnswerOne] = useState(0);
  const [answerTwo, setAnswerTwo] = useState('');
  const [answerThree, setAnswerThree] = useState('');

  useEffect(() => analyticsSelectContent(AnalyticsContentTypes.assessment, topicId), [topicId]);

  const isSM = useScreenSize() === ScreenSizes.SM;
  const spaceForKeyboard = useSpaceForKeyboard(
    Platform.OS === 'web' || (Platform.OS === 'android' && isSM)
  );
  const keyboardPaddingStyle = spaceForKeyboard ? { paddingBottom: spaceForKeyboard } : {};
  const paddingStyle = useStylesForWidth(styles, 'assPadding');
  const scrollRef = useRef(null);

  const apiRequest = useApiRequest(
    cb => {
      return ApiService.submitAssessment(topicId, answerOne, answerTwo, answerThree, cb);
    },
    [answerOne, answerThree, answerTwo, topicId]
  );

  const questionTitles = [
    useDynamicTranslation(
      topicId,
      TranslatableResourceTypes.TOPIC,
      'assessment_intro',
      topic.assessment_intro
    ),
    useDynamicTranslation(
      topicId,
      TranslatableResourceTypes.TOPIC,
      'quantitative_assessment_question',
      topic.quantitative_assessment_question
    ),
    useDynamicTranslation(
      topicId,
      TranslatableResourceTypes.TOPIC,
      'qualitative_assessment_question_one',
      topic.qualitative_assessment_question_one
    ),
    useDynamicTranslation(
      topicId,
      TranslatableResourceTypes.TOPIC,
      'qualitative_assessment_question_two',
      topic.qualitative_assessment_question_two
    ),
  ];

  const success = apiRequest.succeeded;
  useEffect(() => {
    if (success) {
      setComplete(true);
      requestStoreReview(); //does nothing on the web
      onCompleted();
    }
  }, [success, onCompleted]);

  useEffect(() => {
    let done = questionNumber;
    if (readyToSubmit) {
      done = 4;
    }
    if (complete) {
      done = 5;
    }
    setProgress(Math.round((done * 100) / 5));
  }, [questionNumber, readyToSubmit, complete]);

  const renderInput = () => {
    if (questionNumber === 1) {
      const images = [
        QAAnswer1Image,
        QAAnswer2Image,
        QAAnswer3Image,
        QAAnswer5Image,
        QAAnswer4Image,
      ]; //got 4 and 5 mixed up when I named these
      return (
        <View style={styles.assRadioButtons}>
          {QuantitativeAssessmentAnswer.toArray()
            .filter(option => {
              return option !== QuantitativeAssessmentAnswer.Skip;
            })
            .map((option, i) => {
              const ImageView = images[i];
              return (
                <RadioButton
                  selected={answerOne === option}
                  action={() => setAnswerOne(option)}
                  key={`answerOneOption${option}`}
                  style={styles.assRadioButton}>
                  <View style={styles.assRadioButtonContent}>
                    <Paragraph style={styles.assRadioButtonText}>
                      {staticTranslation(QuantitativeAssessmentAnswer.getDescription(option))}
                    </Paragraph>
                    <View style={styles.assRadioButtonImageContainer}>
                      <ImageView />
                    </View>
                  </View>
                </RadioButton>
              );
            })}
        </View>
      );
    } else {
      return (
        <TextField
          key={`question${questionNumber}`}
          style={styles.assTextArea}
          autoFocusHardwareKeyboards
          inputProps={{
            autoComplete: 'off',
            autoCompleteType: 'off',
            textContentType: 'none',
            disabled: apiRequest.isBusy,
            maxLength: 500,
            multiline: true,
            textAlignVertical: 'top',
            onChangeText: questionNumber === 2 ? setAnswerTwo : setAnswerThree,
            value: questionNumber === 2 ? answerTwo : answerThree,
          }}
        />
      );
    }
  };

  const renderForm = () => {
    return (
      <View style={[styles.assFormView, ...paddingStyle]}>
        <Heading type={HeadingType.TWO}>{questionTitles[questionNumber]}</Heading>
        {questionNumber === 0 ? null : renderInput()}
        <WarningText>
          {warning ||
            (apiRequest.error
              ? `${staticTranslation('There was a problem saving this assessment')} (${
                  apiRequest.error
                })`
              : null)}
        </WarningText>
      </View>
    );
  };

  const renderSummary = () => {
    const declined = staticTranslation(
      QuantitativeAssessmentAnswer.getDescription(QuantitativeAssessmentAnswer.Skip)
    );
    return (
      <View style={[styles.assFormView, ...paddingStyle]}>
        <Heading type={HeadingType.TWO}>{staticTranslation('Review and submit')}</Heading>
        <Heading type={HeadingType.FOUR}>{questionTitles[1]}</Heading>
        <Paragraph style={styles.assSummaryAnswer}>
          {answerOne
            ? staticTranslation(QuantitativeAssessmentAnswer.getDescription(answerOne))
            : declined}
        </Paragraph>
        <Heading type={HeadingType.FOUR}>{questionTitles[2]}</Heading>
        <Paragraph style={styles.assSummaryAnswer}>{answerTwo || declined}</Paragraph>
        <Heading type={HeadingType.FOUR}>{questionTitles[3]}</Heading>
        <Paragraph style={styles.assSummaryAnswer}>{answerThree || declined}</Paragraph>
      </View>
    );
  };

  const renderComplete = () => {
    return (
      <View style={[styles.assFormView, ...paddingStyle]}>
        <Heading type={HeadingType.TWO}>{staticTranslation('All Done!')}</Heading>
        <Paragraph>{staticTranslation('Thanks, your self-assessment is complete.')}</Paragraph>
        <Paragraph>{staticTranslation('Great job!')}</Paragraph>
      </View>
    );
  };

  const renderBeginButton = () => {
    return (
      <ActionButton
        action={() => setQuestionNumber(1)}
        style={styles.assBeginButton}
        title={staticTranslation('Begin Assessment')}
        rightIcon={IconNames.chevronRight}
      />
    );
  };

  const nextButtonTitle = () => {
    if (complete) {
      return 'Done';
    }
    if (readyToSubmit) {
      return 'Submit';
    }
    if (questionNumber === 1) {
      return !answerOne ? 'Skip' : 'Next';
    }
    if (questionNumber === 2) {
      return !answerTwo ? 'Skip' : 'Next';
    }
    if (questionNumber === 3) {
      return !answerThree ? 'Skip' : 'Next';
    }
  };

  const renderNavigationButtons = () => {
    return (
      <>
        <ActionButton
          type={ActionButtonType.SECONDARY}
          style={styles.assButton}
          leftIcon={IconNames.chevronLeft}
          action={() => {
            scrollRef.current.scrollTo({ x: 0, y: 0, animated: false });
            setWarning('');
            if (readyToSubmit) {
              setReadyToSubmit(false);
            } else {
              setQuestionNumber(questionNumber - 1);
            }
          }}
          disabled={apiRequest.isBusy || complete}>
          {staticTranslation('Prev')}
        </ActionButton>
        {complete || readyToSubmit ? null : <Paragraph>{questionNumber}/3</Paragraph>}
        <ActionButton
          action={() => {
            if (complete) {
              close();
            } else if (questionNumber < 3) {
              scrollRef.current.scrollTo({ x: 0, y: 0, animated: false });
              setWarning('');
              setQuestionNumber(questionNumber + 1);
            } else if (!readyToSubmit) {
              scrollRef.current.scrollTo({ x: 0, y: 0, animated: false });
              setReadyToSubmit(true);
            } else {
              if (!answerOne) {
                setAnswerOne(QuantitativeAssessmentAnswer.Skip);
              }
              if (!answerTwo) {
                setAnswerTwo('Skipped');
              }
              if (!answerThree) {
                setAnswerThree('Skipped');
              }
              apiRequest.send();
            }
          }}
          style={styles.assButton}
          disabled={!complete && apiRequest.isBusy}
          showActivityIndicator={apiRequest.isBusy}
          type={!readyToSubmit ? ActionButtonType.SECONDARY : ActionButtonType.PRIMARY}
          rightIcon={complete ? null : IconNames.chevronRight}
          title={staticTranslation(nextButtonTitle())}
        />
      </>
    );
  };

  return (
    <ModalPopup
      closeColor={AppColors.brandedBlue}
      isBelowSafeArea
      style={useStylesForWidth(styles, 'assModal')}
      close={!apiRequest.isBusy ? close : noop}>
      <View style={[styles.assHeadingView, ...paddingStyle]}>
        <Icon color={AppColors.greyText} size={IconSizes.normal} name={IconNames.assessment} />
        <Heading style={styles.assHeading} type={HeadingType.FOUR}>
          {staticTranslation('Self-Assessment')}
        </Heading>
      </View>
      <ProgressBar type={ProgressBarType.squared} percent={progress} />
      <ScrollView ref={scrollRef} style={styles.assBody}>
        {complete ? renderComplete() : readyToSubmit ? renderSummary() : renderForm()}
        <View style={[styles.assButtons, ...paddingStyle, keyboardPaddingStyle]}>
          {questionNumber === 0 ? renderBeginButton() : renderNavigationButtons()}
        </View>
      </ScrollView>
    </ModalPopup>
  );
};

const styles = StyleSheet.create({
  assModalSM: {
    top: '20%',
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderTopRightRadius: AppStyles.largeBorderRadius,
  },
  assHeadingView: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    paddingRight: 50,
    marginBottom: 20,
    marginTop: 20,
  },
  assHeading: {
    textTransform: 'uppercase',
    marginLeft: 10,
    marginBottom: 0,
    color: AppColors.greyText,
  },
  assBody: {
    flexGrow: 1,
    flexShrink: 1,
  },
  assFormView: {
    minHeight: 200,
    paddingBottom: 0,
    marginTop: 20,
    marginBottom: 20,
  },
  assTextArea: {
    height: 160,
  },
  assButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 0,
  },
  assButton: {
    minWidth: '35%',
    maxWidth: '45%',
  },
  assBeginButton: {
    width: '100%',
  },
  assPaddingSM: {
    margin: AppStyles.modalPaddingSM,
  },
  assPaddingMDLGXL: {
    margin: AppStyles.modalPaddingMDLGXL,
  },
  assRadioButtons: {
    alignItems: 'center',
  },
  assRadioButton: {
    marginBottom: 20,
  },
  assRadioButtonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200,
  },
  assRadioButtonText: {
    color: AppColors.darkText,
    marginRight: 10,
  },
  assRadioButtonImageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 66,
    height: 41,
  },
  assSummaryAnswer: {
    marginBottom: 22,
  },
});

export { AssessmentPopup };
