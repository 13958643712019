import React from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity, Platform } from 'react-native';

import { MainMenuButton } from '../components/mainMenuButton';
import { useLocale } from '../hooks/useLocale';
import { useStylesForWidth } from '../hooks/useStyleForWidth';
import { LogoImage } from '../img/logo/LogoImage';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors, AppStyles, IconNames } from '../services/styles';
import { MainSections } from '../util/enums';
import { envBackgroundColorStyle } from '../util/env';
import { getShadowStyles } from '../util/utils';

const AuthenticatedScreen = function (props) {
  const mainSection = props.currentSection || MainSections.DASHBOARD;
  const propStyle = props.style || {};
  const { hideMenu, menuReplacement } = props;
  const locale = useLocale();
  const mmButtonStyle = useStylesForWidth(styles, 'mmButton');
  const authNavStyle = useStylesForWidth(styles, 'authNav', envBackgroundColorStyle());
  const authLogoStyle = useStylesForWidth(styles, 'authLogo');
  const spacerStyle = useStylesForWidth(styles, 'mmSpacer');

  const renderMenu = () => {
    const menuContent = menuReplacement || (
      <>
        <TouchableOpacity style={authLogoStyle} onPress={() => props.navigateTo('/')}>
          <LogoImage style={styles.authLogoImage} />
        </TouchableOpacity>
        <MainMenuButton
          style={mmButtonStyle}
          title={staticTranslation(locale, 'Dashboard')}
          icon={IconNames.dashboard}
          active={mainSection === MainSections.DASHBOARD}
          action={() => props.navigateTo('/')}
        />
        <MainMenuButton
          style={mmButtonStyle}
          icon={IconNames.library}
          title={staticTranslation(locale, 'Library')}
          active={mainSection === MainSections.LIBRARY}
          action={() => props.navigateTo('/topics')}
        />
        <MainMenuButton
          style={mmButtonStyle}
          icon={IconNames.resources}
          title={staticTranslation(locale, 'Resources')}
          active={mainSection === MainSections.RESOURCES}
          action={() => props.navigateTo('/resources')}
        />
        <MainMenuButton
          style={mmButtonStyle}
          icon={IconNames.progress}
          title={staticTranslation(locale, 'Progress')}
          active={mainSection === MainSections.PROGRESS}
          action={() => props.navigateTo('/progress')}
        />
        <View style={spacerStyle} />
        <MainMenuButton
          style={mmButtonStyle}
          icon={IconNames.account}
          active={mainSection === MainSections.ACCOUNT}
          title={staticTranslation(locale, 'Account')}
          action={() => props.navigateTo('/users/me')}
        />
      </>
    );
    return <View style={authNavStyle}>{menuContent}</View>;
  };

  const contentViewStyle = useStylesForWidth(styles, 'authContent', propStyle);

  const ContentViewClass = Platform.OS === 'web' ? View : ScrollView;
  return (
    <View style={useStylesForWidth(styles, 'authView')}>
      {hideMenu ? null : renderMenu()}
      <ContentViewClass style={contentViewStyle}>{props.children}</ContentViewClass>
    </View>
  );
};

const styles = StyleSheet.create({
  authView: {},
  authViewSM: {
    minHeight: '100%',
  },
  authViewMDLGXL: {
    backgroundColor: AppColors.white,
    flex: 1,
    height: '100%',
    flexDirection: 'row',
  },
  authNav: {
    backgroundColor: AppColors.white,
    alignItems: 'center',
  },
  authNavSM: {
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderTopRightRadius: AppStyles.largeBorderRadius,
    ...getShadowStyles({ zIndex: 100 }),
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: 70,
  },
  authNavMDLGXL: {
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
  },
  authNavMD: {
    flexBasis: 80,
  },
  authNavLGXL: {
    flexBasis: 225,
  },
  authLogo: {
    marginTop: 40,
    height: 95,
    width: 70,
    marginBottom: 60,
  },
  authLogoSM: {
    display: 'none',
  },
  authLogoMD: {
    height: 68,
    width: 50,
  },
  authLogoImage: {
    width: '100%',
    height: '100%',
  },
  authContent: {
    backgroundColor: AppColors.mainBackground,
    flex: 1,
  },
  authContentSM: {
    width: '100%',
  },
  authContentMDLGXL: {
    ...(Platform.OS === 'web' ? { overflowY: 'auto' } : {}),
    height: '100%',
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
  },
  mmButtonMDLGXL: {
    flexGrow: 0,
    flexShrink: 0,
    marginBottom: 10,
  },
  mmButtonLGXL: {
    width: '72%',
    marginLeft: 10,
  },
  mmSpacerSM: {
    display: 'none',
  },
  mmSpacerMDLGXL: {
    flexGrow: 1,
    flexShrink: 1,
  },
});

const MAX_AUTH_SCREEN_CONTENT_WIDTH = 1400;
const MIN_AUTH_SCREEN_CONTENT_WIDTH = 600;

export { AuthenticatedScreen, MAX_AUTH_SCREEN_CONTENT_WIDTH, MIN_AUTH_SCREEN_CONTENT_WIDTH };
