import React from 'react';
import { TouchableHighlight, Image, StyleSheet } from 'react-native';

import { pressPropsForTouchable, openUrlAction } from '../util/utils';

const ActionImage = function (props) {
  const disabled = !!props.disabled;
  const style = props.style || {};
  const openUrl = props.openUrl || null;
  const action = props.action || openUrlAction(openUrl);
  const imageSource = props.imageSource;
  return (
    <TouchableHighlight
      disabled={disabled}
      {...pressPropsForTouchable(action, disabled)}
      style={[styles.aiView, style]}>
      <Image source={imageSource} style={styles.aiImage} />
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  aiView: {},
  aiImage: {
    width: '100%',
    height: '100%',
  },
});

export { ActionImage };
