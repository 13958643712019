import React, { useState, useRef, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import { ActionButton, ActionButtonType } from '../components/actionButton';
import { ActionLink } from '../components/actionLink';
import { AnonymousLanguageToggle } from '../components/anonymousLanguageToggle';
import { Heading, HeadingType } from '../components/heading';
import { OrLine } from '../components/orLine';
import { Paragraph } from '../components/paragraph';
import { TextField } from '../components/textField';
import { WarningText } from '../components/warningText';
import { useLocale } from '../hooks/useLocale';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { ScreenSizes, useScreenSize } from '../hooks/useStyleForWidth';
import { ApiService, useApiRequest } from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors, IconNames } from '../services/styles';
import { AnalyticsScreenNames } from '../util/enums';
import { env } from '../util/env';

const LoginScreen = function (props) {
  const locale = useLocale();
  useScreenTitle(staticTranslation(locale, 'Login'));
  useScreenView(AnalyticsScreenNames.login, 'LoginScreen');
  const passwordRef = useRef(null);
  const isSM = useScreenSize() === ScreenSizes.SM;
  const [showingSMWelcome, setShowingSMWelcome] = useState(isSM);
  const [username, setUsername] = useState(''); //the username being entered
  const [password, setPassword] = useState(''); //the password being entered
  const [warning, setWarning] = useState('');
  const message = staticTranslation(
    locale,
    'Where you can go to take a break from the pressures of life.'
  );
  const loginRequest = useApiRequest(
    cb => {
      return ApiService.login(username, password, locale, cb);
    },
    [username, password, locale]
  );
  const formIsFilled = !!username && !!password;

  const attemptLogin = () => {
    if (!formIsFilled) {
      setWarning(staticTranslation(locale, 'Oops, you need to enter your email and password!'));
    } else {
      setWarning('');
      loginRequest.send();
    }
  };

  useEffect(() => {
    setWarning('');
  }, [locale]);

  const renderWelcome = () => {
    return (
      <View>
        <ActionButton
          title={staticTranslation(locale, 'Login')}
          style={styles.lsLoginButton1}
          action={() => setShowingSMWelcome(false)}
        />
        <View style={styles.lsWelcomeStepRegLink}>
          <Paragraph>{staticTranslation(locale, "Don't have an account?")} </Paragraph>
          <ActionLink
            color={AppColors.brandedBlue}
            bold
            action={() => props.navigateTo('/register')}>
            {staticTranslation(locale, 'Create One.')}
          </ActionLink>
        </View>
      </View>
    );
  };

  const renderFields = () => {
    return (
      <View>
        <Heading type={isSM ? HeadingType.ONE : HeadingType.FOUR}>
          {staticTranslation(locale, 'Sign In')}
        </Heading>
        <TextField
          autoFocusHardwareKeyboards
          inputProps={{
            disabled: loginRequest.isBusy,
            maxLength: 100,
            keyboardType: 'email-address',
            placeholder: staticTranslation(locale, 'Email'),
            autoCapitalize: 'none',
            value: username,
            onChangeText: setUsername,
            autoComplete: 'email',
            autoCompleteType: 'email',
            textContentType: 'username',
            onSubmitEditing: () => {
              if (formIsFilled) {
                attemptLogin();
              } else if (username) {
                passwordRef.current.focus();
              }
            },
            blurOnSubmit: formIsFilled,
            returnKeyType: formIsFilled ? 'go' : 'next',
            enablesReturnKeyAutomatically: true,
          }}
        />
        <TextField
          icon={IconNames.lock}
          ref={passwordRef}
          inputProps={{
            maxLength: 100,
            onSubmitEditing: () => attemptLogin(),
            blurOnSubmit: true,
            returnKeyType: 'go',
            disabled: loginRequest.isBusy,
            autoComplete: 'current-password',
            autoCompleteType: 'password',
            textContentType: 'password',
            autoCapitalize: 'none',
            autoCorrect: false,
            placeholder: staticTranslation(locale, 'Password'),
            value: password,
            onChangeText: setPassword,
            enablesReturnKeyAutomatically: true,
            secureTextEntry: true,
          }}
        />
        <WarningText>
          {warning ||
            (loginRequest.error
              ? `${staticTranslation(locale, 'There was a problem attempting to sign in')} (${
                  loginRequest.error
                })`
              : null)}
        </WarningText>
        <View style={styles.lsRow}>
          <ActionLink
            style={styles.lsForgotButton}
            title={staticTranslation(locale, 'Forgot Password?')}
            action={() => props.navigateTo('/password/reset')}
            disabled={loginRequest.isBusy}
          />
          <ActionButton
            title={staticTranslation(locale, 'Next')}
            style={styles.lsLoginButton2}
            action={() => attemptLogin()}
            showActivityIndicator={loginRequest.isBusy}
            disabled={loginRequest.isBusy}
          />
        </View>
        <OrLine />
        <ActionButton
          title={staticTranslation(locale, 'Register for an account')}
          type={ActionButtonType.SECONDARY}
          action={() => props.navigateTo('/register')}
          disabled={loginRequest.isBusy}
        />
      </View>
    );
  };

  const welcomeView = isSM ? null : (
    <View>
      <Heading>{staticTranslation(locale, 'Welcome to BreathingRoom')}</Heading>
      <Paragraph style={styles.lsSubtitle}>{message}</Paragraph>
    </View>
  );

  useEffect(() => {
    props.setAvoidKeyboard(true);
    props.setHideLogo(showingSMWelcome);
    props.setWelcomeMessageForSM(showingSMWelcome ? message : null);
  }, [props, showingSMWelcome, message]);

  return (
    <>
      {welcomeView}
      {showingSMWelcome ? renderWelcome() : renderFields()}
      <AnonymousLanguageToggle />
    </>
  );
};

const styles = StyleSheet.create({
  lsWelcomeStepRegLink: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lsSubtitle: {
    marginBottom: 30,
  },
  lsRow: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  lsForgotButton: {
    maxWidth: '50%',
  },
  lsLoginButton1: {
    width: '100%',
    marginBottom: 10,
  },
  lsLoginButton2: {
    flexGrow: 1,
    marginLeft: 30,
  },
});

export { LoginScreen };
