import React from 'react';
import { StyleSheet } from 'react-native';

const ProgressIconImage = function ({ style }) {
  const oneX = require('./progress-icon.png');
  const twoX = require('./progress-icon@2x.png');
  const threeX = require('./progress-icon@3x.png');
  return (
    <img
      src={threeX}
      style={StyleSheet.flatten(style)}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
      alt=""
    />
  );
};

export { ProgressIconImage };
