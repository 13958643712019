import React from 'react';
import { Image, Platform, StyleSheet } from 'react-native';

import { useLocale } from '../../hooks/useLocale';
import { Languages } from '../../util/enums';

const OnboardingLargeImage = function ({ style, oneX, twoX, threeX }) {
  const locale = useLocale();
  const one = oneX ? oneX[locale] || oneX[Languages.english] : null;
  if (Platform.OS === 'web') {
    const two = twoX[locale] || twoX[Languages.english];
    const three = threeX[locale] || threeX[Languages.english];
    return (
      <img
        src={three}
        alt=""
        style={{
          ...StyleSheet.flatten(style),
          objectFit: 'cover',
          height: 446,
          width: 726,
        }}
        srcSet={`${one}, ${two} 2x, ${three} 3x`}
      />
    );
  } else {
    return <Image source={one} resizeMode="cover" resizeMethod="scale" style={style} />;
  }
};

export { OnboardingLargeImage };
