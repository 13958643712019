import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Paragraph } from './paragraph';
import { AppColors, AppStyles } from '../services/styles';

const HeadingType = {
  ONE: 'ONE',
  TWO: 'TWO',
  THREE: 'THREE',
  FOUR: 'FOUR',
  FIVE: 'FIVE',
};

const Heading = function (props) {
  const type = props.type || HeadingType.ONE;
  const propStyle = props.style || {};

  return (
    <Paragraph
      bold
      style={[styles.hoText, styles['hoMargin' + type], styles['hoText' + type], propStyle]}>
      {props.children}
    </Paragraph>
  );
};

//single border styles on Text components don't always work on RN, so this component allows single borders
const WrappedHeading = function (props) {
  const type = props.type || HeadingType.ONE;
  const { textStyle, style } = props;

  return (
    <View style={[styles['hoMargin' + type], style]}>
      <Paragraph bold style={[styles.hoText, styles['hoText' + type], textStyle]}>
        {props.children}
      </Paragraph>
    </View>
  );
};

const styles = StyleSheet.create({
  hoText: {
    letterSpacing: -0.25,
    color: AppColors.darkText,
  },
  hoTextONE: {
    fontSize: AppStyles.largestTextSize,
    lineHeight: AppStyles.largestTextHeight,
  },
  hoMarginONE: {
    marginBottom: 30,
  },
  hoTextTWO: {
    fontSize: AppStyles.largerTextSize,
    lineHeight: AppStyles.largerTextHeight,
  },
  hoMarginTWO: {
    marginBottom: 22,
  },
  hoTextTHREE: {
    fontSize: AppStyles.largeTextSize,
    lineHeight: AppStyles.largeTextHeight,
  },
  hoMarginTHREE: {
    marginBottom: 14,
  },
  hoTextFOUR: {
    fontSize: AppStyles.normalTextSize,
    lineHeight: AppStyles.normalTextHeight,
  },
  hoMarginFOUR: {
    marginBottom: 10,
  },
  hoTextFIVE: {
    fontSize: AppStyles.normalTextSize,
    lineHeight: AppStyles.normalTextHeight,
    textTransform: 'uppercase',
    color: AppColors.brandedBlue,
  },
  hoMarginFIVE: {
    marginBottom: 10,
  },
});

export { Heading, WrappedHeading, HeadingType };
