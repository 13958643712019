import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';

import { AuthenticatedScreen, MAX_AUTH_SCREEN_CONTENT_WIDTH } from './authenticatedScreen';
import { Heading } from '../components/heading';
import { SubscribePopup } from '../components/popups/SubscribePopup';
import { ScreenBannerImage } from '../components/screenBannerImage';
import { ScreenLoadingIndicator } from '../components/screenLoadingIndicator';
import { TopicHeroButton } from '../components/topicHeroButton';
import { WarningText } from '../components/warningText';
import { useAuthContentStyles } from '../hooks/useAuthContentStyles';
import { TranslatableResourceTypes, useDynamicTranslation } from '../hooks/useDynamicTranslation';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import {
  ScreenSizes,
  useScreenDimensions,
  useScreenSize,
  useStylesForWidth,
} from '../hooks/useStyleForWidth';
import { LogoSmallDashboardImage } from '../img/logo-small-dashboard/LogoSmallDashboardImage';
import {
  ApiService,
  useApiRequest,
  USER_FIELD_IS_TRIAL,
  useUserInfo,
} from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors } from '../services/styles';
import { AnalyticsScreenNames, MainSections, PublishStatus } from '../util/enums';
import { percentCompletionForTopic } from '../util/utils';

const RowItem = function (props) {
  const { style, locked, topic, navigateTo } = props;
  const topicTitle = useDynamicTranslation(
    topic.id,
    TranslatableResourceTypes.TOPIC,
    'title',
    topic.title
  );
  return (
    <View style={style}>
      <TopicHeroButton
        locked={locked}
        title={topicTitle}
        percentComplete={percentCompletionForTopic(topic)}
        isDraft={topic.publish_status === PublishStatus.DRAFT}
        action={() => {
          if (locked) {
            navigateTo('/topics?subscribe=1');
          } else {
            navigateTo('/topic_overview/' + topic.id);
          }
        }}
        imageKey={topic.image_key}
      />
    </View>
  );
};
const LibraryScreen = function (props) {
  useScreenTitle(staticTranslation('Library'));
  useScreenView(AnalyticsScreenNames.topics, 'LibraryScreen');
  const screenSize = useScreenSize();
  const { width } = useScreenDimensions();

  const columns = useMemo(() => {
    return width >= 1500 ? 3 : width > 760 ? 2 : 1;
  }, [width]);

  const request = useApiRequest(
    cb => {
      return ApiService.getModules(cb);
    },
    [],
    true
  );

  const topics = (request.data || {}).modules || [];

  const itemStyle = useStylesForWidth(styles, 'libHero');
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);

  const renderRowItems = startIndex => {
    const items = [];
    for (let i = startIndex, j = 0; j < columns; i++, j++) {
      if (i < topics.length) {
        const topic = topics[i];
        const locked = isTrialUser && !topic.is_trial_content;
        items.push(
          <RowItem
            key={j}
            navigateTo={props.navigateTo}
            topic={topic}
            locked={locked}
            style={itemStyle}
          />
        );
      } else {
        items.push(<View style={itemStyle} key={j} />);
      }
      if (j < columns - 1) {
        items.push(<View key={`${j}spacer`} style={styles.libHeroSpacer} />);
      }
    }
    return items;
  };

  const renderModules = () => {
    const rows = [];
    for (let i = 0; i < topics.length; i += columns) {
      rows.push(
        <View key={`row${i}`} style={styles.libTopicRow}>
          {renderRowItems(i)}
        </View>
      );
    }
    return rows;
  };

  const renderError = () => {
    return (
      <WarningText>
        {staticTranslation('There was a problem loading the library data.')} ({request.error})
      </WarningText>
    );
  };

  const showSubscriptionPopup = props.subscribe;

  return (
    <>
      <AuthenticatedScreen {...props} currentSection={MainSections.LIBRARY}>
        <ScreenBannerImage />
        <View style={useAuthContentStyles()}>
          {screenSize === ScreenSizes.SM ? <LogoSmallDashboardImage /> : null}
          <Heading style={useStylesForWidth(styles, 'libMainHeading')}>
            {staticTranslation('Library')}
          </Heading>
          <View style={useStylesForWidth(styles, 'libTopics')}>
            {request.isBusy ? (
              <ScreenLoadingIndicator />
            ) : request.succeeded ? (
              renderModules()
            ) : (
              renderError()
            )}
          </View>
        </View>
      </AuthenticatedScreen>
      {showSubscriptionPopup ? <SubscribePopup close={() => props.navigateTo('/topics')} /> : null}
    </>
  );
};

const styles = StyleSheet.create({
  libMainHeading: {
    color: AppColors.white,
    zIndex: 1,
  },
  libMainHeadingSM: {
    marginTop: 10,
  },
  libTopicsSM: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  libTopicsMDLGXL: {
    width: '100%',
    maxWidth: MAX_AUTH_SCREEN_CONTENT_WIDTH,
  },
  libTopicRow: {
    flexDirection: 'row',
    width: '100%',
    paddingBottom: 30,
  },
  libHeroSM: {
    width: '100%',
  },
  libHeroMDLGXL: {
    flexGrow: 1,
    flexShrink: 1,
  },
  libHeroSpacer: {
    width: 30,
    height: 30,
    flexGrow: 0,
    flexShrink: 0,
  },
});

export { LibraryScreen };
