import React from 'react';
import { View, StyleSheet } from 'react-native';

import { ActionButton } from './actionButton';
import { ContentBox } from './contentBox';
import { Heading } from './heading';
import { Paragraph, ParagraphSizes } from './paragraph';
import { useLocale } from '../hooks/useLocale';
import { staticTranslation } from '../services/staticTranslations';

const MajorError = function (props) {
  const locale = useLocale();
  const { title, message, error, action, actionTitle, boxed, style } = props;
  const ViewClass = boxed ? ContentBox : View;
  return (
    <ViewClass style={style || {}}>
      <Heading>{title || staticTranslation(locale, 'Uh oh! Something went wrong.')}</Heading>
      <Paragraph>{message}</Paragraph>
      <ActionButton style={styles.meTopMargin} action={action}>
        {actionTitle || staticTranslation(locale, 'Return to Dashboard')}
      </ActionButton>
      {error ? (
        <Paragraph size={ParagraphSizes.TINY} style={styles.meTopMargin}>
          {staticTranslation(locale, 'Diagnostic info:')} {error}
        </Paragraph>
      ) : null}
    </ViewClass>
  );
};

const styles = StyleSheet.create({
  meTopMargin: {
    marginTop: 20,
  },
});

export { MajorError };
