import { useState, useEffect } from 'react';

import { GlobalPubSub, LOCALE_CHANGED_EVENT } from '../services/pubsub';
import { Languages } from '../util/enums';

const LOCALE_STORAGE_KEY = 'preferred_language';

function defaultLanguage() {
  let defaultLanguage = (navigator.language || Languages.english).split('-')[0].toLowerCase();
  if (Languages.toArray().indexOf(defaultLanguage) === -1) {
    defaultLanguage = Languages.english;
  }
  return defaultLanguage;
}

function clearLocale() {
  window.localStorage.removeItem(LOCALE_STORAGE_KEY);
  setLocale(defaultLanguage());
}

function setLocale(locale, remember) {
  if (remember) {
    window.localStorage.setItem(LOCALE_STORAGE_KEY, locale);
  }
  GlobalPubSub.fire(LOCALE_CHANGED_EVENT, locale);
}

function getLocale(cb) {
  const locale = window.localStorage.getItem(LOCALE_STORAGE_KEY) || defaultLanguage();
  cb && cb(locale);
  return locale;
}

function useLocale() {
  const [locale, setLoc] = useState(getLocale());
  useEffect(() => {
    const ref = GlobalPubSub.on(LOCALE_CHANGED_EVENT, value => setLoc(value));
    return () => GlobalPubSub.off(ref);
  }, []);
  return locale;
}

export { getLocale, clearLocale, useLocale, setLocale };
