const PublishStatus = {
  DRAFT: 0,
  PUBLISHED: 1,
  ARCHIVED: 2,
};

const MainSections = {
  DASHBOARD: 1,
  LIBRARY: 2,
  RESOURCES: 3,
  PROGRESS: 4,
  ACCOUNT: 5,
};

const ActivityType = {
  ACTIVITY: 1,
  MEDITATION: 2,
  JOURNALING: 3,
};

const ResourceCategory = {
  FileDownload: 8,
  // FunnyVideo: 1,
  SongRecommendation: 2,
  BookRecommendation: 3,
  MovieRecommendation: 4,
  AudioFile: 5,
  // WrittenJoke: 6,
  // WrittenStory: 7,
  Tutorial: 100, //Hack for the app tutorial displayed on dashboard
};

const QuantitativeAssessmentAnswer = {
  NotAtAll: 1,
  ALittleBit: 2,
  Somewhat: 3,
  QuiteABit: 4,
  VeryMuch: 5,
  Skip: 6,
  getDescription(i) {
    switch (i) {
      case this.NotAtAll:
        return 'Not at all';
      case this.ALittleBit:
        return 'A little bit';
      case this.Somewhat:
        return 'Somewhat';
      case this.QuiteABit:
        return 'Quite a bit';
      case this.VeryMuch:
        return 'Very much';
      case this.Skip:
        return 'Declined to answer';
      default:
        return 'Unknown option';
    }
  },
  toArray() {
    return [
      this.NotAtAll,
      this.ALittleBit,
      this.Somewhat,
      this.QuiteABit,
      this.VeryMuch,
      this.Skip,
    ];
  },
};

const AgeSurveyAnswer = {
  Teen: 15,
  TwentyIsh: 22,
  ThirtyIsh: 32,
  Older: 50,
  Private: 200,
  getDescription(i) {
    switch (i) {
      case this.Teen:
        return '13 - 17';
      case this.TwentyIsh:
        return '18 - 24';
      case this.ThirtyIsh:
        return '25 - 34';
      case this.Older:
        return 'Over 34';
      case this.Private:
        return "I'd rather not say";
      default:
        return 'Unknown option';
    }
  },
  toArray() {
    return [this.Teen, this.TwentyIsh, this.ThirtyIsh, this.Older, this.Private];
  },
};

const AgeSurveyStatus = {
  NotApplicable: 'NOT_APPLICABLE',
  NotYetOffered: 'NOT_YET_OFFERED',
  OfferedMMDDYYY: 'OFFERED_MMDDYYYY',
  Completed: 'COMPLETED',
};

const Breakpoints = {
  SM: 760,
  MD: 1050,
  LG: 1300,
};

const AnalyticsScreenNames = {
  //main authenticated screens
  dashboard: 'Dashboard',
  topics: 'Library',
  resources: 'Resources',
  progress: 'Progress',
  account: 'User Account',
  topicOverview: 'Topic Overview',
  topicDetail: 'Topic Detail',

  //main anonymous screens
  login: 'Login',
  register: 'Registration',
  forgotPassword: 'Password Help',
  resetPassword: 'Password Reset',

  //supplementary screens
  logout: 'Logout',
  disabled: 'Disabled Notice',
  notFound: '404 Error',
  verifyEmail: 'Email Verification',
};

const AnalyticsContentTypes = {
  //popup screens are measured with select_content event instead of screen_view
  // to avoid ending up with the wrong screen name for other subsequent events
  assessment: 'Assessment',
  audioActivity: 'Meditation',
  journalActivity: 'Journaling',
  activityActivity: 'Activity',
  subscribe: 'Subscribe',
  gift: 'Gift',
  songResource: 'Song',
  bookResource: 'Book',
  movieResource: 'Movie',
  audioResource: 'Audio',
  downloadResource: 'Download',
  ageSurvey: 'AgeSurvey',
};

const Languages = {
  english: 'en',
  french: 'fr',
  spanish: 'es',
  toArray() {
    return [this.english, this.french, this.spanish];
  },
  displayLanguage(languageCode) {
    switch (languageCode) {
      case Languages.french:
        return 'Français';
      case Languages.english:
        return 'English';
      case Languages.spanish:
        return 'Español';
      default:
        return 'Unsupported Language';
    }
  },
};

const InAppPurchases = {
  UNLOCK: 1,
  GIFT: 2,
};

const GiftCardStatus = {
  UNSENT: 10,
  SENT: 20,
  REDEEMED: 30,
};

export {
  PublishStatus,
  MainSections,
  ActivityType,
  ResourceCategory,
  QuantitativeAssessmentAnswer,
  AgeSurveyAnswer,
  AgeSurveyStatus,
  Breakpoints,
  AnalyticsScreenNames,
  AnalyticsContentTypes,
  Languages,
  GiftCardStatus,
  InAppPurchases,
};
