import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { View, StyleSheet, Platform, ScrollView, TouchableOpacity } from 'react-native';

import { Icon } from './icon';
import { TopicHeroButton } from './topicHeroButton';
import { TranslatableResourceTypes, useDynamicTranslation } from '../hooks/useDynamicTranslation';
import {
  ScreenSizes,
  useScreenDimensions,
  useScreenSize,
  useStylesForWidth,
} from '../hooks/useStyleForWidth';
import { MAX_AUTH_SCREEN_CONTENT_WIDTH } from '../screens/authenticatedScreen';
import { USER_FIELD_IS_TRIAL, useUserInfo } from '../services/apiService';
import { AppColors, AppStyles, IconNames, IconSizes } from '../services/styles';
import { PublishStatus } from '../util/enums';
import {
  isTouchScreen,
  pagingAttributesForScrollView,
  percentCompletionForTopic,
} from '../util/utils';

const PagingButton = function (props) {
  const { icon, action, isActive } = props;
  return (
    <TouchableOpacity style={styles.carouselDotButton} onPress={action}>
      {icon ? (
        <Icon name={icon} size={IconSizes.large} color={AppColors.menuIconBackground} />
      ) : (
        <View style={styles[`carouselDotInnerButton${isActive ? 'Active' : 'Inactive'}`]} />
      )}
    </TouchableOpacity>
  );
};

const CarouselItem = function (props) {
  const { topic, index, topicCount, onSelect, isTrialUser, style } = props;
  return (
    <View style={[style, ...(index === topicCount - 1 ? [{ marginRight: 0 }] : [])]}>
      <TopicHeroButton
        locked={isTrialUser && !topic.is_trial_content}
        title={useDynamicTranslation(
          topic.id,
          TranslatableResourceTypes.TOPIC,
          'title',
          topic.title
        )}
        percentComplete={percentCompletionForTopic(topic)}
        isDraft={topic.publish_status === PublishStatus.DRAFT}
        action={() => onSelect(topic)}
        imageKey={topic.image_key}
      />
    </View>
  );
};

const ButtonHorizontalMarginMDLGXL = 40;
const TopicCarousel = function (props) {
  const { topics, onSelect, onItemWidthKnown } = props;
  const screenSize = useScreenSize();
  const [initialScrollComplete, setInitialScrollComplete] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(-1);
  const topicButtonHorizontalMargin =
    screenSize === ScreenSizes.SM
      ? AppStyles.mainAuthScreenPaddingSM
      : ButtonHorizontalMarginMDLGXL;
  const scrollRef = useRef(null);
  const screenWidth = useScreenDimensions().width;
  const isTouch = isTouchScreen(screenWidth <= 768);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const topicButtonWidth = useMemo(() => {
    if (screenSize === ScreenSizes.SM) {
      return screenWidth - 60;
    }
    const horizontalSpacing = 2 * topicButtonHorizontalMargin;
    return Math.max(375, (carouselWidth - horizontalSpacing) / 3);
  }, [screenSize, screenWidth, topicButtonHorizontalMargin, carouselWidth]);

  useEffect(() => {
    if (onItemWidthKnown) {
      onItemWidthKnown(topicButtonWidth);
    }
  }, [topicButtonWidth, onItemWidthKnown]);

  const topicButtonHeight = Math.round((topicButtonWidth * 2) / 3) + 20;
  const pageIndexes = useMemo(() => {
    if (
      isTouch || //don't need the paging dots on touchscreens
      carouselWidth === 0
    ) {
      //not sure how many pages are needed until this is measured
      return [];
    }
    let increaseBy = 2;
    if (
      screenSize === ScreenSizes.SM ||
      topicButtonHorizontalMargin + topicButtonWidth * 2 > carouselWidth
    ) {
      increaseBy = 1;
    } else if (topicButtonHorizontalMargin * 2 + topicButtonWidth * 3 <= carouselWidth) {
      increaseBy = 3;
    }
    const indexes = [];
    for (let i = 0; i < topics.length; i += increaseBy) {
      indexes.push(i);
    }
    return indexes;
  }, [screenSize, isTouch, carouselWidth, topicButtonWidth, topicButtonHorizontalMargin, topics]);
  const carouselItemStyle = useStylesForWidth(styles, 'carouselTopicButtonContainer', {
    width: topicButtonWidth,
  });

  const scrollToTopic = useCallback(
    topicIndex => {
      const scrollPositionOfTopic = (topicButtonWidth + topicButtonHorizontalMargin) * topicIndex;
      // noinspection JSUnresolvedFunction
      scrollRef.current.scrollTo({
        x: scrollPositionOfTopic,
        y: 0,
        animated: true,
      });
      if (!isTouch) {
        let newPageIndex = -1;
        for (let x = 1; x < pageIndexes.length; x++) {
          if (topicIndex < pageIndexes[x]) {
            newPageIndex = pageIndexes[x - 1];
            break;
          }
        }
        if (newPageIndex < 0) {
          newPageIndex = pageIndexes[pageIndexes.length - 1];
        }
        setCurrentPageIndex(newPageIndex);
      }
    },
    [topicButtonWidth, topicButtonHorizontalMargin, scrollRef, isTouch, pageIndexes]
  );

  useEffect(() => {
    if (
      !initialScrollComplete && //only do once
      topics.length > 0 && //make sure topics are loaded
      carouselWidth > 0
    ) {
      //make sure carousel and pager is ready
      let initialTopicIndex = 0;
      for (let i = 0; i < topics.length; i++) {
        if (percentCompletionForTopic(topics[i]) < 100) {
          initialTopicIndex = i;
          break;
        }
      }
      scrollToTopic(initialTopicIndex); //TODO: this is scrolling slightly too far left on iPhone Safari and maybe others
      setInitialScrollComplete(true);
    }
  }, [topics, initialScrollComplete, scrollToTopic, carouselWidth]);

  const carouselRightMargin = useMemo(() => {
    if (screenSize !== ScreenSizes.SM && carouselWidth < MAX_AUTH_SCREEN_CONTENT_WIDTH) {
      const suffix = screenSize === ScreenSizes.XL ? 'XL' : 'MDLG';
      return { marginRight: -1 * AppStyles['mainAuthScreenPadding' + suffix] };
    }
    return {};
  }, [screenSize, carouselWidth]);

  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);
  return (
    <View
      onLayout={({
        nativeEvent: {
          layout: { width },
        },
      }) => {
        setCarouselWidth(width);
      }}>
      <ScrollView
        style={useStylesForWidth(
          styles,
          'carouselView',
          { height: topicButtonHeight },
          carouselRightMargin
        )}
        ref={scrollRef}
        {...pagingAttributesForScrollView(topicButtonWidth + topicButtonHorizontalMargin)}
        scrollEnabled={isTouch}
        contentContainerStyle={useStylesForWidth(styles, 'carouselContent')}
        horizontal>
        {topics.map(function (topic, index) {
          return (
            <CarouselItem
              topic={topic}
              isTrialUser={isTrialUser}
              style={carouselItemStyle}
              key={topic.id}
              index={index}
              onSelect={onSelect}
              topicCount={topics.length}
            />
          );
        })}
      </ScrollView>

      {isTouch ? null : (
        <View style={styles.carouselDots}>
          <PagingButton
            action={() =>
              scrollToTopic(pageIndexes[Math.max(0, pageIndexes.indexOf(currentPageIndex) - 1)])
            }
            icon={IconNames.chevronLeft}
          />
          {pageIndexes.map(i => (
            <PagingButton
              action={() => scrollToTopic(i)}
              key={i}
              isActive={currentPageIndex === i}
            />
          ))}
          <PagingButton
            action={() =>
              scrollToTopic(
                pageIndexes[
                  Math.min(pageIndexes.length - 1, pageIndexes.indexOf(currentPageIndex) + 1)
                ]
              )
            }
            icon={IconNames.chevronRight}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  carouselView: {},
  carouselViewSM: {
    marginLeft: -1 * AppStyles.mainAuthScreenPaddingSM,
    marginRight: -1 * AppStyles.mainAuthScreenPaddingSM,
  },
  carouselContentSM: {
    paddingRight: AppStyles.mainAuthScreenPaddingSM,
  },
  carouselContentMDLGXL: {
    paddingRight:
      Platform.OS === 'web' ? MAX_AUTH_SCREEN_CONTENT_WIDTH : ButtonHorizontalMarginMDLGXL,
  },
  carouselTopicButtonContainerSM: {
    marginLeft: AppStyles.mainAuthScreenPaddingSM,
  },
  carouselTopicButtonContainerMDLGXL: {
    marginRight: ButtonHorizontalMarginMDLGXL,
  },
  carouselDots: {
    flexDirection: 'row',
    maxWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  carouselDotButton: {
    width: 30,
    flexShrink: 1,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  carouselDotInnerButtonInactive: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: AppColors.menuIconBackground,
  },
  carouselDotInnerButtonActive: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: AppColors.brandedBlue,
  },
});

export { TopicCarousel };
