import React from 'react';
import { StyleSheet } from 'react-native';

const TopicButtonImage = function ({ style, imageKey }) {
  const oneX = require(`./topic-${imageKey}-button.png`);
  const twoX = require(`./topic-${imageKey}-button@2x.png`);
  const threeX = require(`./topic-${imageKey}-button@3x.png`);

  return (
    <img
      src={threeX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
    />
  );
};

export { TopicButtonImage };
