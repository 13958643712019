import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';

import { Icon } from './icon';
import { Paragraph, ParagraphSizes } from './paragraph';
import { AppColors, IconSizes } from '../services/styles';
import { pressPropsForTouchable, openUrlAction } from '../util/utils';

const ActionLink = function (props) {
  const { leftIcon, bold, rightIcon, openUrl, disabled } = props;
  const color = AppColors.withOpacity(props.color || AppColors.darkText, disabled ? 0.5 : 1);
  const action = props.action || openUrlAction(openUrl);
  const style = props.style || {};
  const size = props.size || ParagraphSizes.NORMAL;
  const iconSize = props.iconSize || IconSizes.normal;
  const title = props.children || props.title;
  const textStyle = { color, textTransform: 'capitalize' };
  if (rightIcon) {
    textStyle.paddingRight = 10;
  }
  if (leftIcon) {
    textStyle.paddingLeft = 10;
  }
  return (
    <TouchableOpacity
      disabled={disabled}
      {...pressPropsForTouchable(action, disabled)}
      style={[styles.alView, style]}>
      <View style={styles.alContent}>
        {leftIcon ? <Icon size={iconSize} name={leftIcon} color={color} /> : null}
        <Paragraph bold={bold} style={textStyle} size={size}>
          {title}
        </Paragraph>
        {rightIcon ? <Icon size={iconSize} name={rightIcon} color={color} /> : null}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  alView: {
    height: 50,
    justifyContent: 'center',
  },
  alContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

export { ActionLink };
