import { useEffect } from 'react';

import { ApiService, useApiRequest } from '../services/apiService';

function useActivityCompletion(activityId, onRequestComplete) {
  const apiRequest = useApiRequest(
    cb => {
      return ApiService.updateActivityProgress(activityId, 100, cb);
    },
    [activityId]
  );

  useEffect(() => {
    if (apiRequest.error) {
      console.error('Error sending activity progress to server:', apiRequest.error);
    } else if (apiRequest.succeeded) {
      onRequestComplete();
    }
  }, [apiRequest, onRequestComplete]);

  return {
    markComplete: () => {
      apiRequest.send();
    },
    error: apiRequest.error,
    isBusy: apiRequest.isBusy,
  };
}

export { useActivityCompletion };
