import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import { SubscribePopup } from './SubscribePopup';
import { ContentPopup } from './contentPopup';
import { TutorialPopup } from './tutorialPopup';
import {
  TranslatableResourceTypes,
  useDynamicTranslation,
} from '../../hooks/useDynamicTranslation';
import {
  ApiService,
  useApiRequest,
  USER_FIELD_IS_TRIAL,
  useUserInfo,
} from '../../services/apiService';
import { staticTranslation } from '../../services/staticTranslations';
import { IconNames } from '../../services/styles';
import { AnalyticsContentTypes, ResourceCategory } from '../../util/enums';
import { analyticsSelectContent } from '../../util/firebaseHelper';
import { getCategoryTitle, imageClassForResourceOrActivity } from '../../util/utils';
import { ActionButton } from '../actionButton';
import { ApiView } from '../apiView';
import { AudioPlayer } from '../audioPlayer';
import { DownloadButton } from '../downloadButton';
import { RichTextDisplay } from '../richTextDisplay';

const ResourcePopup = function (props) {
  const { resource, close } = props;
  const resourceId = resource.id;
  const resourceCategory = parseInt(resource.category, 10);
  const request = useApiRequest(
    cb => {
      return ApiService.getResource(resourceId, cb);
    },
    [resourceId],
    resource.category !== ResourceCategory.Tutorial
  );
  const fetchedResource = request.succeeded ? request.data.resource : resource;
  const rtStyle = fetchedResource.file_id ? styles.resourceTextCenter : null;
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);
  const showTutorial = resource.category === ResourceCategory.Tutorial;
  const showSubscribe = isTrialUser && !resource.is_trial_content;

  useEffect(() => {
    if (!showTutorial && !showSubscribe) {
      const contentType =
        resourceCategory === ResourceCategory.SongRecommendation
          ? AnalyticsContentTypes.songResource
          : resourceCategory === ResourceCategory.BookRecommendation
            ? AnalyticsContentTypes.bookResource
            : resourceCategory === ResourceCategory.MovieRecommendation
              ? AnalyticsContentTypes.movieResource
              : resourceCategory === ResourceCategory.AudioFile
                ? AnalyticsContentTypes.audioResource
                : resourceCategory === ResourceCategory.FileDownload
                  ? AnalyticsContentTypes.downloadResource
                  : 'Unknown';
      analyticsSelectContent(contentType, resourceId);
    }
  }, [resourceId, resourceCategory, showTutorial, showSubscribe]);

  const title = useDynamicTranslation(
    resourceId,
    TranslatableResourceTypes.RESOURCE,
    'title',
    resource.title
  );
  const body = useDynamicTranslation(
    resourceId,
    TranslatableResourceTypes.RESOURCE,
    'body',
    fetchedResource.body
  );
  const externalLink = useDynamicTranslation(
    resourceId,
    TranslatableResourceTypes.RESOURCE,
    'external_link',
    fetchedResource.external_link
  );
  const translatedFile = useDynamicTranslation(
    resourceId,
    TranslatableResourceTypes.RESOURCE,
    'file',
    null
  );

  if (showTutorial) {
    return <TutorialPopup close={close} />;
  }

  if (showSubscribe) {
    return <SubscribePopup close={close} />;
  }

  const fileId = translatedFile ? translatedFile.id : fetchedResource.file_id;
  const fileName = translatedFile ? translatedFile.name : fetchedResource.file_name;
  const fileDuration = translatedFile
    ? translatedFile.audio_duration
    : fetchedResource.audio_duration;
  return (
    <ContentPopup
      title={title}
      subtitle={getCategoryTitle(resource)}
      close={close}
      imageClass={imageClassForResourceOrActivity(fetchedResource)}>
      <ApiView
        succeeded={request.succeeded}
        isBusy={request.isBusy}
        hasError={request.error}
        centerLoading
        errorMessage={request.error}>
        <View style={fileId ? styles.resourceAlignItemsCenter : null}>
          {body ? <RichTextDisplay style={rtStyle} html={body} /> : null}
          {externalLink ? (
            <ActionButton
              rightIcon={IconNames.openInNew}
              title={staticTranslation('Check It Out')}
              style={styles.resourceButton}
              openUrl={externalLink}
            />
          ) : null}
          {!fileId ? null : fetchedResource.category === ResourceCategory.FileDownload ? (
            <DownloadButton fileId={fileId} fileName={fileName} style={styles.resourceButton} />
          ) : (
            <AudioPlayer
              style={styles.resourceAudioPlayer}
              audioDuration={parseFloat(fileDuration) || 0}
              title={resource.title}
              audioId={fileId}
            />
          )}
        </View>
      </ApiView>
    </ContentPopup>
  );
};

const styles = StyleSheet.create({
  resourceAlignItemsCenter: {
    alignItems: 'center',
  },
  resourceTextCenter: {
    textAlign: 'center',
  },
  resourceButton: {
    marginTop: 10,
    alignSelf: 'center',
  },
  resourceAudioPlayer: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export { ResourcePopup };
