import React, { useState } from 'react';

import { AnonymousScreen } from './anonymousScreen';

const AnonymousInterface = function (props) {
  const [avoidKeyboard, setAvoidKeyboard] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);
  const [welcomeMessageForSM, setWelcomeMessageForSM] = useState(null);

  const Screen = props.screen;
  const screenProps = props.forwardProps;

  screenProps.setAvoidKeyboard = value => setAvoidKeyboard(value);
  screenProps.setHideLogo = value => setHideLogo(value);
  screenProps.setWelcomeMessageForSM = value => setWelcomeMessageForSM(value);

  return (
    <AnonymousScreen
      avoidKeyboard={avoidKeyboard}
      hideLogo={hideLogo}
      welcomeMessageForSM={welcomeMessageForSM}>
      <Screen {...screenProps} />
    </AnonymousScreen>
  );
};

export { AnonymousInterface };
