import React from 'react';

import { MajorError } from './majorError';
import { AnonymousScreen } from '../screens/anonymousScreen';

const AppError = function (props) {
  const { message, action, actionTitle, error } = props;

  return (
    <AnonymousScreen>
      <MajorError message={message} error={error} action={action} actionTitle={actionTitle} />
    </AnonymousScreen>
  );
};

export { AppError };
