import React from 'react';
import { View, StyleSheet } from 'react-native';

import { AppColors } from '../services/styles';

const ProgressBarType = {
  white: 'W',
  colored: 'C',
  squared: 'S',
};

const ProgressBar = function (props) {
  const percent = props.percent;
  const propStyle = props.style || {};
  const type = props.type || ProgressBarType.white;
  const borderRadius = type === ProgressBarType.squared ? {} : { borderRadius: 3 };

  return (
    <View style={[styles.progressView, propStyle, borderRadius]}>
      <View
        style={[styles.progressBackground, styles['progressBackground' + type], borderRadius]}
      />
      <View
        style={[
          styles.progressBar,
          styles['progressBar' + type],
          { width: percent + '%' },
          borderRadius,
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  progressView: {
    height: 6,
    width: '100%',
    position: 'relative',
  },
  progressBackground: {
    opacity: 0.3,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  progressBackgroundW: {
    backgroundColor: AppColors.white,
  },
  progressBackgroundC: {
    backgroundColor: AppColors.brandedBlue,
  },
  progressBackgroundS: {
    backgroundColor: AppColors.brandedBlue,
  },
  progressBar: {
    height: 6,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
  },
  progressBarW: {
    backgroundColor: AppColors.white,
  },
  progressBarC: {
    backgroundColor: AppColors.brandedBlue,
  },
  progressBarS: {
    backgroundColor: AppColors.brandedBlue,
  },
});

export { ProgressBar, ProgressBarType };
