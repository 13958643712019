import React from 'react';

const Icon = React.memo(function (props) {
  const style = {
    fontSize: props.size || 18,
    color: props.color || '#000000',
  };
  //https://material.io/resources/icons/?icon=dashboard&style=baseline
  //note, to use material icon themes besides filled, use a different classname like material-icons-outlined
  //also update the link to google web font server. See https://stackoverflow.com/questions/50303454/how-to-use-the-new-material-design-icon-themes-outlined-rounded-two-tone-and/54902967
  return (
    <i className="material-icons" style={style}>
      {props.name}
    </i>
  );
});

export { Icon };
