import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, Platform, View } from 'react-native';

import { ActionButton, ActionButtonType } from '../components/actionButton';
import { ActionLink } from '../components/actionLink';
import { AnonymousLanguageToggle } from '../components/anonymousLanguageToggle';
import { Checkbox } from '../components/checkbox';
import { Heading } from '../components/heading';
import { InstitutionCodeHelp } from '../components/institutionCodeHelp';
import { OrLine } from '../components/orLine';
import { Paragraph } from '../components/paragraph';
import { AlertPopup } from '../components/popups/alertPopup';
import { TextField } from '../components/textField';
import { WarningText } from '../components/warningText';
import { useLocale } from '../hooks/useLocale';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { CinimLogoImage } from '../img/cinim-logo/CinimLogoImage';
import { ApiService, useApiRequest } from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AppStyles, IconNames } from '../services/styles';
import { AnalyticsScreenNames } from '../util/enums';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../util/links';
import { openUrlAction } from '../util/utils';

const RegisterScreen = function (props) {
  const locale = useLocale();
  useScreenTitle(staticTranslation(locale, 'Registration'));
  useScreenView(AnalyticsScreenNames.register, 'RegisterScreen');
  const codeParam = props.code || '';
  //state of login screen
  const [institutionCode, setInstitutionCode] = useState(codeParam);
  const [agreedToConditions, setAgreedToConditions] = useState(false);
  const [gettingConditionsAgreement, setGettingConditionsAgreement] = useState(false);
  const [viewedTermsOfUse, setViewedTermsOfUse] = useState(false);
  const [viewedPrivacyPolicy, setViewedPrivacyPolicy] = useState(false);
  const [name, setName] = useState('');
  const [marketing, setMarketing] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [warning, setWarning] = useState('');
  const registerRequest = useApiRequest(
    cb => {
      return ApiService.register(
        institutionCode,
        name,
        email,
        password,
        confirmPassword,
        agreedToConditions,
        marketing,
        locale,
        cb
      );
    },
    [institutionCode, name, email, password, confirmPassword, agreedToConditions, marketing, locale]
  );
  const formIsFilled = !!name && !!email && !!password && !!confirmPassword;
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const codeRef = useRef(null);

  const showConditionsAgreementBox = () => {
    if (!formIsFilled) {
      setWarning(
        staticTranslation(locale, 'Oops, you need to complete all fields before submitting!')
      );
    } else if (password !== confirmPassword) {
      setWarning(
        staticTranslation(locale, 'Oops, your password confirmation must match your password!')
      );
    } else {
      setWarning('');
      setGettingConditionsAgreement(true);
    }
  };

  useEffect(() => {
    setWarning('');
  }, [locale]);

  const popup = (
    <AlertPopup
      close={() => setGettingConditionsAgreement(a => !a)}
      title={staticTranslation(locale, 'Terms of Use')}
      okButton={false}>
      <Paragraph>
        {staticTranslation(
          locale,
          'You must read and agree to our Terms of Use and Privacy Policy in order to complete your registration.'
        )}
      </Paragraph>
      <ActionLink
        style={styles.regPopupAction}
        rightIcon={viewedTermsOfUse ? IconNames.checkmark : IconNames.openInNew}
        action={() => {
          setViewedTermsOfUse(true);
          openUrlAction(TERMS_OF_USE_URL)();
        }}>
        {staticTranslation(locale, 'View Terms of Use')}
      </ActionLink>
      <ActionLink
        style={styles.regPopupAction}
        rightIcon={viewedPrivacyPolicy ? IconNames.checkmark : IconNames.openInNew}
        action={() => {
          setViewedPrivacyPolicy(true);
          openUrlAction(PRIVACY_POLICY_URL)();
        }}>
        {staticTranslation(locale, 'View Privacy Policy')}
      </ActionLink>
      <ActionButton
        style={styles.regPopupAction}
        action={() => {
          setAgreedToConditions(true);
          setWarning('');
          setGettingConditionsAgreement(false);
          registerRequest.send();
        }}
        disabled={!viewedPrivacyPolicy || !viewedTermsOfUse}>
        {staticTranslation(locale, 'Agree and Finish Registration')}
      </ActionButton>
    </AlertPopup>
  );

  const scrollToBottom = () => {
    //TODO: props.scrollToBottom();
  };

  useEffect(() => {
    props.setHideLogo(false);
    props.setWelcomeMessageForSM(null);
    props.setAvoidKeyboard(true);
  }, [props]);

  return (
    <>
      <Heading>{staticTranslation(locale, 'Register')}</Heading>
      <TextField
        autoFocusHardwareKeyboards
        inputProps={{
          disabled: registerRequest.isBusy,
          maxLength: 100,
          blurOnSubmit: formIsFilled,
          onSubmitEditing: () => {
            if (formIsFilled) {
              if (marketing) {
                showConditionsAgreementBox();
              } else {
                scrollToBottom();
              }
            } else if (name) {
              emailRef.current.focus();
            }
          },
          returnKeyType: 'next',
          placeholder: staticTranslation(locale, 'Full Name'),
          ...(Platform.OS === 'web' ? {} : { autoCapitalize: 'words' }), //Causes bug in webkit: https://bugs.webkit.org/show_bug.cgi?id=148504
          value: name,
          onChangeText: setName,
          autoComplete: 'name',
          autoCompleteType: 'name',
          textContentType: 'name',
          enablesReturnKeyAutomatically: true,
        }}
      />
      <TextField
        ref={emailRef}
        inputProps={{
          disabled: registerRequest.isBusy,
          maxLength: 100,
          blurOnSubmit: formIsFilled,
          onSubmitEditing: () => {
            if (formIsFilled) {
              if (marketing) {
                showConditionsAgreementBox();
              } else {
                scrollToBottom();
              }
            } else if (email) {
              passwordRef.current.focus();
            }
          },
          returnKeyType: 'next',
          keyboardType: 'email-address',
          placeholder: staticTranslation(locale, 'Email'),
          autoCapitalize: 'none',
          value: email,
          onChangeText: setEmail,
          autoComplete: 'email',
          autoCompleteType: 'email',
          textContentType: 'emailAddress',
          enablesReturnKeyAutomatically: true,
        }}
      />
      <TextField
        ref={passwordRef}
        icon={IconNames.lock}
        inputProps={{
          maxLength: 200,
          blurOnSubmit: formIsFilled,
          onSubmitEditing: () => {
            if (formIsFilled) {
              if (marketing) {
                showConditionsAgreementBox();
              } else {
                scrollToBottom();
              }
            } else if (password) {
              confirmPasswordRef.current.focus();
            }
          },
          returnKeyType: 'next',
          disabled: registerRequest.isBusy,
          autoComplete: 'new-password',
          autoCompleteType: 'password',
          textContentType: 'newPassword',
          autoCorrect: false,
          autoCapitalize: 'none',
          enablesReturnKeyAutomatically: true,
          placeholder: staticTranslation(locale, 'Password'),
          value: password,
          onChangeText: setPassword,
          secureTextEntry: true,
        }}
      />
      <TextField
        ref={confirmPasswordRef}
        icon={IconNames.lock}
        inputProps={{
          maxLength: 200,
          blurOnSubmit: formIsFilled,
          onSubmitEditing: () => {
            if (formIsFilled) {
              if (marketing) {
                showConditionsAgreementBox();
              } else {
                scrollToBottom();
              }
            } else if (confirmPassword) {
              codeRef.current.focus();
            }
          },
          returnKeyType: 'next',
          disabled: registerRequest.isBusy,
          autoComplete: 'new-password', //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
          autoCompleteType: 'password',
          textContentType: 'newPassword',
          autoCorrect: false,
          autoCapitalize: 'none',
          enablesReturnKeyAutomatically: true,
          placeholder: staticTranslation(locale, 'Confirm Password'),
          value: confirmPassword,
          onChangeText: setConfirmPassword,
          secureTextEntry: true,
        }}
      />
      <TextField
        ref={codeRef}
        style={styles.regCodeField}
        inputProps={{
          disabled: registerRequest.isBusy,
          maxLength: 8,
          blurOnSubmit: true,
          onSubmitEditing: () => {
            if (formIsFilled) {
              if (marketing) {
                showConditionsAgreementBox();
              } else {
                scrollToBottom();
              }
            }
          },
          clearButtonMode: 'while-editing',
          placeholder: staticTranslation(locale, 'Institution Code (optional)'),
          autoCorrect: false,
          value: institutionCode,
          onChangeText: setInstitutionCode,
          autoComplete: 'off',
          autoCapitalize: 'characters',
          autoCompleteType: 'off',
          textContentType: 'none',
          returnKeyType: 'next',
        }}
      />
      <InstitutionCodeHelp />
      <Checkbox
        title={staticTranslation(
          locale,
          'I agree to allow BreathingRoom to send me important emails from time to time.'
        )}
        style={styles.regCheckbox}
        checked={marketing}
        onChange={() => setMarketing(m => !m)}
      />
      <WarningText>
        {warning ||
          (registerRequest.error
            ? `${staticTranslation(locale, 'There was a problem saving this registration:')} ${
                registerRequest.error
              }`
            : null)}
      </WarningText>
      <ActionButton
        title={staticTranslation(locale, 'Register')}
        action={() => showConditionsAgreementBox()}
        showActivityIndicator={registerRequest.isBusy}
        disabled={registerRequest.isBusy}
      />
      <OrLine />
      <ActionButton
        title={staticTranslation(locale, 'Return to Sign In')}
        type={ActionButtonType.SECONDARY}
        action={() => props.navigateTo('/login')}
        disabled={registerRequest.isBusy}
      />
      {gettingConditionsAgreement ? popup : null}
      <AnonymousLanguageToggle />
    </>
  );
};

const styles = StyleSheet.create({
  regCodeField: {
    marginBottom: 0,
  },
  regPopupAction: {
    marginBottom: 10,
  },
  regCheckbox: {
    marginBottom: 20,
  },
});

export { RegisterScreen };
