import React, { useImperativeHandle, useRef } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';

import { Icon } from './icon';
import { AppColors, AppStyles, IconNames, IconSizes } from '../services/styles';
import { pressPropsForTouchable } from '../util/utils';

const CornerButton = React.forwardRef((props, ref) => {
  const { iconColor, iconName, action, style, opacity } = props;
  const backgroundColorStyle = {
    backgroundColor: AppColors.withOpacity(AppColors.menuIconBackground, opacity || 0.4),
  };
  const touchableRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (touchableRef.current && typeof touchableRef.current.focus === 'function') {
        touchableRef.current.focus();
      }
    },
  }));

  return (
    <TouchableOpacity
      {...pressPropsForTouchable(action)}
      ref={touchableRef}
      style={[styles.cornerButton, backgroundColorStyle, style || {}]}>
      <Icon
        name={iconName || IconNames.close}
        size={IconSizes.large}
        color={iconColor || AppColors.brandedBlue}
      />
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  cornerButton: {
    borderRadius: AppStyles.normalBorderRadius,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: 40,
    width: 40,
  },
});

export { CornerButton };
