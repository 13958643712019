import React, { useEffect } from 'react';
import { View } from 'react-native';

import { MajorError } from '../components/majorError';
import { ScreenLoadingIndicator } from '../components/screenLoadingIndicator';
import { clearLocale } from '../hooks/useLocale';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import {
  ApiService,
  AUTH_STATUS_AUTHENTICATED,
  useApiRequest,
  USER_FIELD_AUTH_STATUS,
  useUserInfo,
} from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AnalyticsScreenNames } from '../util/enums';

const LogoutScreen = function (props) {
  useScreenTitle('');
  useScreenView(AnalyticsScreenNames.logout, 'LogoutScreen');
  const isLoggedIn = useUserInfo(USER_FIELD_AUTH_STATUS) === AUTH_STATUS_AUTHENTICATED;
  const logoutRequest = useApiRequest(
    cb => {
      return ApiService.logout(cb);
    },
    [],
    isLoggedIn
  );

  useEffect(() => {
    props.setHideLogo(false);
    props.setWelcomeMessageForSM(null);
    props.setAvoidKeyboard(false);
  }, [props]);

  useEffect(() => {
    if (!isLoggedIn) {
      clearLocale();
      props.navigateTo('/');
    }
  }, [isLoggedIn, props]);

  return logoutRequest.isBusy ? (
    <View style={{ marginTop: 100 }}>
      <ScreenLoadingIndicator center />
    </View>
  ) : logoutRequest.error ? (
    <MajorError
      message={staticTranslation(`There was a problem while signing out. Please try again later.`)}
      error={logoutRequest.error}
      action={() => props.navigateTo('/')}
    />
  ) : null;
};

export { LogoutScreen };
