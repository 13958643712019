import React from 'react';
import { View, Platform, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';

import { DraftBadge } from './draftBadge';
import { HeadingType, WrappedHeading } from './heading';
import { Icon } from './icon';
import { Paragraph } from './paragraph';
import { TranslatableResourceTypes, useDynamicTranslation } from '../hooks/useDynamicTranslation';
import {
  ScreenSizes,
  useScreenDimensions,
  useScreenSize,
  useStylesForWidth,
} from '../hooks/useStyleForWidth';
import { USER_FIELD_IS_TRIAL, useUserInfo } from '../services/apiService';
import { AppColors, AppStyles, IconNames, IconSizes } from '../services/styles';
import { ResourceCategory } from '../util/enums';
import {
  getCategoryTitle,
  iconForActivity,
  imageClassForResourceOrActivity,
  pagingAttributesForScrollView,
  pressPropsForTouchable,
} from '../util/utils';

const ButtonImage = ({ item }) => {
  const ImageClass = imageClassForResourceOrActivity(item, true);
  return <ImageClass style={styles.resourceItemBoxImage} />;
};

function iconForResource(resource, isTrialUser) {
  if (isTrialUser && !resource.is_trial_content) {
    return IconNames.lock;
  }
  if (resource.activity_type) {
    return iconForActivity(resource);
  }
  switch (resource.category) {
    case ResourceCategory.SongRecommendation:
      return IconNames.music;
    case ResourceCategory.AudioFile:
      return IconNames.audio;
    case ResourceCategory.BookRecommendation:
      return IconNames.book;
    case ResourceCategory.MovieRecommendation:
      return IconNames.movie;
    case ResourceCategory.FileDownload:
      return IconNames.download;
    case ResourceCategory.Tutorial:
      return IconNames.tutorial;
    default:
      return '';
  }
}

const ResourceItem = function (props) {
  const { resource, itemWidth, onShowResource, isTrialUser, displayCategories } = props;
  const itemWidthStyle = {
    width: itemWidth,
    minWidth: itemWidth,
    maxWidth: itemWidth,
  };
  const resourceTitle = useDynamicTranslation(
    resource.id,
    resource.activity_type
      ? TranslatableResourceTypes.ACTIVITY
      : TranslatableResourceTypes.RESOURCE,
    'title',
    resource.title
  );
  return (
    <TouchableOpacity
      style={useStylesForWidth(styles, 'resourcesTouchable', itemWidthStyle)}
      {...pressPropsForTouchable(() => onShowResource(resource))}
      key={resource.id + (resource.activity_type ? 'A' : 'R')}>
      <View style={styles.resourcesTouchableView}>
        <View style={styles.resourcesItemBox}>
          <ButtonImage item={resource} />
          <View style={styles.resourceIconBackground} />
          <View style={styles.resourceIconContainer}>
            <Icon
              name={iconForResource(resource, isTrialUser)}
              size={IconSizes.small}
              color={AppColors.white}
            />
          </View>
        </View>
        <View style={styles.resourceItemText}>
          {displayCategories ? (
            <Paragraph style={styles.resourceItemCategory}>{getCategoryTitle(resource)}</Paragraph>
          ) : null}
          <Paragraph bold style={styles.resourceItemTitle}>
            {resourceTitle}
            <DraftBadge item={resource} />
          </Paragraph>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const ResourcesList = function (props) {
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);
  const isSM = useScreenSize() === ScreenSizes.SM;
  const resources = props.resources || [];
  const underlineTitle = !!props.underlineTitle;
  const columns = props.columns || 2;
  const style = props.style || {};
  const { displayCategories } = props;
  const title = props.title || 'Resources';
  const scrollerWidth = useScreenDimensions().width - AppStyles.mainAuthScreenPaddingSM;
  const onShowResource = props.onShowResource || (() => {});
  const itemWidth = isSM
    ? Math.min(540, scrollerWidth - ITEM_IMAGE_SIZE - AppStyles.mainAuthScreenPaddingSM)
    : Math.floor(100 / columns) + '%';

  const renderItem = resource => {
    return (
      <ResourceItem
        resource={resource}
        onShowResource={onShowResource}
        itemWidth={itemWidth}
        isTrialUser={isTrialUser}
        displayCategories={displayCategories}
        key={resource.id + (resource.activity_type ? 'A' : 'R')}
      />
    );
  };

  const pages = [];
  if (isSM) {
    for (let x = 0; x < resources.length; x += 2) {
      const items = [resources[x]];
      if (x + 1 < resources.length) {
        items.push(resources[x + 1]);
      }
      pages.push(items);
    }
  }

  const underlineStyle = useStylesForWidth(styles, 'resourcesListUnderlineTitle');

  return resources.length === 0 ? null : (
    <View style={style}>
      <WrappedHeading type={HeadingType.THREE} style={underlineTitle ? underlineStyle : {}}>
        {title}
      </WrappedHeading>
      {isSM ? (
        <ScrollView
          contentContainerStyle={styles.resourceListContentContainerSM}
          style={styles.resourcesListSM}
          {...pagingAttributesForScrollView(itemWidth)}
          horizontal>
          {pages.map((pageItems, index) => (
            <View key={index}>{pageItems.map(renderItem)}</View>
          ))}
        </ScrollView>
      ) : (
        <View style={styles.resourcesListMDLGXL}>{resources.map(renderItem)}</View>
      )}
    </View>
  );
};

const ITEM_IMAGE_SIZE = 58;
const styles = StyleSheet.create({
  resourcesListSM: {
    marginRight: -1 * AppStyles.mainAuthScreenPaddingSM,
  },
  resourcesListMDLGXL: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  resourceListContentContainerSM: {
    paddingRight: ITEM_IMAGE_SIZE + AppStyles.mainAuthScreenPaddingSM,
  },
  resourcesTouchable: {
    height: 90,
    flexDirection: 'row',
    alignItems: 'center',
  },
  resourcesTouchableSM: {
    paddingRight: 20,
  },
  resourcesTouchableMDLGXL: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    paddingBottom: 10,
    paddingRight: 20,
    paddingTop: 10,
  },
  resourcesTouchableView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  resourcesItemBox: {
    height: ITEM_IMAGE_SIZE,
    width: ITEM_IMAGE_SIZE,
    position: 'relative',
  },
  resourceItemBoxImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: ITEM_IMAGE_SIZE,
    width: ITEM_IMAGE_SIZE,
    borderRadius: AppStyles.normalBorderRadius,
    ...(Platform.OS === 'web'
      ? {
          transform: 'translateZ(0)', //fix bug in mobile safari caused by having an overflow:hidden element and/or border radius inside of overflow auto
        }
      : {}),
    backgroundColor: AppColors.brandedBlue,
    zIndex: 1,
  },
  resourceIconBackground: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -13,
    marginTop: -13,
    right: 0,
    bottom: 0,
    width: 26,
    height: 26,
    lineHeight: 5,
    borderRadius: AppStyles.normalBorderRadius,
    zIndex: 2,
    backgroundColor: AppColors.withOpacity(AppColors.white, 0.4),
  },
  resourceIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
  },
  resourceItemText: {
    marginLeft: 10,
    flexShrink: 1,
  },
  resourceItemCategory: {
    textTransform: 'uppercase',
    color: AppColors.brandedBlue,
  },
  resourceItemTitle: {
    lineHeight: AppStyles.normalTextSize + 4,
    color: AppColors.darkerText,
  },
  resourcesListUnderlineTitleMDLGXL: {
    borderBottomWidth: 2,
    borderBottomColor: AppColors.lightBackground,
  },
  resourcesListUnderlineTitleSM: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: AppColors.menuIconBackground,
  },
});

export { ResourcesList };
