import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Paragraph, ParagraphSizes } from './paragraph';
import { RadioButton } from './radioButton';
import { setLocale, useLocale } from '../hooks/useLocale';
import { Languages } from '../util/enums';

const AnonymousLanguageToggle = function (props) {
  const locale = useLocale();
  return (
    <View style={[styles.altLanguageToggle, props.style || {}]}>
      <RadioButton
        selected={locale === Languages.english}
        style={{ marginRight: 10 }}
        action={() => setLocale(Languages.english, true)}>
        <Paragraph size={ParagraphSizes.TINY}>
          {Languages.displayLanguage(Languages.english)}
        </Paragraph>
      </RadioButton>
      <RadioButton
        selected={locale === Languages.french}
        style={{ marginRight: 10 }}
        action={() => setLocale(Languages.french, true)}>
        <Paragraph size={ParagraphSizes.TINY}>
          {Languages.displayLanguage(Languages.french)}
        </Paragraph>
      </RadioButton>
      <RadioButton
        selected={locale === Languages.spanish}
        action={() => setLocale(Languages.spanish, true)}>
        <Paragraph size={ParagraphSizes.TINY}>
          {Languages.displayLanguage(Languages.spanish)}
        </Paragraph>
      </RadioButton>
    </View>
  );
};

const styles = StyleSheet.create({
  altLanguageToggle: {
    justifyContent: 'center',
    marginTop: 20,
    flexDirection: 'row',
  },
});

export { AnonymousLanguageToggle };
