import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Platform } from 'react-native';

import { ContentPopup } from './contentPopup';
import { useSpaceForKeyboard } from '../../hooks/useSpaceForKeyboard';
import { ScreenSizes, useScreenSize } from '../../hooks/useStyleForWidth';
import { SubscribeBannerImage } from '../../img/subscribe-banner/SubscribeBannerImage';
import {
  ApiService,
  useApiRequest,
  USER_FIELD_IS_TRIAL,
  useUserInfo,
} from '../../services/apiService';
import { staticTranslation } from '../../services/staticTranslations';
import { AnalyticsContentTypes, InAppPurchases } from '../../util/enums';
import { analyticsSelectContent } from '../../util/firebaseHelper';
import { ActionButton } from '../actionButton';
import { Heading, HeadingType } from '../heading';
import { InAppPurchase } from '../inAppPurchase';
import { InstitutionCodeHelp } from '../institutionCodeHelp';
import { OrLine } from '../orLine';
import { Paragraph } from '../paragraph';
import { ScreenLoadingIndicator } from '../screenLoadingIndicator';
import { TextField } from '../textField';
import { WarningText } from '../warningText';

const SubscribePopup = function (props) {
  const isSM = useScreenSize() === ScreenSizes.SM;
  const spaceForKeyboard = useSpaceForKeyboard(
    Platform.OS === 'web' || (Platform.OS === 'android' && isSM)
  );
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);
  const [code, setCode] = useState('');
  const [busyPurchasing, setBusyPurchasing] = useState(false);
  const updateRequest = useApiRequest(
    cb => {
      return ApiService.setInstitutionCode(code, cb);
    },
    [code]
  );
  const getGiftCardsRequest = useApiRequest(
    cb => {
      //immediately load the user's gift purchases to check for a redeemable card
      return ApiService.getGiftCards(cb);
    },
    [],
    true
  );
  const redeemableGiftCard = getGiftCardsRequest.succeeded
    ? getGiftCardsRequest.data.redeemable_gift_card
    : null;
  const redeemGiftCardRequest = useApiRequest(
    cb => {
      return ApiService.redeemGiftCard(redeemableGiftCard.id, cb);
    },
    [redeemableGiftCard]
  );
  const close = busyPurchasing ? () => {} : props.close;
  const afterSubscribe = props.afterSubscribe || close;
  const contentMessage = props.hideContentMessage
    ? ''
    : staticTranslation('This content is only available to those with full access.');

  useEffect(() => analyticsSelectContent(AnalyticsContentTypes.subscribe, 'subscribe'), []);

  function sendRequest() {
    if (code) {
      updateRequest.send();
    }
  }

  const renderPrompt = () => {
    const message = !redeemableGiftCard
      ? staticTranslation(
          'Complete a one-time purchase to access all our videos, activities, resources, and self-assessments.'
        )
      : (redeemableGiftCard.display_from
          ? staticTranslation(':from sent you a BreathingRoom gift card!', {
              from: redeemableGiftCard.display_from,
            })
          : staticTranslation("You've been sent a BreathingRoom gift card!")) +
        ' ' +
        staticTranslation(
          'You can use this card to unlock full access to all our videos, activities, resources, and self-assessments.'
        );
    return (
      <View style={{ marginBottom: spaceForKeyboard }}>
        {contentMessage ? (
          <Paragraph style={styles.subpopMessage}>{contentMessage}</Paragraph>
        ) : null}
        <Paragraph style={styles.subpopMessage}>{message}</Paragraph>
        {redeemableGiftCard ? (
          <>
            <ActionButton
              action={() => redeemGiftCardRequest.send()}
              showActivityIndicator={redeemGiftCardRequest.isBusy}
              disabled={redeemGiftCardRequest.isBusy}
              title={staticTranslation('Redeem Gift Card')}
            />
            <WarningText>{redeemGiftCardRequest.error}</WarningText>
          </>
        ) : (
          <InAppPurchase
            disabled={updateRequest.isBusy}
            type={InAppPurchases.UNLOCK}
            onPurchasing={purchasing => setBusyPurchasing(purchasing)}
          />
        )}
        <OrLine />
        <Paragraph style={styles.subpopMessage}>
          {staticTranslation(
            "If you are associated with one of our partners, enter that institution's code to connect your account."
          )}
        </Paragraph>
        <Heading type={HeadingType.FOUR}>
          {staticTranslation('Enter your institution code:')}
        </Heading>
        {/*TODO: fix keyboard covering field*/}
        <TextField
          inputProps={{
            disabled: updateRequest.isBusy || redeemGiftCardRequest.isBusy,
            maxLength: 8,
            blurOnSubmit: true,
            onSubmitEditing: () => sendRequest(),
            placeholder: staticTranslation('ex: KW2LP84N'),
            autoCorrect: false,
            clearButtonMode: 'while-editing',
            enablesReturnKeyAutomatically: true,
            autoCapitalize: 'characters',
            value: code,
            onChangeText: setCode,
            autoComplete: 'off',
            autoCompleteType: 'off',
            textContentType: 'none',
            returnKeyType: 'go',
          }}
        />
        <InstitutionCodeHelp />
        <WarningText>{updateRequest.error}</WarningText>
        <ActionButton
          showActivityIndicator={updateRequest.isBusy}
          disabled={updateRequest.isBusy || redeemGiftCardRequest.isBusy || busyPurchasing}
          action={() => sendRequest()}>
          {staticTranslation('Connect with institution')}
        </ActionButton>
      </View>
    );
  };

  const renderSuccess = () => {
    return (
      <View>
        <Heading type={HeadingType.TWO}>{staticTranslation('Thanks!')}</Heading>
        <Paragraph style={styles.subpopMessage}>
          {staticTranslation('Your subscription is now active and you can access all content.')}
        </Paragraph>
        <ActionButton action={afterSubscribe}>{staticTranslation('Done')}</ActionButton>
      </View>
    );
  };
  return (
    <ContentPopup
      close={close}
      headerHeightOverride={spaceForKeyboard > 0 && !isSM ? 100 : null}
      imageClass={SubscribeBannerImage}
      title={staticTranslation('Get full access today!')}>
      {getGiftCardsRequest.isBusy ? (
        <ScreenLoadingIndicator center />
      ) : isTrialUser ? (
        renderPrompt()
      ) : (
        renderSuccess()
      )}
    </ContentPopup>
  );
};

const styles = StyleSheet.create({
  subpopMessage: {
    marginBottom: 30,
  },
});

export { SubscribePopup };
