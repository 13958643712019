import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';

import { AppColors } from '../services/styles';

const ScreenLoadingIndicator = function (props) {
  const { center, color } = props;
  const style = styles[center ? 'sliCenter' : 'sliLeft'];
  return <ActivityIndicator size="large" color={color || AppColors.brandedBlue} style={style} />;
};

const styles = StyleSheet.create({
  sliLeft: {
    alignSelf: 'flex-start',
  },
  sliCenter: {
    alignSelf: 'center',
  },
});
export { ScreenLoadingIndicator };
