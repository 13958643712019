import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  AuthenticatedScreen,
  MAX_AUTH_SCREEN_CONTENT_WIDTH,
  MIN_AUTH_SCREEN_CONTENT_WIDTH,
} from './authenticatedScreen';
import { ApiView } from '../components/apiView';
import { ContentBox } from '../components/contentBox';
import { Heading } from '../components/heading';
import { SubscribePopup } from '../components/popups/SubscribePopup';
import { ActivityPopup } from '../components/popups/activityPopup';
import { ResourcePopup } from '../components/popups/resourcePopup';
import { ResourcesList } from '../components/resourcesList';
import { useAuthContentStyles } from '../hooks/useAuthContentStyles';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { ScreenSizes, useScreenSize } from '../hooks/useStyleForWidth';
import {
  ApiService,
  useApiRequest,
  USER_FIELD_IS_TRIAL,
  useUserInfo,
} from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { ActivityType, AnalyticsScreenNames, MainSections, ResourceCategory } from '../util/enums';
import { getCategoryTitle } from '../util/utils';

const ACTIVITY = 'A';
const RESOURCE = 'R';

function getArrayOfCategories(object, contentType) {
  const results = [];
  for (const category in object) {
    if (object.hasOwnProperty(category)) {
      results.push({ category, items: object[category], contentType });
    }
  }
  return results;
}

const ResourcesScreen = function (props) {
  useScreenTitle(staticTranslation('Resources'));
  useScreenView(AnalyticsScreenNames.resources, 'ResourcesScreen');
  const currentContentId = parseInt(props.resourceId || null, 10);
  const currentContentType = props.contentType || null;
  const screenSize = useScreenSize();
  const resourcesRequest = useApiRequest(
    cb => {
      return ApiService.getResourcesByCategory(cb);
    },
    [],
    true
  );
  const loadedResources = resourcesRequest.succeeded;
  const resourceData = resourcesRequest.data;
  const activitiesRequest = useApiRequest(
    cb => {
      return ApiService.getActivities(false, cb);
    },
    [],
    true
  );
  const loadedActivities = activitiesRequest.succeeded;
  const activityData = activitiesRequest.data;

  const contentList = useMemo(() => {
    if (loadedResources && resourceData && loadedActivities && activityData) {
      //group activities by activity_type
      const activitiesByType = {};
      for (let i = 0; i < activityData.activities.length; i++) {
        const a = activityData.activities[i];
        if (typeof activitiesByType[a.activity_type] === 'undefined') {
          activitiesByType[a.activity_type] = [];
        }
        activitiesByType[a.activity_type].push(a);
      }
      const categories = [
        ...getArrayOfCategories(activitiesByType, ACTIVITY),
        ...getArrayOfCategories(resourceData.resources || {}, RESOURCE),
      ];
      const preferredOrder = {};
      preferredOrder[ACTIVITY + ActivityType.MEDITATION] = 1;
      preferredOrder[ACTIVITY + ActivityType.ACTIVITY] = 2;
      preferredOrder[ACTIVITY + ActivityType.JOURNALING] = 3;
      preferredOrder[RESOURCE + ResourceCategory.SongRecommendation] = 4;
      preferredOrder[RESOURCE + ResourceCategory.MovieRecommendation] = 5;
      preferredOrder[RESOURCE + ResourceCategory.BookRecommendation] = 6;
      preferredOrder[RESOURCE + ResourceCategory.FileDownload] = 7;

      return categories.sort((a, b) => {
        if (preferredOrder[a.contentType + a.category] > preferredOrder[b.contentType + b.category])
          return 1;
        if (preferredOrder[a.contentType + a.category] < preferredOrder[b.contentType + b.category])
          return -1;
        return 0;
      });
    }
    return [];
  }, [loadedResources, resourceData, activityData, loadedActivities]);

  const currentContent = useMemo(() => {
    for (let i = 0; i < contentList.length; i++) {
      if (contentList[i].contentType === currentContentType) {
        const match = contentList[i].items.filter(r => r.id === currentContentId);
        if (match.length) {
          return match[0];
        }
      }
    }
    return null;
  }, [contentList, currentContentId, currentContentType]);

  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);

  const sections = () => {
    return contentList.map(list => {
      return (
        <ResourcesList
          title={list.items.length ? getCategoryTitle(list.items[0], false) : 'Unknown'}
          key={list.category + list.contentType}
          columns={screenSize === ScreenSizes.SM ? 1 : screenSize === ScreenSizes.MD ? 2 : 3}
          resources={list.items}
          onShowResource={resource => {
            if (isTrialUser && !resource.is_trial_content) {
              props.navigateTo('/resources?subscribe=1');
            } else {
              props.navigateTo('/resources/' + resource.id + '?contentType=' + list.contentType);
            }
          }}
          underlineTitle
          style={styles.resourcesList}
        />
      );
    });
  };

  const close = () => props.navigateTo('/resources');
  const popupView = props.subscribe ? (
    <SubscribePopup close={close} />
  ) : !currentContent ? null : currentContentType === ACTIVITY ? (
    <ActivityPopup trackProgress={false} activity={currentContent} close={close} />
  ) : (
    <ResourcePopup close={close} resource={currentContent} />
  );

  return (
    <>
      <AuthenticatedScreen {...props} currentSection={MainSections.RESOURCES}>
        <View style={useAuthContentStyles()}>
          <Heading>{staticTranslation('Resources')}</Heading>
          <ApiView
            centerLoading
            succeeded={loadedResources && loadedActivities}
            hasError={resourcesRequest.error || activitiesRequest.error}
            isBusy={resourcesRequest.isBusy || activitiesRequest.isBusy}
            errorMessage={`${staticTranslation('There was a problem loading the resource data')} (${
              resourcesRequest.error || activitiesRequest.error
            })`}>
            {screenSize === ScreenSizes.SM ? (
              <View>{sections()}</View>
            ) : (
              <ContentBox style={styles.resourcesContentMDLGXL}>{sections()}</ContentBox>
            )}
          </ApiView>
        </View>
      </AuthenticatedScreen>
      {popupView}
    </>
  );
};

const styles = StyleSheet.create({
  resourcesContentMDLGXL: {
    maxWidth: MAX_AUTH_SCREEN_CONTENT_WIDTH,
    minWidth: MIN_AUTH_SCREEN_CONTENT_WIDTH,
  },
  resourcesList: {
    marginBottom: 40,
  },
});

export { ResourcesScreen };
