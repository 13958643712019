import React from 'react';
import { StyleSheet } from 'react-native';

const ResourceMovieButtonImage = function ({ style }) {
  const oneX = require('./resource-movie-button.png');
  const twoX = require('./resource-movie-button@2x.png');
  const threeX = require('./resource-movie-button@3x.png');
  return (
    <img
      src={threeX}
      alt=""
      style={StyleSheet.flatten(style)}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
    />
  );
};

export { ResourceMovieButtonImage };
