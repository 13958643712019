import React from 'react';
import { StyleSheet } from 'react-native';

const TopicDetailsBannerImage = function ({ style }) {
  const oneX = require('./topic-details-banner.png');
  const twoX = require('./topic-details-banner@2x.png');
  const threeX = require('./topic-details-banner@3x.png');
  return (
    <img
      src={threeX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
    />
  );
};

export { TopicDetailsBannerImage };
