import React from 'react';
import { StyleSheet, Platform, View } from 'react-native';

import { Paragraph } from './paragraph';
import { ProgressBar, ProgressBarType } from './progressBar';
import { AppColors } from '../services/styles';

function formatAudioDurationForDisplay(seconds) {
  const minutes = Math.floor(seconds / 60);
  const displaySeconds = '0' + Math.min(59, Math.round(seconds % 60));
  return minutes + ':' + displaySeconds.substr(displaySeconds.length - 2);
}

function AudioTimeline(props) {
  const { position, duration } = props;
  return (
    <View style={AP_STYLES.audioTimelineContainer}>
      <Paragraph style={AP_STYLES.audioTime} bold>
        {formatAudioDurationForDisplay(position)}
      </Paragraph>
      <View style={AP_STYLES.audioSliderContainer}>
        <ProgressBar
          type={ProgressBarType.colored}
          percent={Math.round(100 * (duration > 0 ? position / duration : 0))}
        />
      </View>
      <Paragraph style={[AP_STYLES.audioTime, { textAlign: 'right' }]} bold>
        {formatAudioDurationForDisplay(duration)}
      </Paragraph>
    </View>
  );
}

const AP_JUMP_INTERVAL = 30;

const AP_STYLES = StyleSheet.create({
  audioView: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  audioTimelineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
  audioTime: {
    color: AppColors.darkerText,
    flexGrow: 0,
    minWidth: 50,
    flexShrink: 0,
  },
  audioSliderContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingRight: 10,
    paddingLeft: 10,
  },
  audioControls: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  audioPlayButton: {
    backgroundColor: AppColors.brandedBlue,
    height: 60,
    width: 60,
    borderRadius: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...(Platform.OS === 'web'
      ? {
          marginLeft: '5%',
          marginRight: '5%',
        }
      : {}),
  },
  audioPlayButtonWrapper: {
    height: 60,
    width: 60,
    borderRadius: 30,
  },
  audioSkipButton: {
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export { AP_STYLES, AP_JUMP_INTERVAL, AudioTimeline };
