import React, { useRef, useMemo } from 'react';
import { View, StyleSheet, Platform } from 'react-native';

import { AuthenticatedScreen } from './authenticatedScreen';
import { ActionButton } from '../components/actionButton';
import { ActionLink } from '../components/actionLink';
import { CompletedBadge } from '../components/completedBadge';
import { ContentBox } from '../components/contentBox';
import { ContentBoxRow } from '../components/contentBoxRow';
import { CornerButton } from '../components/cornerButton';
import { DraftBadge } from '../components/draftBadge';
import { GradientMask } from '../components/gradientMask';
import { Heading } from '../components/heading';
import { Icon } from '../components/icon';
import { MajorError } from '../components/majorError';
import { Paragraph } from '../components/paragraph';
import { ProgressBar } from '../components/progressBar';
import { ScreenLoadingIndicator } from '../components/screenLoadingIndicator';
import { useAuthContentStyles } from '../hooks/useAuthContentStyles';
import { TranslatableResourceTypes, useDynamicTranslation } from '../hooks/useDynamicTranslation';
import { useElementDimensions } from '../hooks/useElementDimensions';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { ScreenSizes, useScreenSize, useStylesForWidth } from '../hooks/useStyleForWidth';
import { TopicBannerImage } from '../img/topic-banner/TopicBannerImage';
import {
  ApiService,
  useApiRequest,
  USER_FIELD_IS_TRIAL,
  useUserInfo,
} from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors, AppStyles, IconNames, IconSizes } from '../services/styles';
import { AnalyticsScreenNames, MainSections } from '../util/enums';
import { percentCompletionForTopic, TIME_TO_COMPLETE_ASSESSMENT } from '../util/utils';

function isSegmentComplete(segment, topicAssessmentCompleted) {
  const done = segment.done_steps + segment.done_exercises;
  const total = segment.total_exercises + segment.total_steps;
  const isAssessmentOutstanding = !topicAssessmentCompleted && segment.has_user_assessment;
  return done === total && !isAssessmentOutstanding;
}

const SegmentRow = function (props) {
  const { segment, isTrialUser, topic, navigateTo } = props;
  const segmentTitle = useDynamicTranslation(
    segment.id,
    TranslatableResourceTypes.SEGMENT,
    'title',
    segment.title
  );
  const accessibleSteps = segment.steps.filter(step => {
    return !isTrialUser || step.is_trial_content;
  });
  //TODO: go to first incomplete step BUT need to factor in activities so people don't skip over them
  // const incompleteSteps = accessibleSteps.filter(step => !isItemComplete(step));
  // const linkToStepId = incompleteSteps.length ? incompleteSteps[0].id : accessibleSteps.length ? accessibleSteps[0].id : null;
  const linkToStepId = accessibleSteps.length ? accessibleSteps[0].id : null;
  const completed = isSegmentComplete(segment, topic.user_assessment_completed);
  const number = completed ? (
    <CompletedBadge />
  ) : (
    <Paragraph bold style={styles.toSegmentNumber}>
      {segment.segment_number}
    </Paragraph>
  );

  const left = (
    <View style={styles.toSegmentLeft}>
      {number}
      <Paragraph style={styles.toSegmentTitle}>
        {segmentTitle}
        <DraftBadge item={segment} />
      </Paragraph>
    </View>
  );

  const right = completed ? (
    <Paragraph bold style={styles.toSegmentTimeComplete}>
      {staticTranslation('Done')}
    </Paragraph>
  ) : (
    <Paragraph bold style={styles.toSegmentTime}>
      {staticTranslation(':number min', {
        number:
          segment.minutes_to_complete +
          (segment.has_user_assessment ? TIME_TO_COMPLETE_ASSESSMENT : 0),
      })}
    </Paragraph>
  );

  return (
    <ContentBoxRow
      action={() => {
        navigateTo(
          '/topic_detail/' + (topic.id || '0') + (linkToStepId ? '?stepId=' + linkToStepId : '')
        );
      }}
      left={left}
      right={right}
    />
  );
};

const TopicOverviewScreen = function (props) {
  useScreenView(AnalyticsScreenNames.topicOverview, 'TopicOverviewScreen');
  const topicId = props.topicId;
  const topicRequest = useApiRequest(
    cb => {
      return ApiService.getModule(topicId, true, cb);
    },
    [topicId],
    true
  );
  const topicSubtitleStyle = useStylesForWidth(styles, 'toTopicSubtitle');
  const lgHeadingStyle = useStylesForWidth(styles, 'lgHeading');
  const statsSegmentsStyle = useStylesForWidth(styles, 'toStatsSegments');
  const statsClockStyle = useStylesForWidth(styles, 'toStatsClock');
  const overlapContentStyle = useStylesForWidth(styles, 'toOverlapContents');
  const statsStyle = useStylesForWidth(styles, 'toStats');
  const statsTimeStyle = useStylesForWidth(styles, 'toStatsTime');
  const statsTimeValueStyle = useStylesForWidth(styles, 'toStatsTimeValue');
  const mobileHeadingStyle = useStylesForWidth(styles, 'mobileHeading');
  const imageBackgroundStyle = useStylesForWidth(styles, 'toImageBackground');
  const gradientMaskStyle = useStylesForWidth(styles, 'toOverlapGradientMask');
  const topic = topicRequest.data && topicRequest.succeeded ? topicRequest.data.module : {};
  const completionPercentage = percentCompletionForTopic(topic);
  const screenSize = useScreenSize();
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);
  const actionButton = (
    <ActionButton
      action={() => props.navigateTo('/topic_detail/' + topicId)}
      disabled={!topicRequest.succeeded}
      style={useStylesForWidth(styles, 'toStartButton')}
      title={staticTranslation(
        completionPercentage >= 100
          ? 'Revisit Topic'
          : completionPercentage > 0
            ? 'Continue Topic'
            : "Let's get started"
      )}
      rightIcon={IconNames.chevronRight}
    />
  );

  const topicTitle = useDynamicTranslation(
    topic.id,
    TranslatableResourceTypes.TOPIC,
    'title',
    topic.title
  );
  const topicSubtitle = useDynamicTranslation(
    topic.id,
    TranslatableResourceTypes.TOPIC,
    'subtitle',
    topic.subtitle
  );
  const topicBody = useDynamicTranslation(
    topic.id,
    TranslatableResourceTypes.TOPIC,
    'body',
    topic.body
  );

  useScreenTitle(topicTitle || '', false);
  const renderInfo = () => {
    return (
      <View>
        <Paragraph bold style={topicSubtitleStyle}>
          {topicSubtitle}
          <DraftBadge item={topic} />
        </Paragraph>
        <Heading style={lgHeadingStyle}>{topicTitle}</Heading>
        <Paragraph>{topicBody}</Paragraph>
        {screenSize === ScreenSizes.SM ? null : actionButton}
        <ContentBox style={styles.toSegments} title={staticTranslation('Segments')}>
          {topic.segments.map(function (segment) {
            return (
              <SegmentRow
                navigateTo={props.navigateTo}
                key={segment.id}
                segment={segment}
                topic={topic}
                isTrialUser={isTrialUser}
              />
            );
          })}
        </ContentBox>
      </View>
    );
  };

  const renderError = () => {
    return (
      <MajorError
        boxed
        message={staticTranslation(
          'There was a problem loading the details for this topic. Please check your network connection and try again.'
        )}
        error={topicRequest.error}
        actionTitle={staticTranslation('Try Again')}
        action={() => {
          topicRequest.reset();
          topicRequest.send();
        }}
      />
    );
  };

  const renderStats = () => {
    const completedSegments = topic.segments.filter(s =>
      isSegmentComplete(s, topic.user_assessment_completed)
    ).length;

    const numberOfSegments = topic.segments.length;

    return (
      <View style={overlapContentStyle}>
        <View style={statsStyle}>
          <Paragraph bold style={statsSegmentsStyle}>
            {completedSegments}/{numberOfSegments} {staticTranslation('Segments')}
          </Paragraph>
          <View style={statsClockStyle}>
            <Icon color={AppColors.white} size={IconSizes.normal} name={IconNames.clock} />
          </View>
          <View style={statsTimeStyle}>
            <Paragraph style={styles.toStatsTimeLabel}>{staticTranslation('Total Time')}</Paragraph>
            <Paragraph bold style={statsTimeValueStyle}>
              {staticTranslation(':number min', {
                number: topic.minutes_to_complete + TIME_TO_COMPLETE_ASSESSMENT,
              })}
            </Paragraph>
          </View>
        </View>
        <Heading style={mobileHeadingStyle}>{topicTitle}</Heading>
        <ProgressBar percent={completionPercentage} style={styles.toProgress} />
      </View>
    );
  };

  const mainViewRef = useRef(null);
  const mainViewWidth = useElementDimensions(mainViewRef).width;

  const overlapWidthStyle = useMemo(() => {
    if (screenSize < ScreenSizes.LG) {
      return {};
    }
    const viewWidthInLGXL = Math.min(mainViewWidth * (VIEW_LGXL_WIDTH / 100), VIEW_LGXL_MAX_WIDTH);
    return { width: mainViewWidth - viewWidthInLGXL };
  }, [mainViewWidth, screenSize]);

  const BackButtonClass = screenSize <= ScreenSizes.MD ? CornerButton : ActionLink;
  const backButton = (
    <BackButtonClass
      opacity={0.3}
      style={useStylesForWidth(styles, 'toBack')}
      iconColor={AppColors.white}
      iconName={IconNames.chevronLeft}
      action={() => props.navigateTo('/topics')}
      leftIcon={IconNames.chevronLeft}
      color={screenSize < ScreenSizes.LG ? AppColors.white : null}
      title={staticTranslation('Back')}
      bold
    />
  );

  const overlap = (
    <View style={useStylesForWidth(styles, 'toOverlap', overlapWidthStyle)}>
      {topicRequest.succeeded ? (
        <>
          <TopicBannerImage
            imageKey={topicRequest.data.module.image_key}
            style={imageBackgroundStyle}
          />
          <GradientMask zIndex={2} style={gradientMaskStyle} />
          {renderStats()}
        </>
      ) : null}
    </View>
  );

  return (
    <>
      <AuthenticatedScreen
        {...props}
        menuReplacement={
          screenSize === ScreenSizes.SM ? (
            <View style={styles.toStartButtonSMContainer}>{actionButton}</View>
          ) : null
        }
        currentSection={MainSections.LIBRARY}>
        <View
          style={useStylesForWidth(styles, 'toScreen')}
          ref={mainViewRef}
          onLayout={() => {
            /*need onLayout for measure() to work on Android*/
          }}>
          <View style={useStylesForWidth(styles, 'toView', ...useAuthContentStyles())}>
            {topicRequest.isBusy ? (
              <ScreenLoadingIndicator center={screenSize === ScreenSizes.SM} />
            ) : null}
            {topicRequest.isBusy ? null : topicRequest.succeeded ? renderInfo() : renderError()}
          </View>
          {screenSize < ScreenSizes.LG && topicRequest.succeeded ? overlap : null}
        </View>
        {topicRequest.succeeded ? backButton : null}
      </AuthenticatedScreen>
      {screenSize >= ScreenSizes.LG ? overlap : null}
    </>
  );
};

const VIEW_LGXL_MAX_WIDTH = 500;
const VIEW_LGXL_WIDTH = 60;
const styles = StyleSheet.create({
  toScreenSMMD: {
    flexDirection: 'column-reverse',
  },
  toScreenLGXL: {
    width: '100%',
    minHeight: '100%',
  },
  toViewSM: {
    marginBottom: 20,
    flexGrow: 1,
    width: '100%',
  },
  toViewLGXL: {
    width: VIEW_LGXL_WIDTH + '%',
    maxWidth: VIEW_LGXL_MAX_WIDTH,
  },
  toViewLG: {
    paddingTop: 50 + AppStyles.mainAuthScreenPaddingMDLG * 2,
  },
  toViewXL: {
    paddingTop: 50 + AppStyles.mainAuthScreenPaddingXL * 2,
  },
  toBack: {
    position: 'absolute',
    zIndex: 100,
    elevation: 100,
  },
  toBackSM: {
    top: AppStyles.mainAuthScreenPaddingSM,
    left: AppStyles.mainAuthScreenPaddingSM,
  },
  toBackMDLG: {
    top: AppStyles.mainAuthScreenPaddingMDLG,
    left: AppStyles.mainAuthScreenPaddingMDLG,
  },
  toBackXL: {
    top: AppStyles.mainAuthScreenPaddingXL,
    left: AppStyles.mainAuthScreenPaddingXL,
  },
  toStartButtonSMContainer: {
    flexGrow: 1,
    paddingLeft: AppStyles.mainAuthScreenPaddingSM,
    paddingRight: AppStyles.mainAuthScreenPaddingSM,
  },
  toStartButtonSM: {
    width: '100%',
  },
  toStartButtonMDLGXL: {
    marginTop: 30,
  },
  toTopicSubtitleSM: {
    display: 'none',
  },
  toTopicSubtitleMDLGXL: {
    textTransform: 'uppercase',
    color: AppColors.brandedBlue,
  },
  toSegments: {
    marginTop: 30,
    marginBottom: 20,
  },
  toSegmentLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toSegmentNumber: {
    color: AppColors.brandedBlue,
    backgroundColor: '#DFF2FD',
    width: 30,
    lineHeight: 30,
    textAlign: 'center',
    borderRadius: AppStyles.smallBorderRadius,
  },
  toSegmentTitle: {
    paddingLeft: 20,
    color: AppColors.darkText,
  },
  toSegmentTime: {
    color: AppColors.darkestText,
  },
  toSegmentTimeComplete: {
    color: AppColors.brandedOrange,
    textTransform: 'uppercase',
  },
  toOverlapGradientMaskSM: {
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
    borderBottomRightRadius: AppStyles.largeBorderRadius,
  },
  toOverlapGradientMaskMDLGXL: {
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
    borderTopLeftRadius: AppStyles.largeBorderRadius,
  },
  toOverlap: {
    overflow: 'hidden',
  },
  toOverlapSM: {
    height: 280,
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
    borderBottomRightRadius: AppStyles.largeBorderRadius,
  },
  toOverlapMD: {
    height: 350,
  },
  toOverlapMDLGXL: {
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
    borderTopLeftRadius: AppStyles.largeBorderRadius,
  },
  toOverlapLGXL: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  },
  toImageBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1,
    backgroundColor: AppColors.brandedBlue,
  },
  toImageBackgroundSM: {
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
    borderBottomRightRadius: AppStyles.largeBorderRadius,
  },
  toImageBackgroundMDLGXL: {
    ...(Platform.OS === 'web'
      ? {
          transform: 'translateZ(0)', //fix bug in mobile safari caused by having an overflow:hidden element and/or border radius inside of overflow auto
        }
      : {}),
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
    borderTopLeftRadius: AppStyles.largeBorderRadius,
  },
  toOverlapContents: {
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 3,
  },
  toOverlapContentsSM: {
    padding: AppStyles.mainAuthScreenPaddingSM,
  },
  toOverlapContentsMDLG: {
    padding: AppStyles.mainAuthScreenPaddingMDLG,
  },
  toOverlapContentsXL: {
    padding: AppStyles.mainAuthScreenPaddingXL,
  },
  toStats: {
    flexDirection: 'row',
  },
  toStatsSM: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  toStatsMDLGXL: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  toStatsSegmentsSM: {
    display: 'none',
  },
  toStatsSegmentsMDLGXL: {
    color: AppColors.white,
    flexGrow: 1,
    flexShrink: 1,
  },
  toStatsClock: {
    flexGrow: 0,
    flexShrink: 0,
  },
  toStatsClockMDLGXL: {
    height: 48,
    width: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: AppColors.withOpacity(AppColors.white, 0.2),
    borderRadius: AppStyles.normalBorderRadius,
  },
  toStatsTime: {
    marginLeft: 10,
  },
  toStatsTimeSM: {
    flexDirection: 'row',
    alignContent: 'center',
  },
  toStatsTimeMDLGXL: {
    flexGrow: 0,
    flexShrink: 0,
  },
  toStatsTimeLabel: {
    color: AppColors.white,
  },
  toStatsTimeValue: {
    color: AppColors.white,
  },
  toStatsTimeValueSM: {
    paddingLeft: 10,
  },
  mobileHeadingSM: {
    color: AppColors.white,
    marginBottom: 0,
    marginTop: 10,
  },
  mobileHeadingMDLGXL: {
    display: 'none',
  },
  lgHeadingSM: {
    display: 'none',
  },
  toProgress: {
    marginTop: 20,
  },
});

export { TopicOverviewScreen };
