import { useState, useEffect } from 'react';

import { useLocale } from './useLocale';
import {
  ApiService,
  USER_FIELD_IS_DISABLED,
  USER_FIELD_PREFERRED_LANGUAGE,
  useUserInfo,
} from '../services/apiService';
import { Languages } from '../util/enums';

const T = {
  translations: {},
  loadingLanguages: {},
  callbacks: {},
  isLanguageLoaded(lang) {
    return !!this.translations[lang];
  },
  getTranslation(lang, key, fallback) {
    if (!this.isLanguageLoaded(lang) || typeof this.translations[lang][key] === 'undefined') {
      return fallback;
    }
    return this.translations[lang][key];
  },
  loadTranslationsForLanguage(lang, cb) {
    //TODO: do when user object first loads?
    if (lang === Languages.english) {
      cb(true);
      return;
    }
    if (this.isLanguageLoaded(lang)) {
      cb(true);
      return;
    }
    const a = this.callbacks[lang] || [];
    a.push(cb);
    this.callbacks[lang] = a;
    if (!this.loadingLanguages[lang]) {
      this.loadingLanguages[lang] = true;
      ApiService.getTranslationsForLanguage(lang, result => {
        if (result.success) {
          this.translations[lang] = result.data[lang];
        }
        this.loadingLanguages[lang] = false;
        this.callbacks[lang].forEach(cb => cb(result.success));
        this.callbacks[lang] = [];
      });
    }
  },
};

const TranslatableResourceTypes = {
  TOPIC: 'App\\Module',
  SEGMENT: 'App\\Lesson',
  STEP: 'App\\Step',
  ACTIVITY: 'App\\Exercise',
  RESOURCE: 'App\\ExtraContent',
  INSTITUTION_CODE: 'App\\InstitutionCode',
};

function useIsAuthenticatedLocaleReady() {
  const lang = useUserInfo(USER_FIELD_PREFERRED_LANGUAGE); //null for anonymous
  const isUserDisabled = useUserInfo(USER_FIELD_IS_DISABLED); //null for anonymous
  const [isLocaleReady, setIsLocaleReady] = useState(
    lang && (lang === Languages.english || isUserDisabled || T.isLanguageLoaded(lang))
  );
  const [loadFailed, setLoadFailed] = useState(false);
  useEffect(() => {
    let cb = success => {
      setLoadFailed(!success);
      setIsLocaleReady(success);
    };
    if (lang && lang !== Languages.english && !isUserDisabled && !T.isLanguageLoaded(lang)) {
      //NOTE: we could also setIsLocaleReady(T.isLanguageLoaded(lang)) before making this call,
      // but that might make the app flash to a blank spinner screen if the user is updating their profile
      // instead we leave it as true once it is true the first time (which will be during initial app load)
      // subsequent lang changes only happen on the profile screen, where we will optimistically assume the translations will load ok
      setLoadFailed(false);
      T.loadTranslationsForLanguage(lang, success => cb(success));
    } else {
      setIsLocaleReady(
        lang && (lang === Languages.english || isUserDisabled || T.isLanguageLoaded(lang))
      );
    }
    return () => {
      cb = () => {};
    };
  }, [lang, isUserDisabled]);
  return [isLocaleReady, loadFailed];
}

function useDynamicTranslation(resourceId, resourceType, fieldName, englishValue) {
  const lang = useLocale();
  const key = `${resourceType}-${resourceId}-${fieldName}`;
  const [translation, setTranslation] = useState(T.getTranslation(lang, key, englishValue));
  useEffect(() => {
    let cb = success => {
      if (success) {
        setTranslation(T.getTranslation(lang, key, englishValue));
      }
    };
    if (lang === Languages.english) {
      setTranslation(englishValue);
    } else {
      T.loadTranslationsForLanguage(lang, success => cb(success));
    }
    return () => {
      cb = () => {};
    };
  }, [lang, englishValue, key]);

  return translation;
}

export { useIsAuthenticatedLocaleReady, useDynamicTranslation, TranslatableResourceTypes };
