import React from 'react';
import { StyleSheet } from 'react-native';

import { AppColors } from '../services/styles';

const RichTextDisplay = function (props) {
  const content = { __html: props.html || '' };
  const propsStyle = props.style || {};

  return (
    <div
      style={StyleSheet.flatten([styles.rtdDiv, propsStyle])}
      className="RichText"
      dangerouslySetInnerHTML={content}
    />
  );
};

const styles = StyleSheet.create({
  rtdDiv: {
    color: AppColors.darkText,
  },
});
export { RichTextDisplay };
