import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { AlertPopup } from './alertPopup';
import { ApiService, useApiRequest } from '../../services/apiService';
import { staticTranslation } from '../../services/staticTranslations';
import { ActionButton, ActionButtonType } from '../actionButton';
import { Paragraph } from '../paragraph';
import { WarningText } from '../warningText';

const ConfirmAccountDeletionPopup = function (props) {
  const sendRequest = useApiRequest(cb => {
    return ApiService.deleteCurrentUser(cb);
  });
  const close = () => {
    if (!sendRequest.isBusy) {
      props.close();
    }
  };
  const [onStepOne, setOnStepOne] = useState(true);

  const firstSetOfButtons = () => {
    return (
      <View style={styles.confirmDeletionButtons}>
        <ActionButton
          type={ActionButtonType.SECONDARY}
          title={staticTranslation('Yes')}
          action={() => setOnStepOne(false)}
        />
        <ActionButton title={staticTranslation('No')} action={close} />
      </View>
    );
  };

  const secondSetOfButtons = () => {
    return (
      <View style={styles.confirmDeletionButtons}>
        <ActionButton
          disabled={sendRequest.isBusy}
          type={ActionButtonType.SECONDARY}
          title={staticTranslation('No')}
          action={close}
        />
        <ActionButton
          type={ActionButtonType.DANGER}
          style={styles.confirmDeletionButton}
          disabled={sendRequest.isBusy}
          showActivityIndicator={sendRequest.isBusy}
          title={staticTranslation('Yes, proceed now')}
          action={() => sendRequest.send()}
        />
      </View>
    );
  };

  return (
    <AlertPopup close={close} title={staticTranslation('Delete my account')} okButton={false}>
      <Paragraph style={styles.confirmDeletionParagraph}>
        {onStepOne
          ? staticTranslation(
              'Do you really want to delete your BreathingRoom account? This action is permanent and cannot be undone.'
            )
          : staticTranslation('Are you really sure?')}
      </Paragraph>
      <WarningText>
        {sendRequest.error
          ? `${staticTranslation('There was a problem sending this request')} (${
              sendRequest.error
            })`
          : null}
      </WarningText>
      {onStepOne ? firstSetOfButtons() : secondSetOfButtons()}
    </AlertPopup>
  );
};

const styles = StyleSheet.create({
  confirmDeletionParagraph: {
    marginBottom: 20,
    textAlign: 'center',
  },
  confirmDeletionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
  },
  confirmDeletionButton: {
    maxWidth: '70%',
  },
});

export { ConfirmAccountDeletionPopup };
