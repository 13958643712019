import { useEffect } from 'react';

import { analyticsScreenView } from '../util/firebaseHelper';

function useScreenView(screenName, screenClass) {
  useEffect(() => {
    analyticsScreenView(screenName, screenClass);
  }, [screenName, screenClass]);
}

export { useScreenView };
