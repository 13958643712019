import React, { useState, useRef, useEffect } from 'react';
import { View } from 'react-native';
import { TextField } from '../shared/components/textField';
import { Heading } from '../shared/components/heading';
import { useApiRequest } from '../shared/services/apiService';
import {
  ActionButton,
  ActionButtonType,
} from '../shared/components/actionButton';
import {
  ApiService,
  AUTH_STATUS_AUTHENTICATED,
  USER_FIELD_AUTH_STATUS,
  useUserInfo,
} from '../shared/services/apiService';
import { Paragraph } from '../shared/components/paragraph';
import { WarningText } from '../shared/components/warningText';
import { OrLine } from '../shared/components/orLine';
import { IconNames } from '../shared/services/styles';
import { useScreenTitle } from '../shared/hooks/useScreenTitle';
import { useScreenView } from '../shared/hooks/useScreenView';
import { AnalyticsScreenNames } from '../shared/util/enums';
import { AnonymousLanguageToggle } from '../shared/components/anonymousLanguageToggle';
import { useLocale } from '../shared/hooks/useLocale';
import { staticTranslation } from '../shared/services/staticTranslations';

let ResetPasswordScreen = function (props) {
  useScreenView(AnalyticsScreenNames.resetPassword, 'ResetPasswordScreen');
  const token = props.token;
  const email = props.email || '';
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [warning, setWarning] = useState('');
  const authStatus = useUserInfo(USER_FIELD_AUTH_STATUS);
  const isUserLoggedIn = authStatus === AUTH_STATUS_AUTHENTICATED;
  const locale = useLocale();
  useScreenTitle(
    staticTranslation(locale, `Password ${isUserLoggedIn ? 'Change' : 'Reset'}`)
  );
  const title = staticTranslation(
    locale,
    isUserLoggedIn ? 'Change Password' : 'Reset Password'
  );
  const confirmPasswordRef = useRef(null);
  const resetPassword = useApiRequest(
    (cb) => {
      //TODO: fix forgot password bug for users who's User locale doesn't match their browser locale
      //if the user is anonymous when they reset their password, then immediately after a successful reset they flip to being logged in
      //the useLocale hook will then fire if the local of the user record is different than that of the current browser session
      //this will cause this useApiRequest hook to get fired again, but inside of useApiRequest the isBusy will still be true since cb fires after setUser is called inside of apiService.js
      //so, sadly, the resetPassword here will get fired twice
      return ApiService.resetPassword(
        email,
        password,
        confirmPassword,
        token,
        locale,
        cb
      );
    },
    [email, password, confirmPassword, token, locale]
  );

  const formIsFilled = !!email && !!password && !!confirmPassword;

  let sendResetRequest = () => {
    if (!formIsFilled) {
      setWarning(
        staticTranslation(
          locale,
          'Oops, you need to enter a new password before submitting!'
        )
      );
    } else if (password !== confirmPassword) {
      setWarning(
        staticTranslation(
          locale,
          'Oops, your password confirmation must match your password!'
        )
      );
    } else {
      setWarning('');
      resetPassword.send();
    }
  };

  useEffect(() => {
    setWarning('');
  }, [locale]);

  let form = () => {
    return (
      <View>
        <TextField
          inputProps={{
            disabled: true,
            editable: false,
            placeholder: staticTranslation(locale, 'Email'),
            value: email,
          }}
        />
        <TextField
          icon={IconNames.lock}
          autoFocusHardwareKeyboards={true}
          inputProps={{
            maxLength: 200,
            onSubmitEditing: () => {
              if (formIsFilled) {
                sendResetRequest();
              } else if (password) {
                confirmPasswordRef.current.focus();
              }
            },
            blurOnSubmit: formIsFilled,
            returnKeyType: formIsFilled ? 'go' : 'next',
            disabled: resetPassword.isBusy,
            autoComplete: 'new-password',
            autoCapitalize: 'none',
            autoCorrect: false,
            placeholder: staticTranslation(locale, 'New Password'),
            value: password,
            onChangeText: setPassword,
            secureTextEntry: true,
          }}
        />
        <TextField
          icon={IconNames.lock}
          ref={confirmPasswordRef}
          inputProps={{
            maxLength: 200,
            blurOnSubmit: true,
            onSubmitEditing: () => sendResetRequest(),
            returnKeyType: 'go',
            disabled: resetPassword.isBusy,
            autoComplete: 'new-password', //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
            placeholder: staticTranslation(locale, 'Confirm Password'),
            autoCorrect: false,
            autoCapitalize: 'none',
            value: confirmPassword,
            onChangeText: setConfirmPassword,
            secureTextEntry: true,
          }}
        />
        <WarningText>
          {warning ||
            (resetPassword.error
              ? `${staticTranslation(
                  locale,
                  'There was a problem resetting your password'
                )} (${resetPassword.error})`
              : null)}
        </WarningText>
        <ActionButton
          title={title}
          action={() => sendResetRequest()}
          showActivityIndicator={resetPassword.isBusy}
          disabled={resetPassword.isBusy}
        />
        <OrLine />
        <ActionButton
          type={ActionButtonType.SECONDARY}
          disabled={resetPassword.isBusy}
          action={() => props.navigateTo('/')}
          title={staticTranslation(
            locale,
            isUserLoggedIn ? 'Return to Dashboard' : 'Return to Sign In'
          )}
        />
        {isUserLoggedIn ? null : <AnonymousLanguageToggle />}
      </View>
    );
  };

  let result = () => {
    return (
      <View>
        <Paragraph style={{ marginBottom: 30 }}>
          {staticTranslation(locale, 'Your password was successfully reset!')}
        </Paragraph>
        <ActionButton
          title={staticTranslation(locale, 'Go to the Dashboard')}
          action={() => props.navigateTo('/')}
        />
      </View>
    );
  };

  useEffect(() => {
    props.setHideLogo(false);
    props.setWelcomeMessageForSM(null);
    props.setAvoidKeyboard(true);
  }, [props]);

  return (
    <>
      <Heading>{title}</Heading>
      {resetPassword.succeeded === true ? result() : form()}
    </>
  );
};

export { ResetPasswordScreen };
