import React from 'react';
import { StyleSheet } from 'react-native';

const OnboardingButtonImage = function ({ style }) {
  const oneX = require('./onboarding-button.png');
  const twoX = require('./onboarding-button@2x.png');
  const threeX = require('./onboarding-button@3x.png');
  return (
    <img
      src={threeX}
      alt=""
      style={StyleSheet.flatten(style)}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
    />
  );
};

export { OnboardingButtonImage };
