import React, { useRef, useImperativeHandle } from 'react';
import { TextInput, StyleSheet, View, Platform } from 'react-native';

import { Icon } from './icon';
import { ScreenSizes, useScreenSize, useStylesForWidth } from '../hooks/useStyleForWidth';
import { AppColors, AppStyles, IconSizes } from '../services/styles';

const TextField = React.forwardRef((props, ref) => {
  const propStyle = props.style || {};
  const inputProps = props.inputProps;
  const icon = props.icon || null;
  const autoFocusHardwareKeyboards = props.autoFocusHardwareKeyboards;
  const isHardwareKeyboard = useScreenSize() >= ScreenSizes.LG && Platform.OS === 'web'; //An approximation, there is no reliable way to detect if a device uses a software keyboard
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  const textInput = (
    <TextInput
      ref={inputRef}
      style={useStylesForWidth(
        styles,
        'tfInput',
        inputProps.multiline ? styles.tfMultilineInput : {},
        propStyle,
        icon ? styles.tfInputWithIcon : {}
      )}
      placeholderTextColor={AppColors.placeholderText}
      autoFocus={autoFocusHardwareKeyboards && isHardwareKeyboard}
      {...inputProps}
    />
  );
  const iconStyle = useStylesForWidth(styles, 'tfIconView');

  if (icon) {
    return (
      <View style={styles.tfViewWithIcon}>
        {textInput}
        <View style={iconStyle}>
          <Icon name={icon} size={IconSizes.normal} color={AppColors.greyText} />
        </View>
      </View>
    );
  }
  return textInput;
});

const SMALLER_TEXT_HEIGHT = 50;
const BIGGER_TEXT_HEIGHT = 60;

const styles = StyleSheet.create({
  tfViewWithIcon: {
    position: 'relative',
  },
  tfIconView: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 40,

    justifyContent: 'center',
    alignItems: 'center',
  },
  tfIconViewSMMDLG: {
    height: SMALLER_TEXT_HEIGHT,
  },
  tfIconViewXL: {
    height: BIGGER_TEXT_HEIGHT,
  },
  tfInput: {
    ...(Platform.OS === 'web' ? { outlineStyle: 'none' } : {}),
    borderWidth: 2,
    borderColor: AppColors.lightBackground,
    borderStyle: 'solid',
    backgroundColor: AppColors.lightBackground,
    marginBottom: 20,
    borderRadius: AppStyles.fieldBorderRadius,
    paddingLeft: 24,
    paddingRight: 24,
    color: '#000000',
    fontSize: AppStyles.normalTextSize,
  },
  tfInputSM: {
    ...(Platform.OS === 'web'
      ? {
          outlineWidth: 0,
          outlineStyle: 'solid',
          outlineColor: AppColors.brandedBlue,
        }
      : {}),
  },
  tfInputSMMDLG: {
    height: SMALLER_TEXT_HEIGHT,
  },
  tfInputXL: {
    height: BIGGER_TEXT_HEIGHT,
  },
  tfMultilineInput: {
    paddingTop: 10,
  },
  tfInputWithIcon: {
    paddingRight: 40,
  },
});

export { TextField };
