import React from 'react';
import { StyleSheet } from 'react-native';

import { Paragraph, ParagraphSizes } from './paragraph';
import { AppColors, AppStyles } from '../services/styles';
import { PublishStatus } from '../util/enums';

const DraftBadge = function (props) {
  const item = props.item || { publish_status: PublishStatus.DRAFT };

  if (item.publish_status !== PublishStatus.DRAFT) {
    return null;
  }

  return (
    <Paragraph size={ParagraphSizes.TINY} bold style={styles.dbParagraph}>
      draft
    </Paragraph>
  );
};

const styles = StyleSheet.create({
  dbParagraph: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'flex-start',
    color: AppColors.white,
    backgroundColor: AppColors.darkText,
    padding: 2,
    marginLeft: 5,
    borderRadius: AppStyles.smallBorderRadius,
  },
});

export { DraftBadge };
