class PubSub {
  constructor() {
    this.events = {};
  }

  on(name, func) {
    if (!this.events[name]) {
      this.events[name] = [];
    }
    const handle = Math.random();
    this.events[name].push({ handle, func });
    return { name, handle };
  }

  off(listener) {
    if (this.events[listener.name]) {
      for (let i = 0; i < this.events[listener.name].length; i++) {
        const o = this.events[listener.name][i];
        if (o.handle === listener.handle) {
          this.events[listener.name].splice(i, 1);
          break;
        }
      }
    }
  }

  fire(name, arg) {
    if (this.events[name]) {
      for (let i = 0; i < this.events[name].length; i++) {
        this.events[name][i].func(arg);
      }
    }
  }
}

const GlobalPubSub = new PubSub();
const LOCALE_CHANGED_EVENT = 'locale-changed';
const PURCHASE_LISTENER_GC_EVENT = 'purchase_listener_gc';
export { GlobalPubSub, LOCALE_CHANGED_EVENT, PURCHASE_LISTENER_GC_EVENT, PubSub };
