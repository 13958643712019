//Stick to recommended events (and params) for best reporting results:
//https://support.google.com/analytics/answer/9267735

function webAnalyticsEvent(eventName, params = {}) {
  try {
    // noinspection JSIgnoredPromiseFromCall
    window.firebase.analytics().logEvent(eventName, params);
  } catch (e) {
    console.error('Could not fire GA event:', e);
  }
}

function analyticsLogin(method = 'email') {
  // noinspection JSUnresolvedVariable
  webAnalyticsEvent(window.firebase.analytics.EventName.LOGIN, { method });
}

function analyticsSignUp(method = 'email') {
  // noinspection JSUnresolvedVariable
  webAnalyticsEvent(window.firebase.analytics.EventName.SIGN_UP, { method });
}

function analyticsTutorialBegin() {
  webAnalyticsEvent('tutorial_begin');
}

function analyticsTutorialComplete() {
  webAnalyticsEvent('tutorial_complete');
}

function analyticsSelectContent(content_type, item_id) {
  if (item_id) {
    // noinspection JSUnresolvedVariable
    webAnalyticsEvent(window.firebase.analytics.EventName.SELECT_CONTENT, {
      content_type,
      item_id: item_id.toString(),
    });
  }
}

function analyticsScreenView(screenName, screenClass) {
  //page_view is automatically collected on web apps (https://support.google.com/analytics/answer/9234069)
  //but because this is a Single Page App, we disable auto page views (index.html) and use screen_view instead
  // noinspection JSUnresolvedVariable
  webAnalyticsEvent(window.firebase.analytics.EventName.SCREEN_VIEW, {
    page_title: screenName,
    screen_name: screenName,
    screen_class: screenClass,
  });
}

export {
  analyticsLogin,
  analyticsSignUp,
  analyticsTutorialBegin,
  analyticsTutorialComplete,
  analyticsSelectContent,
  analyticsScreenView,
};
