import React from 'react';

import { OnboardingImage } from './OnboardingImage';

const OnboardingWelcomeImage = function ({ style }) {
  const oneX = { en: require('./onboarding-welcome.png') };
  const twoX = { en: require('./onboarding-welcome@2x.png') };
  const threeX = { en: require('./onboarding-welcome@3x.png') };
  return <OnboardingImage oneX={oneX} twoX={twoX} threeX={threeX} style={style} />;
};

export { OnboardingWelcomeImage };
