import React from 'react';
import { StyleSheet } from 'react-native';

const DashboardBannerSMImage = function ({ style }) {
  const oneX = require('./dashboard-banner-sm.png');
  const twoX = require('./dashboard-banner-sm@2x.png');
  const threeX = require('./dashboard-banner-sm@3x.png');
  return (
    <img
      src={threeX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
    />
  );
};

export { DashboardBannerSMImage };
