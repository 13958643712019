import React from 'react';
import { View, StyleSheet } from 'react-native';

import { DraftBadge } from './draftBadge';
import { GradientMask } from './gradientMask';
import { Heading, HeadingType } from './heading';
import { Icon } from './icon';
import { ProgressBar } from './progressBar';
import { TopicButtonImage } from '../img/topic-button/TopicButtonImage';
import { AppColors, IconNames } from '../services/styles';

const LockIcon = function () {
  return (
    <View style={styles.thLockView}>
      <View style={styles.thLockBackground} />
      <View style={styles.thLockIconContainer}>
        <Icon name={IconNames.lock} size={50} color={AppColors.white} />
      </View>
    </View>
  );
};

const TopicHero = function (props) {
  const { percentComplete, imageKey, title, isDraft, locked } = props;
  return (
    <View style={styles.thInnerView}>
      <TopicButtonImage style={styles.thImage} imageKey={imageKey} />
      <GradientMask zIndex={2} />
      <View style={styles.thContentView}>
        {isDraft ? <DraftBadge /> : null}
        <Heading type={HeadingType.TWO} style={styles.thTitle}>
          {title}
        </Heading>
        <ProgressBar percent={percentComplete} style={styles.thProgress} />
      </View>
      {locked ? <LockIcon /> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  thInnerView: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
  },
  thImage: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: AppColors.menuIconBackground,
  },
  thContentView: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: 3,
    padding: 20,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  thTitle: {
    color: AppColors.white,
    marginBottom: 0,
  },
  thProgress: {
    marginTop: 20,
  },
  thLockView: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: 4,
  },
  thLockBackground: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -35,
    marginTop: -35,
    width: 70,
    height: 70,
    borderRadius: 35,
    zIndex: 5,
    backgroundColor: AppColors.withOpacity('#000000', 0.4),
  },
  thLockIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 6,
  },
});

export { TopicHero };
