/*
 * See deploy_in_production.sh to see which env are set for the production web instance
 * For mobile builds, comment out the appropriate blocks in the env variable below.
 *
 * environment
 * what type of instance is running: local, dev, staging, or production
 * used by mobile and web builds
 *
 * apiBaseUrl
 * what is the backend API url
 * used by mobile and web builds
 *
 * vimeoBaseUrl
 * used for local testing only, to make vimeo videos load correctly
 * used by mobile builds only
 *
 * webAppBaseUrl
 * the frontend web app base url
 * used by mobile and web builds
 */
let env = {
  //for local web and mobile development:
  //in web production, the ENV are picked up here from the process.env
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'http://cinim-web.test',
  // vimeoBaseUrl: 'https://cinim.artisanapps.io',
  webAppBaseUrl: process.env.REACT_APP_APP_BASE_URL || 'http://app.cinim-web.test:3000',

  //below are blocks to build the mobile app for dev, staging, and production:

  // environment: 'dev',
  // apiBaseUrl: 'https://br-admin-dev.artisanapps.io',
  // webAppBaseUrl: 'https://br-app-dev.artisanapps.io',

  // environment: 'staging',
  // apiBaseUrl: 'https://cinim.artisanapps.io',
  // webAppBaseUrl: 'https://cinim-app.artisanapps.io',
};

if (process.env.EAS_BUILD === 'true') {
  env = {
    environment: 'production',
    apiBaseUrl: 'https://admin.breathingroom.me',
    webAppBaseUrl: 'https://app.breathingroom.me',
  };
}

console.log(env);

function envBackgroundColorStyle() {
  return env.environment === 'local'
    ? { backgroundColor: '#ffed4a' }
    : env.environment === 'dev'
      ? { backgroundColor: '#6cb2eb' }
      : env.environment === 'staging'
        ? { backgroundColor: '#38c172' }
        : {};
}

export { env, envBackgroundColorStyle };
